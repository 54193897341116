import React from "react";
import './ThemeItem.css';
import {Box, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import TrashRed from '../../../../../../assets/icons/TrashRed.svg';
import ReactECharts from "echarts-for-react";
import {CONFIG_OPTIONS, simpleOption} from "../styleConsts";
import {ScoopTheme} from "../../../../../Insights/Style";
import Button from "../../../../../common/Button/Button";

export const ThemeItem = ({handleDelete, theme, onClick, selectedTheme, setSelectedProp}) => {

    return (
        <Box
            className={
                `theme-item-container 
                ${onClick ? 'theme-clickable' : ''}
                ${onClick && selectedTheme === theme?.themeID ? 'preview-theme' : ''}`
            }
            onClick={() => onClick && onClick(theme.themeID)}
        >
            <Box className={'theme-item-header'}>
                <Typography className={'inter'} fontSize={14}>{theme?.themeName || 'Default theme'}</Typography>
                <Box className={'theme-item-controls'}>
                    {
                        onClick && (
                            selectedTheme === theme?.themeID ?
                                <Button
                                    className={'button-purple apply-theme-button'}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setSelectedProp(CONFIG_OPTIONS.MAIN)
                                    }}
                                >
                                    Apply
                                </Button> :
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDelete(theme.themeID)
                                    }}
                                >
                                    <img src={TrashRed} alt={'delete'} width={18} height={18} />
                                </IconButton>
                        )
                    }
                </Box>
            </Box>
            <Box className={'theme-item-preview'}>
                <ReactECharts
                    option={{
                        ...simpleOption,
                        backgroundColor: theme?.colorScheme.backgroundColor || ScoopTheme.backgroundColor,
                        color: theme?.colorScheme.colors.map(c => c.val) || ScoopTheme.color
                    }}
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                />
            </Box>
        </Box>
    )
}
