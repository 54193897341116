import {Box, Typography} from "@mui/material";
import React from "react";

export const DialogItem = ({icon, title, description, onClick, disabledStyles}) => {

    return (
        <Box
            sx={{
            padding: '10px',
            display: 'flex',
            gap: '10px',
            '&:hover': {
                backgroundColor: '#F2F2F2',
                cursor: 'pointer',
            },
            ...disabledStyles
        }}
             onClick={onClick}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FCE7EE',
                width: '38px',
                height: '38px',
                borderRadius: '3px'
            }}>
                <img style={{width: '18px'}} alt={'Plus icon'} src={icon}/>
            </Box>
            <Box>
                <Typography sx={{
                    color: '#412747',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '140%',
                    letterSpacing: '-0.28px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    {title} {disabledStyles &&
                        <Typography sx={{
                            color: '#E50B54',
                            fontFamily: 'Inter',
                            fontSize: '10px',
                            fontStyle: 'normal',
                        }}>
                            {/* Coming soon! */}
                        </Typography>
                    }
                </Typography>
                <Typography sx={{
                    color: '#979099',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                    letterSpacing: '-0.28px'
                }}>
                    {description}
                </Typography>
            </Box>
        </Box>
    )

}
