import {Icon} from "@mui/material";
import successStatus from "../../../assets/icons/SuccessStatus.svg";
import errorStatus from "../../../assets/icons/ErrorStatus.svg";
import pendingStatus from "../../../assets/icons/PendingStatus.svg";

export const StatusBubble = ({status,withLabel}) => {
    return (
        <Icon
            sx={{
                backgroundColor: status?.toLowerCase() === 'success' ? '#F1FBF8' : ((status?.toLowerCase() === 'error'  || status?.toLowerCase() === 'failed') ? '#FFF1F0' : '#F2F2F2'),
                borderRadius: withLabel ? '50px' : '50%',
                display:'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:  withLabel ? 'fit-content': '',
                padding: withLabel ? '0px 8px': '12px',
            }}
        >
            <img src={status?.toLowerCase() === 'success' ? successStatus : ((status?.toLowerCase() === 'error'  || status?.toLowerCase() === 'failed') ? errorStatus : pendingStatus)}
                 alt={'status'}/>
            {withLabel && <p
                style={{
                color: status?.toLowerCase() === 'success' ? '#00B87C' : ((status?.toLowerCase() === 'error'  || status?.toLowerCase() === 'failed') ? '#FF4D4F' : '#000000'),
                fontSize:'12px',
                marginLeft: '8px',
            }}
            >{status}</p>}
        </Icon>
    )
}