import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import React from "react";
import './Source.css';
import Grid from "@mui/material/Unstable_Grid2";
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function EventLogDialog({
                                   open,
                                   setOpen, eventLog, inboxID, server
                               }) {
    const [expanded, setExpanded] = React.useState('panel1');

    function handleClose(event) {
        setOpen(false);
    }


    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({theme}) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
            {...props}
        />
    ))(({theme}) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    function processSourceContent(result) {
        const cleanedUrl = result?.url?.replace(/\s+/g, '');
        window.open(cleanedUrl);
    }

    function handleSourceContent(inboxID, s3Key) {
        server.postData({
            "action": "getIngestionContent",
            "inboxID": inboxID,
            "s3Key": s3Key
        }, processSourceContent);
    }

    // maxWidth={false} to set to fullWidth, and fullWidth={true}
    return (<Box sx={{width: '100%'}}>
            <Dialog open={open} onClose={handleClose} maxWidth={'800px'}>
                <DialogTitle sx={{color: 'text.secondary'}}></DialogTitle>
                <DialogContent sx={{width: '100%'}}>
                    <DialogContentText>
                        <Typography sx={{fontWeight: 'bold'}}>Report load history</Typography>
                    </DialogContentText>
                    {eventLog && inboxID && eventLog.get(inboxID) && eventLog.get(inboxID).items.map((item) => {
                        if (item.inboxID === inboxID) {
                            return <Accordion expanded={expanded === item.timeStamp}
                                              onChange={handleChange(item.timeStamp)} sx={{minWidth: 1000}}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                                  sx={{pointerEvents: "none"}} expandIcon={(
                                    <ExpandMoreIcon sx={{pointerEvents: "auto"}}/>)}>
                                    <Grid container sx={{width: '100%', alignItems: 'center'}}>
                                        <Grid xs={2.5}>
                                            {item.date.toLocaleDateString('en-us', {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric", hour: "numeric",
                                                minute: "numeric"
                                            })}
                                        </Grid>
                                        <Grid xs={8.5}>
                                            {item.message}
                                        </Grid>
                                        <Grid xs={.5}>
                                            {item.s3Key &&
                                                <IconButton sx={{
                                                    pointerEvents: "auto"
                                                }} onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleSourceContent(inboxID, item.s3Key);
                                                }}><DownloadIcon/></IconButton>}
                                        </Grid>
                                        <Grid xs={.5}>
                                            {item.error &&
                                                <ErrorIcon sx={{color: 'red'}}/>}
                                            {!item.error && <CheckIcon style={{color: 'green'}}/>}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {item.metadata && <Box>{Object.keys(item.metadata).map((name) => {
                                        return <div><strong>{name}:</strong> {item.metadata[name]}</div>
                                    })}</Box>}
                                    {item.details && <Box sx={{width: '100%', mt: 2}}>
                                        <Grid container sx={{width: '100%', fontWeight: 'bold'}}><Grid xs={1}>
                                            Alert
                                        </Grid><Grid xs={9.5}>Detail</Grid><Grid xs={1.5} sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'right'
                                        }}>Rows</Grid></Grid>
                                        {item.details.map((detail) => {
                                            return <Grid container sx={{width: '100%'}}><Grid xs={1}>
                                                {detail.type === "Error" && <ErrorIcon sx={{color: 'red'}}/>}
                                                {detail.type === "Warning" &&
                                                    <Box><WarningIcon sx={{color: 'yellow'}}/></Box>}
                                            </Grid><Grid xs={9.5}>{detail.message}</Grid><Grid
                                                xs={1.5}>{detail.numRows > 0 && <Box sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'right'
                                            }}>{detail.numRows}</Box>}</Grid></Grid>
                                        })}
                                    </Box>}
                                </AccordionDetails>
                            </Accordion>
                        }
                    })}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
        ;
}