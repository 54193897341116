export const ROUTES = {
    AUTH: '',
    LOGIN: '/',
    RECIPE: '/recipe',
    CANVAS_DASHBOARD: '/canvas',
    CANVAS: '/canvas/:canvasID',
    EXPLORER: '/explorer',
    INSIGHTS: '/process', //should be insights but for now make it process
    SOURCES: '/sources',
    SOURCE: '/sources/:inboxID',
    LOGOUT: '/logout',
    GOOGLELOGIN: '/googlelogin',
    HUBSPOT: '/hubspot',
    APICONNECTORAUTH: '/apiconnectorauth',
    GMAIL: '/gmail',
    SALESFORCE: '/salesforce',
    LIVE_WORKSHEETS: '/live-worksheets',
    SETUP: '/setup',
    SCOOPADMIN: '/scoopadmin',
}
