import jwtDecode from 'jwt-decode';

export const validateToken = (token) => {
    if (!token) return false;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return !(decodedToken.exp < currentTime)
    } catch (error) {
        return false;
    }
};

export const parseToken = (token) => {
    const decodedToken = jwtDecode(token);

    // Extracting user information
    const names = decodedToken.name.split(' '); // Splitting the full name into parts
    const firstName = names[0]; // First element is the first name
    const lastName = names[names.length - 1]; // Last element is the last name
    const email = decodedToken.email;
    const userID = decodedToken['cognito:username']; // Extracting the cognito:username

    return {
        firstName,
        lastName,
        email,
        userID
    };
};

