import React, {useRef, useState} from "react";
import {IconButton, MenuItem, Popover, Tooltip, Typography} from "@mui/material";
import Ruler from '../../../assets/icons/Ruler.svg';

export const SizePicker = ({origin, value, onChange, tooltip}) => {

    const anchor = useRef();
    const [open, setOpen] = useState(false);

    const handleSelection = (size) => {
        switch (size) {
            case 'small':
                onChange({width: 1280, height: 720})
                break
            case 'medium':
                onChange({width: 1600, height: 900})
                break
            case 'large':
                onChange({width: 1920, height: 1080})
                break
        }
        setOpen(false)
    }

    const getAnchorProps = () => {
        if (origin === 'top') {
            return {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            }
        } else {
            return {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            }
        }
    }

    return (
        <>
            <Tooltip title={'Change size'} disableHoverListener={!tooltip}>
                <IconButton onClick={() => setOpen(true)} ref={anchor}>
                    <img src={Ruler} alt={'size'} className={'size-picker'} />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchor.current}
                open={open}
                onClose={() => setOpen(false)}
                {...getAnchorProps()}
            >
                <MenuItem onClick={() => handleSelection('large')} sx={{backgroundColor: value.width === 1920 ? '#dad9d9' : ''}}>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>Large</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSelection('medium')} sx={{backgroundColor: value.width === 1600 ? '#dad9d9' : ''}}>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>Medium</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSelection('small')} sx={{backgroundColor: value.width === 1280 ? '#dad9d9' : ''}}>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>Small</Typography>
                </MenuItem>
            </Popover>
        </>
    )
}
