import {Box, Chip, Divider, FormControl, MenuItem, Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import Selector from "../../../../../common/Selector/Selector";
import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import Input from "../../../../../common/Input/Input";
import "./FilterModal.css"

const FilterModal = ({
                         columnName,
                         open,
                         menuItems,
                         anchorEl,
                         id,
                         setOperator,
                         operator,
                         setChanges,
                         allValues,
                         operatorValues,
                         setOperatorValues,
                         filterList,
                         saveFilter2
                     }) => {

    //Variables for managing the search input
    const [filterSearch, setFilterSearch] = useState('')
    const [tempAllValues, setTempAllValues] = useState([])

    //Contains the current filter list with all the filter objects
    const [currentFilterList, setCurrentFilterList] = useState(filterList?.filter(f => f.attributeName === columnName) ?? [])
    const [currentOperatorValues, setCurrentOperatorValues] = useState(operatorValues)

    useEffect(() => {
        setTempAllValues(allValues)
    }, [allValues])

    useEffect(() => {
        setCurrentFilterList(filterList?.filter(f => f.attributeName === columnName) ?? [])
    }, [filterList]);

    const addSelectedValue = (value) => {
        setChanges(true);
        // Check if there exists a filter with the same operator
        const existingFilterIndex = currentFilterList.findIndex(f => f.operator === operator);
        if (existingFilterIndex !== -1) {
            // If the value is already in the filter list, remove it
            const existingFilter = currentFilterList[existingFilterIndex];
            if (existingFilter.filterValue.values.includes(value)) {
                setCurrentFilterList(prev => {
                    const updatedFilterList = [...prev];
                    const updatedFilter = {
                        ...existingFilter,
                        filterValue: {
                            values: existingFilter.filterValue.values.filter(v => v !== value)
                        }
                    };
                    // If the values array becomes empty, remove the entire filter
                    if (updatedFilter.filterValue.values.length === 0) {
                        updatedFilterList.splice(existingFilterIndex, 1);
                    } else {
                        updatedFilterList[existingFilterIndex] = updatedFilter;
                    }
                    return updatedFilterList;
                });
            } else {
                // Otherwise, add the value to the existing filter
                setCurrentFilterList(prev => prev.map(f => {
                    if (f.operator === operator) {
                        return {
                            ...f,
                            filterValue: {
                                values: [...f.filterValue.values, value]
                            }
                        };
                    }
                    return f;
                }));
            }
        } else {
            // If no filter with the current operator exists, create a new filter object with the value
            setCurrentFilterList(prev => [
                ...prev,
                {
                    attributeName: columnName,
                    operator: operator,
                    filterValue: {
                        values: [value]
                    }
                }
            ]);
        }
    };

    const addOperatorValues = (value) => {
        setChanges(true)
        if (currentOperatorValues.includes(value)) {
            setOperatorValues((prev) => prev.filter((v) => v !== value));
            setCurrentOperatorValues((prev) => prev.filter((v) => v !== value));
            setCurrentFilterList((prev) => prev.filter((f) => f.operator !== value));
        } else {
            setOperatorValues([...operatorValues, value]);
            setCurrentOperatorValues([...currentOperatorValues, value]);
            setCurrentFilterList((prev) => {
                const existingFilterIndex = prev.findIndex(f => f.operator === value);
                if (existingFilterIndex !== -1) {
                    return prev;
                }
                return [
                    ...prev,
                    {
                        attributeName: columnName,
                        operator: value,
                        filterValue: {
                            values: []
                        }
                    }
                ];

            });

        }
    }

    const handleFilterSearch = (e) => {
        setFilterSearch(e.target.value)
        setTempAllValues(allValues.filter((value) => value.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    /*    const handleDelete = (filterIndex, valueIndex) => {
            setChanges(true);
            setCurrentFilterList(prevFilterList => {
                // Make a copy of the previous filter list
                const updatedFilterList = [...prevFilterList];
                // Remove the value at the specified valueIndex from the filterValue.values array
                updatedFilterList[filterIndex].filterValue.values.splice(valueIndex, 1);
                // If the values array of the filter becomes empty, remove the entire filter object
                if (updatedFilterList[filterIndex].filterValue.values.length === 0) {
                    updatedFilterList.splice(filterIndex, 1);
                }
                // Return the updated filter list
                return updatedFilterList;
            });
            setCurrentOperatorValues(prev => prev.filter((v) => v !== currentFilterList[filterIndex].operator));
        };*/
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
                saveFilter2(currentFilterList)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '360px',
                maxHeight: '318px',
            }}
        >
            <Box sx={{
                display: 'flex',
                padding: '12px 10px',
                gap: '8px',
                alignItems: 'center',
                width: 'auto',
            }}>
                <Typography sx={{
                    color: '#412747',
                    fontSize: '14px',
                    fontWeight: 600,
                }}>
                    {columnName}
                </Typography>
                <Box className="customInput">
                    <Selector
                        value={operator}
                        size={'small'}
                        sx={{
                            marginTop: '0px !important',
                            mt: 0,
                            maxHeight: '42px',
                            minWidth: '187px',
                            '& .MuiFormControl-root': {marginTop: '0px !important'}
                        }}
                        onChange={(event) => {
                            setOperator(event.target.value)
                            setChanges(true)
                        }}
                    >
                        {menuItems.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Selector>
                </Box>
            </Box>
            {/*<Box
                sx={{
                    width: 'auto',
                    maxWidth: '325px',
                    overflow: 'auto',
                    gap: '8px',
                    padding: '6px 8px',
                    maxHeight: '100px',
                    display: 'flex',
                }}
            >
                {currentFilterList.map((f, index) => (
                    <React.Fragment key={index}>
                        {f.operator === "IsNull" || f.operator === "IsNotNull" ? (
                            <Chip
                                sx={{ width: 'auto' }}
                                onDelete={() => handleDelete(index)}
                                label={menuItems.find(m => m.value === f.operator)?.label}
                            />
                        ) : (
                            f.filterValue.values.map((value, valueIndex) => (
                                <Chip
                                    sx={{ width: 'auto' }}
                                    onDelete={() => handleDelete(index, valueIndex)}
                                    key={`${index}-${valueIndex}`}
                                    label={`${menuItems.find(m => m.value === f.operator)?.label}: ${value}`}
                                />
                            ))
                        )}
                    </React.Fragment>
                ))}

            </Box>*/}
            <Divider sx={{color: '#F2F2F2'}}/>
            <Box sx={{padding: '16px 10px 10px 10px'}}>
                {(operator === "Equals" || operator === "NotEquals") &&
                    <>
                        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <Box className="customInput">
                                <Input
                                    onChange={handleFilterSearch}
                                    value={filterSearch}
                                    placeholder={'Search'}
                                    padding={'0px 8px !important'}
                                    size={'small'}
                                    style={{maxHeight: '30px', paddingTop: '1px'}}
                                />
                            </Box>
                        </Box>
                        {tempAllValues.filter(v => v !== "NULL").map((value, index) => (
                            <Box key={index} sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                <Checkbox
                                    checked={currentFilterList
                                        .filter(f => f.operator === operator)
                                        .some(f => f.filterValue.values.includes(value))}
                                    sx={{
                                        padding: 0,
                                        color: '#E6E4E6',
                                        '&.Mui-checked': {color: '#E50B54'}
                                    }}
                                    onChange={() => addSelectedValue(value)}
                                />
                                <Typography
                                    sx={{
                                        color: '#2B1630',
                                        fontSize: '14px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Box>
                        ))}
                    </>
                }
                {(operator === "IsNull" || operator === "IsNotNull") &&
                    <>
                        <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <Checkbox
                                checked={currentOperatorValues.includes(operator)}
                                sx={{
                                    padding: 0,
                                    color: '#E6E4E6',
                                    '&.Mui-checked': {color: '#E50B54'}
                                }}
                                onChange={() => addOperatorValues(operator)}
                            />
                            <Typography
                                sx={{
                                    color: '#2B1630',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}
                            >
                                {menuItems.find(m => m.value === operator)?.label}
                            </Typography>
                        </Box>
                    </>
                }
            </Box>
        </Popover>
    )


}

export default FilterModal;