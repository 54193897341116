export const formatDate = (dateString) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
        return null; // or handle as you see fit
    }
    const parts = dateString.split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
}
export const formatToDDMMYY_HHMMampm = (timestamp)=> {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Format the day
    const day = date.getDate().toString().padStart(2, '0');

    // Format the month (getMonth() returns month from 0-11)
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    // Format the year (get only the last two digits)
    const year = date.getFullYear().toString().slice(-2);

    // Format the hours for 12-hour format
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, '0');

    // Format the minutes
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Combine the parts
    return `${day}/${month}/${year}, ${formattedHours}:${minutes}${ampm}`;
}


