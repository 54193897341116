import {OBJECT_TYPES} from "../types";

export const Sheetlet_MIN_SIZE = {"width": 200, "height": 200};

export class SheetletClass {
  constructor(id, title, x, y, width, height, sheetletProps, wrapperStyles) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.isBlank = true;
    this.shouldHideGrid = false;
    this.shouldHideHeaders = false;
    this.type = OBJECT_TYPES.SHEETLET;
    this.content = {'worksheetID': sheetletProps?.worksheetID || null,
                    'worksheetNum': sheetletProps?.worksheetNum || null, //opening a specific worksheet, we need either its ordinal number of named range
                    'worksheetName': sheetletProps?.worksheetName || null,
                    'worksheetRange': sheetletProps?.worksheetRange || null,
                    'workspaceID': sheetletProps?.workspaceID || null,
                    'worksheetURL': sheetletProps?.worksheetURL || null,
                    };
    this.wrapperStyles = wrapperStyles || {};
  }

  // initialize w sample data from a working chart
  static newSheetlet(title, sheetletProps, zoom, wrapperStyles, x ,y) {
      const canvasScrollElement = document.getElementById('canvas-scrollable')
      return new SheetletClass(
        Math.random(),
        title || 'My Sheetlet',
        x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
        y ? y :(200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
        700,
        500,
        sheetletProps,
        wrapperStyles,
    );
  }
}
