import { updateProcessDiagramContent, updateProcessDiagramTitle } from '../../../store/actions';

export function loadFromSavedProcessDiagram(processDiagram, setIsProcessDiagramASkeleton, dispatch, id, workspaceID) {
    // setIsProcessDiagramASkeleton(false);
    dispatch(updateProcessDiagramContent(id, { "processDiagram": processDiagram }));
    if (typeof processDiagram !== 'undefined') {
        dispatch(updateProcessDiagramTitle(id, processDiagram));
    }
}

export function saveProcessDiagram(processDiagram, server, workspaceID, userID, token) {
    // Save the processDiagram to the database
    // server.saveProcessDiagram(processDiagram, workspaceID, userID, token);
}

