import {
    Box,
    DialogContentText,
    TextField, Typography,
} from "@mui/material";
import * as React from "react";
import {getStringForFilter} from "./Filter";
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";

export function SavedFilterDialog({
                                      filterToSave, setFilterToSave,
                                      workspaceMetadata,
                                      server, getWorkspaceMetadata
                                  }) {
    let [name, setName] = React.useState("");
    let [isConfirmDelete, setIsConfirmDelete] = React.useState(false);

    function handleClose(event) {
        setFilterToSave(null);
        setIsConfirmDelete(false);
    }

    function handleSave(event) {
        if (filterToSave) {
            setFilterToSave(null);
            setIsConfirmDelete(false);
            server.postData(
                {
                    "action": "putObject",
                    "class": "scoop.queryfilter.SavedFilter",
                    "value": {
                        filterName: name,
                        workspaceID: server.workspaceID,
                        filter: filterToSave
                    }
                },
                (result) => {
                    getWorkspaceMetadata();
                }
            )
            ;
        }
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap= "8px">
            <Button onClick={handleClose} text={'Cancel'}/>
            <Button onClick={handleSave} className={'button-purple'} text={'Save'}/>
        </Box>
    )

    const renderFiltersToSave = () => {
        if (filterToSave.boperator) {
            return filterToSave.filters.map(f => (
                <Typography className={'inter'}>{getStringForFilter(f)}</Typography>
            ))
        } else {
            return <Typography className={'inter'}>{getStringForFilter(filterToSave)}</Typography>
        }
    }

    if (workspaceMetadata && filterToSave) {
        return (
            <Dialog open={true} onClose={handleClose} maxWidth={'800px'} title={'Edit Saved Filter Properties'} actions={actions}>
            {isConfirmDelete &&
                <>
                    <DialogContentText>
                        Are you sure you want to delete the saved filter:
                    </DialogContentText>
                    <Box sx={{display: 'flex', justifyContent: 'center', m: 5}}>
                        {filterToSave.filterName}
                    </Box>
                </>

            }
            {!isConfirmDelete &&
                    <>
                        <DialogContentText>
                            Save a filter for later use
                        </DialogContentText>
                        <Box sx={{minWidth: 700}}>
                            <TextField id="savedFilterName" label="Saved filter name" sx={{mt: 2}} fullWidth
                                       defaultValue={name}
                                       onChange={(event) => {
                                           setName(event.target.value);
                                       }}>
                            </TextField>
                            <Box sx={{display: 'flex', mt: 3, flexDirection: 'column'}}>
                                {renderFiltersToSave()}
                            </Box>
                        </Box>
                    </>
                }
        </Dialog>
        );
    } else return null;
}
