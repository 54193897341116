import React from "react";
import {Box, Slider as MuiSlider, Typography} from "@mui/material";

export const Slider = ({
    value,
    step= 1,
    min= 0,
    max= 100,
    onChange,
    label,
    ...props

}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
            {
                label &&
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            color: '#14092A',
                            fontWeight: 600
                        }}>
                            {label}
                        </Typography>
                    </Box>
            }
            <Box sx={{padding: '0 8px'}}>
                <MuiSlider
                    step={step}
                    value={value}
                    min={min}
                    max={max}
                    sx={{
                        '&.MuiSlider-root': {padding: '0px'},
                        '& .MuiSlider-track': {color: '#E50B54 !important', strokeWidth: '4px !important', border: 'none'},
                        '& .MuiSlider-rail': {color: '#D9D9D9 !important', strokeWidth: '4px !important', border: 'none'},
                        '& .MuiSlider-thumb': {
                            color: '#E50B54 !important',
                            fill: '#E50B54',
                            width: '12px',
                            height: '12px',
                            backgroundColor: '#E50B54',
                            boxShadow: 'none',
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: '0px 0px 5px 8px rgba(255, 166, 195, 0.50)',
                            },
                        },
                        '&.MuiSlider-valueLabel': {
                            backgroundColor: 'rgba(255, 166, 195, 0.50) !important',
                        }
                    }}
                    onChange={(e, value) => onChange(value)}
                    {...props}
                />
            </Box>
        </Box>
    )
}
