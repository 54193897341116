import { updateSheetletContent, updateSheetletTitle } from '../../../store/actions';

export function loadFromSavedSheetlet(sheetlet, setIsSheetletASkeleton, dispatch, id, selectedItemToLoad, workspaceID) {
    // setIsSheetletASkeleton(false);
    dispatch(updateSheetletContent(id, { "worksheetID": sheetlet.worksheetID, "worksheetRange": sheetlet.worksheetRange, "worksheetNum": sheetlet.worksheetNum, "worksheetName": sheetlet.worksheetName, "workspaceID": workspaceID, "worksheetURL": sheetlet.worksheetURL }));
    if (typeof sheetlet.sheetletName !== 'undefined') {
        dispatch(updateSheetletTitle(id, sheetlet.sheetletName));
    }
}

