import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MailIcon from '@mui/icons-material/Mail';
import ReportIcon from '@mui/icons-material/Report';
import CloseIcon from "@mui/icons-material/Close";
import {Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography} from "@mui/material";
import {Drawer} from "../../../common/Drawer/Drawer";
import {Label} from "@mui/icons-material";
import {StatusBubble} from "../../../common/StatusBubble/StatusBubble";
import IconButton from "@mui/material/IconButton";
import Download from "../../../../assets/icons/Download.svg"
import Button from "../../../common/Button/Button";
import Trash from "../../../../assets/icons/Trash.svg"
import {useState} from "react";
import {formatToDDMMYY_HHMMampm} from "../../../../utils/date";
import {EmailDrawerInfo} from "./EmailDrawerInfo";
import {BotDrawerInfo} from "./BotDrawerInfo";
import {Server} from "../../../../api/Server";
import {useSelector} from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from '@mui/icons-material/Circle';

const ScoopDrawer = ({open, onClose, logDetails, setOpenReprocess, dataset}) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);

    const processTableContent = (result) => {
        const cleanedUrl = result?.url.replace(/\s+/g, '');
        window.open(cleanedUrl);
    }


    const handleTableContent = (inboxID, contentKey) => {
        const server = new Server(workspaceID, userID, token)
        server.postData({
            "action": "getTableContent",
            "inboxID": inboxID,
            "contentKey": contentKey
        }, processTableContent);
    }

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
        >
            {
                logDetails &&
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px', height: '100%'}}>
                    <Typography sx={{
                        fontSize: '24px',
                        fontWeight: 600
                    }}>{logDetails.timeStamp ? formatToDDMMYY_HHMMampm(logDetails.timeStamp) : ''}</Typography>
                    <Box sx={{display: 'flex', gap: '32px'}}>
                        <Box>
                            <Typography
                                sx={{fontSize: '12px', color: '#635566', marginBottom: '8px'}}>Status</Typography>
                            <StatusBubble status={logDetails.rstiKey ? 'Success' : 'Failed'} withLabel/>
                        </Box>
                        <Box>
                            <Typography sx={{fontSize: '12px', color: '#635566', marginBottom: '8px'}}>Table
                                Scooped</Typography>
                            <Typography sx={{
                                color: '#2B1630',
                                fontSize: '14px',
                                fontWeight: 600
                            }}>{logDetails.tableName ?? 'No Table'}</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{fontSize: '12px', color: '#635566', marginBottom: '8px'}}>Rows
                                Scooped</Typography>
                            <Typography sx={{
                                color: '#2B1630',
                                fontSize: '14px',
                                fontWeight: 600
                            }}>{logDetails.numRows ?? '-'}</Typography>
                        </Box>
                    </Box>
                    {
                        logDetails?.metadata ?
                            <EmailDrawerInfo logDetails={logDetails} dataset={dataset}/>
                            :
                            <BotDrawerInfo logDetails={logDetails} dataset={dataset}/>
                    }
                    <Box>
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#2B1630',
                            marginBottom: '16px'
                        }}>
                            Logs details:
                        </Typography>
                        <Box sx={{maxHeight: '300px', overflow: 'noscroll'}}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                {logDetails && logDetails?.details?.length > 0 ? logDetails?.details?.map((detail, index) => (
                                    <Box key={index} sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#2B1630',
                                            fontFamily: 'Inter',
                                        }}>
                                            {detail.message}.
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#2B1630',
                                            fontFamily: 'Inter',
                                        }}>
                                            {detail.numRows} rows
                                        </Typography>
                                        <Chip sx={{marginLeft: 'auto', fontFamily: 'Inter', height: '22px'}}
                                              color={detail.type.toLowerCase()} label={detail.type}/>
                                    </Box>
                                )) : <Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    color: '#2B1630',
                                }}>
                                    No details available
                                </Typography>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }

            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                <Button
                    className={'button-purple small'}
                    onClick={() => {
                        if (logDetails.rstiKey)
                            handleTableContent(logDetails.inboxID, logDetails.contentKey)
                        else {
                            onClose()
                            setOpenReprocess(true)
                        }
                    }}
                >{logDetails?.rstiKey ? 'View Processed Data' : 'Try Again'}</Button>
                <Button className={'icon-button'}>
                    <img src={Trash} alt={'Trash'}/>
                </Button>
            </Box>
        </Drawer>
    );
}

export default ScoopDrawer;

const LogEvent = ({logDetails}) => {

    return (
        <Accordion>
            <AccordionSummary
                sx={{"& .MuiAccordionSummary-content": {maxWidth: '100%'}}}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#2B1630',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {logDetails.message}

                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    {logDetails?.details?.map((detail, index) => (
                        <Box key={index} sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#2B1630',
                            }}>
                                {detail.message},
                            </Typography>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#2B1630',
                            }}>
                             Rows: {detail.numRows}
                            </Typography>
                            <Chip sx={{marginLeft: 'auto'}} color={detail.type.toLowerCase()} label={detail.type}/>

                        </Box>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}