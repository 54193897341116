import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material";
import Button from "../../../common/Button/Button";
import AppsIcon from "@mui/icons-material/Apps";
import Sheetlet from "../../../Source/Sheetlet";
import {Switch} from "../../../common/Switch/Switch";
import {DataGrid} from "../../../common/DataGrid/DataGrid";
import {renderToString} from "react-dom/server";
import {ServerContext} from "../../../Source/SheetState";
import {Server} from "../../../../api/Server";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import {EmptyCalculatedColumns} from "./EmptyCalculatedColumns";
import {ScoopLoader} from "../../../common/Spinner/ScoopLoader";
import googleSheetsIcon from "../../../../assets/icons/GoogleSheets.svg";

export const CalculatedColumns = ({inboxID}) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const [previewData, setPreviewData] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentServerContext, setCurrentServerContext] = useState(null);
    const [expanded, setExpanded] = useState(false)
    const [worksheetID, setWorksheetID] = useState(null)

    useEffect(() => {
        setCurrentServerContext(new ServerContext(new Server(workspaceID, userID, token)));
    }, [workspaceID, userID, token]);
    const [sheetRange, setSheetRange] = React.useState({
        "sheetType": "CalculatedField",
        "advanced": false,
        "inboxID": inboxID
    });

    useEffect(() => {
        if (!initialized && currentServerContext?.server) {
            sheetRange.inboxID = inboxID;
            setSheetRange({...sheetRange});
            setLoading(true);
            setInitialized(true);
        }
    }, [initialized, currentServerContext]);

    const handleSave = (event) => {
        try {
            currentServerContext.server.sheetPostData({
                "action": "flushWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            }, () => {
            });
        }catch (e) {
            console.error(e)
        }

        setPreviewData(null);
        setInitialized(false);
    }

    const handleCancel = (event) => {
        currentServerContext.server.postData({
            "action": "clearWorksheetCache",
            "sheetRange": {
                "inboxID": inboxID
            }
        }, () => {
        });
        setPreviewData(null);
        setInitialized(false);
    }

    const processPreviewData = (results) => {
        setLoading(false);
        if (results && results.rows) {
            setPreviewData(results);
        } else {
            setPreviewData(null);
        }
    }

    const updatePreviewData = () => {
        currentServerContext?.server.sheetPostData({
            "action": "getCFWPreviewData",
            "inboxID": inboxID
        }, processPreviewData);
    }


    return (
        <Box sx={{width: '100%', paddingLeft: '15px', height: '100%'}}>
            {
                !previewData && !loading ?
                    <EmptyCalculatedColumns inboxID={inboxID}/>
                    :
                    currentServerContext &&
                    <Box sx={{height: '100%'}}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            gap: '16px',
                            marginBottom: '16px'
                        }}>
                            <Typography sx={{flex: '2', color: '#635566', fontSize: '14px',}}>
                                Create and edit custom calculated columns that can be added to your raw report source
                                data.
                                You
                                can
                                also choose to not include columns from your raw reports.
                            </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{color: 'text.secondary', padding: '10px'}}
                                        control={<Switch
                                                    checked={sheetRange.advanced}
                                                    onChange={(event) => {
                                                        sheetRange.advanced = !sheetRange.advanced;
                                                        setSheetRange({...sheetRange});
                                                    }}/>}
                                        label="Advanced"
                                    />
                                </FormGroup>
                                <Box sx={{flex: '1'}}>
                                    <Button sx={{float: 'right'}} className={'button-purple small'}
                                            onClick={handleSave}>Save Changes</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Sheetlet
                            controlledHeight={true}
                            setWorksheetID={setWorksheetID}
                            serverContext={currentServerContext} sheetRange={sheetRange}
                                  locked={!sheetRange.advanced}
                                  embeddedSizeProps={{height: 'auto'}}
                                  cornerHTML={worksheetID ? '<a href="https://docs.google.com/spreadsheets/d/' + worksheetID + '" target="_blank">' +
                                      renderToString(<img alt={'Google sheets'} src={googleSheetsIcon}/>) + '</a>' : ''}/>
                        <Box sx={{
                            mt: 2,
                            mb: 1,
                            color: 'text.secondary',
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '40%'
                        }}>
                            <Accordion expanded={expanded}
                                       TransitionProps={{
                                           timeout: 0
                                       }}
                                       sx={{
                                           height: '100%',
                                           '& .MuiCollapse-root': {height: '85% !important'},
                                           '& .MuiCollapse-wrapper': {height: '100% !important'},
                                           '& .MuiAccordionDetails-root': {height: '100% !important', padding: '0'},
                                           '& .MuiAccordion-region': {height: '100% !important'},
                                       }}
                                       disableGutters
                            >
                                <AccordionSummary sx={{padding: '0'}}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Box
                                            sx={{display: 'flex', alignItems: 'center', padding: '10px'}}
                                            onClick={() => {
                                                !expanded && !previewData && updatePreviewData();
                                                setExpanded(!expanded)
                                            }}
                                        >
                                            <Typography sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: '#635566',
                                                fontSize: '14px'
                                            }}>
                                                Preview data
                                            </Typography>
                                            {
                                                expanded ? <ArrowDropDownRoundedIcon/> : <ArrowRightRoundedIcon/>
                                            }
                                        </Box>

                                        {expanded &&
                                            <Button variant="contained"
                                                    className={'button-grey small'}
                                                    onClick={() => {
                                                        updatePreviewData();
                                                        setLoading(true);
                                                    }}>Update Preview</Button>
                                        }
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{height: '100%'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                                        {previewData &&
                                            <Box sx={{height: '100%'}}>
                                                <DataGrid rowHeight={20} rows={previewData.rows}
                                                          columns={previewData.columns}
                                                          disableColumnMenu={true}
                                                          pagination={false}
                                                          hideFooter
                                                          sx={{
                                                              height: '100%',
                                                              color: "text.secondary",
                                                              '& div.MuiListItemIcon-root': {color: "#000"},
                                                          }}/>
                                            </Box>}
                                        {!previewData && loading &&
                                            <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                                                <ScoopLoader/>
                                            </Box>}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                        </Box>

                    </Box>
            }
        </Box>
    );
}
