import React, {useState} from "react";
import './EditArrowMenu.css';
import {Box, InputLabel, MenuItem, Select, FormControl, IconButton} from "@mui/material";
import {ColorPicker} from "../../common/ColorPicker/ColorPicker";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteDialog from "../../common/Dialog/DeleteDialog";
import {deleteArrow, updateArrow} from "../../../store/actions";
import {useDispatch} from "react-redux";
import {ReorderPicker} from "../../common/ReorderPicker/ReorderPicker";

export const EditArrowMenu = ({id, arrowProps, handleDelete, setArrowProps}) => {

    const dispatch = useDispatch();
    const [arrowHead, setArrowHead] = useState(
        arrowProps.showTail && arrowProps.showHead ? 'both' :
        !arrowProps.showTail && arrowProps.showHead ? 'end' :
        arrowProps.showTail && !arrowProps.showHead ? 'start' : ''
    );
    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleUpdateArrow = (key, value) => {
        setArrowProps(value)
        dispatch(updateArrow(id, {key, value}))
    }

    const handleDeleteArrow = () => {
        handleDelete()
        dispatch(deleteArrow(id));
        setDeleteOpen(false);
    };

    const handleArrowHeadChange = (value) => {
        setArrowHead(value)
        switch (value) {
            case 'start':
                handleUpdateArrow('arrowProps', {...arrowProps, showTail: true, showHead: false})
                break;
            case 'end':
                handleUpdateArrow('arrowProps', {...arrowProps, showTail: false, showHead: true})
                break;
            case 'both':
                handleUpdateArrow('arrowProps', {...arrowProps, showTail: true, showHead: true})
                break;
        }
    }

    return (
        <Box id={'arrow-menu'} className={'menu-container'} sx={{'& .MuiFormControl-root': {marginTop: '0 !important'}}}>
            <FormControl sx={{minWidth: 120, mr: '10px'}} size="small">
                <InputLabel id={"select-path-label"}>Path</InputLabel>
                <Select
                    labelId={"select-path-label"}
                    id={"select-path"}
                    value={arrowProps.path}
                    label={'Path'}
                    onChange={(e) => handleUpdateArrow('arrowProps', {...arrowProps, path: e.target.value})}
                >
                    <MenuItem value={'smooth'}>Smooth</MenuItem>
                    <MenuItem value={'grid'}>Grid</MenuItem>
                    <MenuItem value={'straight'}>Straight</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{minWidth: 120, mr: '10px'}} size="small">
                <InputLabel id={"select-arrow-head-label"}>Arrow head</InputLabel>
                <Select
                    labelId={"select-arrow-head-label"}
                    id={"select-arrow-head"}
                    label={'Arrow head'}
                    onChange={(e) => handleArrowHeadChange(e.target.value)}
                    value={arrowHead}
                >
                    <MenuItem value={'start'}>Start</MenuItem>
                    <MenuItem value={'end'}>End</MenuItem>
                    <MenuItem value={'both'}>Both</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{minWidth: 120, mr: '10px'}} size="small">
                <InputLabel id={"select-anchor-start-label"}>Start anchor</InputLabel>
                <Select
                    labelId={"select-anchor-start-label"}
                    id={"select-anchor-start"}
                    value={arrowProps.startAnchor}
                    label={'Start anchor'}
                    onChange={(e) => handleUpdateArrow('arrowProps', {...arrowProps, startAnchor: e.target.value})}
                >
                    <MenuItem value={'auto'}>Auto</MenuItem>
                    <MenuItem value={'left'}>Left</MenuItem>
                    <MenuItem value={'right'}>Right</MenuItem>
                    <MenuItem value={'top'}>Top</MenuItem>
                    <MenuItem value={'bottom'}>Bottom</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{minWidth: 120, mr: '10px'}} size="small">
                <InputLabel id={"select-anchor-end-label"}>End anchor</InputLabel>
                <Select
                    labelId={"select-anchor-end-label"}
                    id={"select-anchor-end"}
                    value={arrowProps.endAnchor}
                    label={'End anchor'}
                    onChange={(e) => handleUpdateArrow('arrowProps', {...arrowProps, endAnchor: e.target.value})}
                >
                    <MenuItem value={'auto'}>Auto</MenuItem>
                    <MenuItem value={'left'}>Left</MenuItem>
                    <MenuItem value={'right'}>Right</MenuItem>
                    <MenuItem value={'top'}>Top</MenuItem>
                    <MenuItem value={'bottom'}>Bottom</MenuItem>
                </Select>
            </FormControl>
            <ColorPicker
                color={arrowProps.color}
                setColor={(color) => handleUpdateArrow('arrowProps', {...arrowProps, color})}
                width={25}
                height={25}
                menuLeft={-80}
                menuTop={45}
            />
            <IconButton size={"small"} sx={{ml: '10px'}} onClick={() => setDeleteOpen(true)}>
                <DeleteForeverIcon />
            </IconButton>
            <DeleteDialog
                handleCancel={() => setDeleteOpen(false)}
                title={'Arrow'}
                type={'arrow'}
                open={deleteOpen}
                handleDelete={handleDeleteArrow}
            />
            <ReorderPicker origin={'bottom'} objectId={id} tooltip/>
        </Box>
    )
}
