export const SORTING = {
    ASC: 'ascending',
    DESC: 'descending',
    NAT: 'natural',
}

export const SORTING_OPTIONS = [
    {label: 'Ascending', value: SORTING.ASC},
    {label: 'Descending', value: SORTING.DESC},
    {label: 'Natural', value: SORTING.NAT},
]
