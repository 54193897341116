import {createBrowserRouter} from "react-router-dom";
import {Logout} from "../components/screens/Logout/Logout";
import {GoogleLogin} from "../components/screens/GoogleLogin/GoogleLogin";
import {AuthLayout} from "../components/screens/AuthLayout/AuthLayout";
import {ROUTES} from "./routes";
import {Canvas} from "../components/screens/Canvas/Canvas";
import {Explorer} from "../components/screens/Explorer/Explorer";
import {Process} from "../components/screens/Process/Process";
import {CanvasDashboard} from "../components/screens/CanvasDashboard/CanvasDashboard";
import {Source} from "../components/Source/Source";
import {ScoopAdmin} from "../components/screens/ScoopAdmin/ScoopAdmin";
import {Hubspot} from "../components/screens/Appconnect/Hubspot/Hubspot";
import {Salesforce} from "../components/screens/Appconnect/GMail/Salesforce";
import {DatasetIndex} from "../components/screens/Source/DatasetIndex/DatasetIndex";
import {DatasetInsight} from "../components/screens/Source/DatasetInsight/DatasetInsight";
import {LiveWorksheets} from "../components/screens/LiveWorksheets/LiveWorksheets";
import {Setup} from "../components/screens/Setup/Setup";
import {GMail} from "../components/screens/Appconnect/GMail/GMail";
import {Login} from "../components/screens/Login/Login";
import {Recipe} from "../components/screens/Recipe/Recipe";
import {APIConnectorAuth} from "../components/screens/Appconnect/API/APIConnectorAuth";


export const router = createBrowserRouter([
    {
        path: ROUTES.LOGIN,
        element: <Login/>
    },
    {
        path: ROUTES.RECIPE,
        element: <Recipe/>
    },
    {
        path: ROUTES.LOGOUT,
        element: <Logout/>
    },
    {
        path: ROUTES.GOOGLELOGIN,
        element: <GoogleLogin/>
    },
    {
        path: ROUTES.AUTH,
        element: <AuthLayout/>,
        children: [
            {
                path: ROUTES.CANVAS_DASHBOARD,
                element: <CanvasDashboard/>,

            },
            {
                path: ROUTES.CANVAS,
                element: <Canvas/>,
            },
            {
                path: ROUTES.EXPLORER,
                element: <Explorer/>,
            },
            {
                path: ROUTES.INSIGHTS,
                element: <Process/>,
            },
            {
                path: ROUTES.SOURCES,
                element: <DatasetIndex/>,
            },
            {
                path: ROUTES.SCOOPADMIN,
                element: <ScoopAdmin/>,
            },
            {
                path: ROUTES.SOURCE,
                element: <DatasetInsight/>,
            },
            {
                path: ROUTES.LIVE_WORKSHEETS,
                element: <LiveWorksheets/>,
            },
            {
                path: '/old-sources',
                element: <Source/>
            }
        ]
    },
    {
        path: ROUTES.HUBSPOT,
        element: <Hubspot/>
    },
    {
        path: ROUTES.SALESFORCE,
        element: <Salesforce/>
    },
    {
        path: ROUTES.GMAIL,
        element: <GMail/>
    },
    {
        path: ROUTES.APICONNECTORAUTH,
        element: <APIConnectorAuth/>
    },
    {
        path: ROUTES.SETUP,
        element: <Setup/>
    }
])
