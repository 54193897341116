import React, {useEffect, useState} from 'react';
import {
    IconButton,
    ListItem,
    ListItemText,
    Tooltip,
    Chip,
    Typography, Box, Icon,
    LinearProgress, CircularProgress
} from '@mui/material';
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import {StatusBubble} from "../../../common/StatusBubble/StatusBubble";
import Table from '../../../../assets/icons/Table.svg'
import './DatasetIndex.css'
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../../../utils/date";
import {ScoopLoader} from "../../../common/Spinner/ScoopLoader";

const identifyTrue = ( transactional) => {
    if (transactional) return "Transactional";
    return "Snapshot"; // Return this if none are true
}

export const DatasetRow = ({dataset, setAnchorEl, setSelectedInbox, id, processStatus}) => {

    const navigate = useNavigate();
    const { label, description, tables, lastIngestion, incremental, transactional, botVerified} = dataset;
    const [icon, setIcon] = React.useState(() => <></>);
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const status = processStatus.get(dataset.inboxID);
        if (status) {
            setLoading(true);
            setProgress((status.curStep * 100) / status.totalSteps)
        } else {
            setLoading(false);
        }
    }, [processStatus]);

    useEffect(() => {
        import(`../../../../assets/icons/${'GoogleDataStudio'}.svg`)
            .then((Svg) => setIcon(() => Svg.default))
            .catch(err => console.error("Failed to load svg:", err))
    }, [icon]);

    return (
        <ListItem key={id} className={`dataset-row ${loading ? 'dataset-row-disabled' : ''}`} onClick={(e)=> navigate( `${dataset.inboxID}`)}>
            <Icon sx={{height: '32px', width: '32px'}}>
                {<img className={'dataset-icon'} style={{}} src={icon} alt={'dataset-icon'}/>}
            </Icon>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'40%'} gap={'8px'}>
                <ListItemText
                    primary={label}
                    secondary={description}
                    sx={{
                        whiteSpace: 'nowrap',
                        minWidth: '250px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
                <Box sx={{width:'20%', display:'flex', alignItems:'flex-start'}}>
                    <Chip label={identifyTrue(transactional)} size="small" sx={{borderRadius:'5px', background:'#F9F9F9'}}/>

                </Box>
                <Chip size="small" label={
                    <Box display={'flex'} alignItems={'center'} gap={'4px'} justifyContent={'space-between'}>
                        <img src={Table} alt={'table'}/>
                        {tables.length}
                    </Box>
                }
                      sx={{borderRadius:'5px', background:'#F9F9F9'}}
                />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '8px', width:'50%', justifyContent:'flex-end'}}>
                {
                    loading ?
                    <>
                        <Box sx={{width: '100%', display: 'flex', gap: '16px', justifyContent:'flex-end', alignItems: 'center' }}>
                            <Typography className={'last-scoop-date'}>
                                Progress {progress === -1 ? 'Starting' : `${progress.toFixed(1)}%`}
                            </Typography>
                            <CircularProgress
                                sx={{
                                    height: '24px !important',
                                    width: '24px !important',
                                    '& .MuiCircularProgress-circle': {
                                        color: '#E50B54',
                                        strokeLinecap: "round",
                                    }
                                }}
                                variant={'determinate'}
                                value={progress !== -1 ? progress : 0}
                            />
                        </Box>
                    </> :
                    <>
                        <Typography className={'last-scoop-date'}>{formatDate(lastIngestion) ? `Last Scoop ${formatDate(lastIngestion)}`: ''}</Typography>
                        <Tooltip title="Last Scoop Status"><StatusBubble status={'success'}/></Tooltip>
                    </>
                }
            </Box>
            {!dataset.readOnly && <IconButton
                sx={{padding: '4px', borderRadius: '5px', color: 'inherit'}}
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget)
                    setSelectedInbox(dataset)
                }}
            >
                <MoreHorizRoundedIcon fontSize={'small'}/>
            </IconButton>}
        </ListItem>
    );
};
