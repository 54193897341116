import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import './Presentation.css';
import ArrowRight from '../../assets/icons/ArrowRight.svg';
import ArrowLeft from '../../assets/icons/ArrowLeft.svg';
import CaretDownBlack from '../../assets/icons/CaretDownBlack.svg';
import {Box, IconButton, MenuItem, Popover, Typography} from "@mui/material";
import {OBJECT_TYPES} from "../Objects/types";
import ImageElement from "../Objects/Image/ImageElement";
import VideoElement from "../Objects/Video/VideoElement";
import InsightElement from "../Objects/Insight/InsightElement";
import SheetletElement from "../Objects/Sheetlet/SheetletElement";
import TextEditorElement from "../Objects/TextEditor/TextEditorElement";
import {ArrowElement} from "../Objects/Arrow/ArrowElement";
import ProcessDiagramElement from "../Objects/ProcessDiagram/ProcessDiagramElement";
import {PromptElement} from "../Objects/Prompt/PromptElement";
import Button from "../common/Button/Button";
import {GenericShapeElement} from "../Objects/GenericShape/GenericShapeElement";
import CornersIn from '../../assets/icons/CornersIn.svg'
import CornersOut from '../../assets/icons/CornersOut.svg'
import {drawShape, isCanvasBlank} from "../CanvasGrid/DrawShapes";

export const Presentation = ({slides, workspaceMetadata}) => {

    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const presentationRef = useRef();
    const slideSelector = useRef();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideSelectorOpen, setSlideSelectorOpen] = useState(false);
    const [showControls, setShowControls] = useState(false);

    const handlePrevious = () => {
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1)
    }

    const handleNext = () => {
        if (currentSlide < (slides.length - 1)) setCurrentSlide(currentSlide + 1)
    }

    useEffect(() => {
        const handleArrowPress = (e) => {
            switch (e.key) {
                case 'ArrowLeft':
                    handlePrevious()
                    break;
                case 'ArrowRight':
                    handleNext()
                    break;
                default: break;
            }
        }
        window.addEventListener('keydown', handleArrowPress)
        return () => window.removeEventListener('keydown', handleArrowPress)
    }, [slides, currentSlide])

    const exitFullscreen = () => {
        if (isGuestMode) {
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                const elem = document.getElementById('root-canvas-container')
                if (elem?.requestFullscreen) {
                    elem.requestFullscreen()
                }
            }
        } else {
            document.exitFullscreen()
        }
        setShowControls(false)
    }

    const constructObject = (obj) => {
        const horizontalScale = slides[currentSlide].width / slides[currentSlide].frame.width
        const verticalScale = slides[currentSlide].height / slides[currentSlide].frame.height
        const avgScale = (horizontalScale + verticalScale) / 2
        switch (obj.type) {
            case OBJECT_TYPES.IMAGE:
                return (
                    <ImageElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale }}
                        url={obj.url?.replace('https://scoop-image-uploads.s3.us-west-2.amazonaws.com', 'https://d3lkv74tdz6isd.cloudfront.net')}
                        content={obj.content}
                    />
                )
                case OBJECT_TYPES.VIDEO:
                    return (
                        <VideoElement
                            key={obj.id}
                            id={obj.id}
                            initialPosition={{
                                x: obj.slideX * horizontalScale,
                                y: obj.slideY * verticalScale
                            }}
                            initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale }}
                            url={obj.url}
                            content={obj.content}
                        />
                    )
            case OBJECT_TYPES.INSIGHT:
                return (
                    <InsightElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale }}
                        content={obj.content}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        workspaceMetadata={workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.SHEETLET:
                const sheetWidth = obj.width * horizontalScale
                const sheetHeight = obj.height * verticalScale
                return (
                    <SheetletElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: sheetWidth, height: sheetHeight }}
                        content={obj.content}
                        shouldHideGrid={obj.shouldHideGrid}
                        shouldHideHeaders={obj.shouldHideHeaders}
                        presentationScale={sheetHeight > sheetWidth ? horizontalScale : verticalScale}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                    />
                )
            case OBJECT_TYPES.TEXT:
                let resultContent = obj.content
                if (obj.content.includes('font-size')) {
                    let scaledContent = obj.content.split('font-size:')
                    scaledContent.forEach((split, i) => {
                        if (i > 0) {
                            const tempStr = split.split(';')
                            const fontSize = tempStr[0]
                            let fontValue = fontSize.match(/[-.0-9]/g).join('')
                            fontValue = parseFloat(fontValue) * avgScale
                            const fontUnit = fontSize.match(/[a-zA-Z]/g).join('')
                            tempStr.splice(0, 1)
                            scaledContent[i] = fontValue + fontUnit + ';' + tempStr.join(';')
                        }
                    })
                    resultContent = scaledContent.join('font-size:')
                }
                return (
                    <TextEditorElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale }}
                        value={resultContent}
                        replacement={obj?.replacement}
                        initialBorder={obj.border}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                    />
                )
            case OBJECT_TYPES.ARROW:
                return (
                    <ArrowElement
                        id={obj.id}
                        key={obj.id}
                        arrowProps={obj.arrowProps}
                        startInitialPosition={{
                            x: obj.slideStartInitialPosition.x * horizontalScale,
                            y: obj.slideStartInitialPosition.y * verticalScale
                        }}
                        endInitialPosition={{
                            x: obj.slideEndInitialPosition.x * horizontalScale,
                            y: obj.slideEndInitialPosition.y * verticalScale
                        }}
                        multiProps={{}}
                    />
                )
            case OBJECT_TYPES.PROCESS:
                return (
                    <ProcessDiagramElement
                        key={obj.id}
                        id={obj.id}
                        title={obj.title}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale }}
                        content={obj.content}
                        workspaceMetadata={workspaceMetadata}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                    />
                )
            case OBJECT_TYPES.PROMPT:
                return (
                    <PromptElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale}}
                        promptProps={obj.promptProps}
                        workspaceID={workspaceID}
                        userID={userID}
                        token={token}
                        workspaceMetadata={workspaceMetadata}
                    />
                )
            case OBJECT_TYPES.GENERIC_SHAPE:
                return (
                    <GenericShapeElement
                        key={obj.id}
                        id={obj.id}
                        initialPosition={{
                            x: obj.slideX * horizontalScale,
                            y: obj.slideY * verticalScale
                        }}
                        initialSize={{ width: obj.width * horizontalScale, height: obj.height * verticalScale}}
                        content={obj.content}
                        shapeType={obj.shapeType}
                    />
                )
            default: return null
        }
    }

    useEffect(() => {
        const drawObjects = document.getElementsByClassName('drawObject')
        const verticalScale = slides[currentSlide].height / slides[currentSlide].frame.height
        const horizontalScale = slides[currentSlide].width / slides[currentSlide].frame.width
        const avgScale = (verticalScale + horizontalScale) / 2
        if (drawObjects.length > 0) {
            const drawArray = [...drawObjects]
            drawArray.forEach(canvas => {
                if (isCanvasBlank(canvas)) {
                    let shape = JSON.parse(canvas.dataset.shape)
                    if (shape) {
                        drawShape(canvas, shape)
                        // SCALE CANVAS
                        canvas.style.transformOrigin = 'top left'
                        canvas.style.transform = `scale(${avgScale})`
                    }
                }
            })
        }
    }, [currentSlide]);

    return (
        <>
            <Box
                id={'slide-container'}
                sx={{
                    backgroundColor: 'black',
                    width: '100%',
                    height: '100%',
                    display: 'grid',
                    placeContent: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
                ref={presentationRef}
            >
                <Box
                    id={'slide-content-container'}
                    sx={{
                        backgroundColor: slides[currentSlide].frame.backgroundColor,
                        backgroundImage: `url(${slides[currentSlide].frame.backgroundImage?.replace('https://scoop-image-uploads.s3.us-west-2.amazonaws.com', 'https://d3lkv74tdz6isd.cloudfront.net')})`,
                        backgroundSize: '100% 100%',
                        width: slides[currentSlide].width,
                        height: slides[currentSlide].height,
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    {slides[currentSlide].slideObjects.map(constructObject)}
                </Box>
                <Box className={'presentation-controls-container'} sx={{bottom: showControls ? '4px' : '-48px'}}>
                    <Box className={'presentation-controls'}>
                        <Box className={'toggle-controls'} onClick={() => setShowControls(!showControls)}>
                            <img
                                src={CaretDownBlack}
                                alt={'toggle'}
                                style={{
                                    height: 15,
                                    transform: showControls ? '' : 'rotate(180deg)',
                                    transition: 'all 300ms ease'
                                }}
                            />
                        </Box>
                        <IconButton onClick={handlePrevious} disabled={currentSlide === 0}>
                            <img src={ArrowLeft} alt={'previous'} />
                        </IconButton>
                        <Box className={'slide-title-button'} onClick={() => setSlideSelectorOpen(true)} ref={slideSelector}>
                            <Typography className={'inter'} sx={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {slides[currentSlide].frame.title}
                            </Typography>
                        </Box>
                        <IconButton onClick={handleNext} disabled={currentSlide === (slides.length - 1)}>
                            <img src={ArrowRight} alt={'next'} />
                        </IconButton>
                        <Button
                            className={`${isGuestMode ? '' : 'button-purple'} end-button`}
                            onClick={exitFullscreen}
                        >
                            {
                                isGuestMode ? (
                                    document.fullscreenElement ?
                                    <img src={CornersIn} alt={'exit-fullscreen'} /> :
                                    <img src={CornersOut} alt={'enter-fullscreen'} />
                                ) :
                                'End'
                            }
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Popover
                open={slideSelectorOpen}
                anchorEl={slideSelector.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                container={presentationRef.current}
                onClose={() => setSlideSelectorOpen(false)}
                slotProps={{
                    paper: {
                        sx: {
                            marginTop: '-10px',
                            boxShadow: '0px 0px 7px 0px rgba(20, 9, 42, 0.25)',
                            width: 200
                        }
                    }
                }}
            >
                {
                    slides.map((slide, index) => (
                        <MenuItem
                            key={'slide' + index}
                            onClick={() => {
                                setCurrentSlide(index)
                                setSlideSelectorOpen(false)
                            }}
                            sx={{
                                backgroundColor: currentSlide === index ? '#FCE7EE' : '',
                                color: currentSlide === index ? '#E50B54' : ''
                            }}
                        >
                            <Typography className={'inter'} sx={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {(index + 1) + '. ' + slide.frame.title}
                            </Typography>
                        </MenuItem>
                    ))
                }
            </Popover>
        </>
    )
}
