import {FormControl, Select} from "@mui/material";
import React from "react";
import './Selector.css'

const Selector = ({removeMargin, value, onChange, children, label, placeholder, labelClassName, ...props}) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    return (
        <FormControl
            id={removeMargin && "selector"}
            className={`selector-container`}>
            <label className={`selector-label ${labelClassName}`}>{label}</label>
            <Select
                className={`selector ${isFocused ? 'purple-border-selector' : ''}`}
                labelId="workspace-select-label"
                id="workspace-select"
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...props}
            >
                {children}
            </Select>
        </FormControl>
    )
}
export default Selector;
