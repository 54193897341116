import React, {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useApi} from "../../../../api/api";
import {Server} from "../../../../api/Server";
import Dialog from '../../../common/Dialog/Dialog';
import Selector from "../../../common/Selector/Selector";
import Button from "../../../common/Button/Button";
import {
    Box,
    Modal,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Input
} from '@mui/material';

const SaveDiagram = ({isOpen, closeModal, diagramSavedAs}) => {

    const [newDiagramName, setNewDiagramName] = useState("");

    const handleSaveDiagram = () => {
        diagramSavedAs(newDiagramName);
        closeModal();
    };

    // Actions for the modal
    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={closeModal} className='primary-button' text='Cancel'/>
            <Button onClick={handleSaveDiagram} className='primary-button button-purple' text='Save Diagram'
                    disabled={newDiagramName.length < 3}/>
        </Box>
    );

    return (
        <Dialog onClose={closeModal} open={isOpen} title="Save this Diagram As..." actions={actions} fullWidth>
            <Input
                label={'Diagram Name'}
                placeholder={'Name this Diagram'}
                value={newDiagramName}
                onChange={(event) => setNewDiagramName(event.target.value)}
            />
        </Dialog>
    );
};

export default SaveDiagram;
