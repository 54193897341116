import {
    Box,
    Button,
    css,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import SourceIcon from '@mui/icons-material/Source';
import TableChartIcon from '@mui/icons-material/TableChart';
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";
import {DataGrid} from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import './Source.css';


export function NewSourceDialog({open, setOpen, serverContext, getWorkspaceMetadata}) {
    const [tableName, setTableName] = React.useState("");
    const [sourceType, setSourceType] = React.useState("transactional");

    function handleClose(event) {
        setOpen(false);
    }

    function handleCreateCSW(result) {
        getWorkspaceMetadata(serverContext.server);
    }

    function handleSave(event) {
        setOpen(false);
        serverContext.server.postData({
            "action": "createCalculatedSheet", "inboxName": tableName, "sourceType" : {sourceType}
        }, handleCreateCSW);
    }

    return (<Dialog open={open} onClose={handleClose} maxWidth={'800px'}>
        <DialogTitle sx={{color: 'text.secondary'}}>
            <Typography sx={{fontWeight: 'bold', mb: 2, fontSize: 22}}>Create New Dataset</Typography>
            <Box sx={{display: 'flex'}}><Box sx={{display: 'flex', alignItems: 'center', width: '200px'}}>Table
                Name:</Box><TextField value={tableName}
                                      sx={{width: '100%'}} onChange={(event) => {
                setTableName(event.currentTarget.value);
            }}/></Box></DialogTitle>
        <DialogContent sx={{width: '100%'}}>
            <DialogContentText>
                Create a New Scoop Dataset
                <FormControl>
                    <FormLabel>Dataset Type</FormLabel>
                    <RadioGroup name="source-type-group" value={sourceType} onChange={(event)=>{setSourceType(event.target.value)}}>
                        <span><Radio value="transactional"/><strong>Transactional</strong>: Accept all new reports as transactions, i.e. as  additional records to the existing database. All records are cumulative.</span>
                        <span><Radio value="snapshot"/><strong>Snapshot</strong>: Treat each report as a new snapshot of an existing set of items, i.e. each report lists the current state of a set of objects. The unique key in each report is used to track changes for each item. Snapshots allow tracking of individual items and their state over time.</span>
                    </RadioGroup>
                </FormControl>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>OK</Button>
        </DialogActions>
    </Dialog>);
}