import {OBJECT_TYPES} from "../types";

export const KPI_MIN_SIZE = {"width": 200, "height": 200};

export class KPIClass {
  constructor(id, title, x, y, width, height) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.type = OBJECT_TYPES.KPI;
  }

  static newKPI(zoom, x ,y) {
    const canvasScrollElement = document.getElementById('canvas-scrollable')
    return new KPIClass(
        Math.random(),
        'My KPI',
        x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
        y ? y : (200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
        400,
        400
    );
  }
}
