import React from 'react';
import PresentationChart from "../../assets/icons/PresentationChart.svg";
import Eye from "../../assets/icons/Eye.svg";
import ChartLine from "../../assets/icons/ChartLine.svg";
import DataBase from "../../assets/icons/Database.svg";
import Worksheet from "../../assets/icons/Worksheet.svg";
import {ROUTES} from "../../router/routes";

export const navbarItems = [
    {
        icon: <img src={PresentationChart} alt="Presentation Chart" />,
        label: 'Canvas',
        route: ROUTES.CANVAS_DASHBOARD,
    },
    {
        icon: <img src={Eye} alt="Eye" />,
        label: 'Explorer',
        route: ROUTES.EXPLORER,
    },
    {
        icon: <img src={ChartLine} alt="Chart Line" />,
        label: 'Insights',
        route: ROUTES.INSIGHTS,
    },
    {
        icon: <img src={DataBase} alt="Database" />,
        label: 'Sources',
        route: ROUTES.SOURCES,
    },
    {
        icon: <img src={Worksheet} alt="Live Worksheets" />,
        label: 'Live Worksheets',
        route: ROUTES.LIVE_WORKSHEETS,
    },
];
