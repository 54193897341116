// SheetletEdit.js
import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GridIcon from '@mui/icons-material/GridOn';
import HeadersIcon from '@mui/icons-material/PivotTableChart';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteSheetlet, updateSheetletGrid, updateSheetletHeaders } from '../../../store/actions';
import Modal from '@mui/material/Modal';
import { Box } from "@mui/material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { LoadSheetletDialog } from "../../Source/LoadSheetletDialog"
import { useSelector } from 'react-redux';
import { loadFromSavedSheetlet } from './SheetletAPI';
import { useApi } from '../../../api/api';
import DeleteDialog from "../../common/Dialog/DeleteDialog";
import Sheetlet from "../../Source/Sheetlet";
import {setShowEditElementWrapper} from "../../../store/actions/uiActions";
import PaletteIcon from "@mui/icons-material/Palette";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const modalBoxStyle = {
    position: 'absolute',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    height: '95vh',
    backgroundColor: '#27142c',
    boxShadow: 24,
};


export default function SheetletEdit({
    setIsMenuOpen,
    isSheetletASkeleton,
    setIsSheetletASkeleton,
    server,
    title,
    id,
    worksheetID,
    worksheetRange,
    worksheetNum,
    worksheetName,
    worksheetURL,
    handleTitleChange,
    editSheetletOpen,
    setEditSheetletOpen,
    shouldHideGrid,
    shouldHideHeaders
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuRef = React.useRef();
    const buttonRef = React.useRef();
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const [loadOpen, setLoadOpen] = React.useState(false);
    const [selectedItemToLoad, setSelectedItemToLoad] = React.useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // Get auth details from the state
    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const workspaceID = useSelector(state => state.auth.workspaceID);

    const { postData } = useApi(); // Use the useApi hook here

    const dispatch = useDispatch();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setIsMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        const actionType = event?.actionType;
        if (actionType === 'delete') {
            setIsAlertOpen(true);
        }
        else if (actionType === 'open') {
            window.open(worksheetURL, '_blank');
        } else if (actionType === 'load') {
            setLoadOpen(true);
        }
        else if (actionType === 'load') {
            setLoadOpen(true);
        }
        else {
            setIsMenuOpen(false);
            setAnchorEl(null);
        }
    };

    const handleDelete = () => {
        console.log("Deleting Sheetlet: " + id);
        dispatch(deleteSheetlet(id));
        setIsMenuOpen(false);
        setIsAlertOpen(false);
        setAnchorEl(null);
    };

    const handleEdit = () => {

    };

    const handleLoadFromSavedSheetlet = (sheetlet) => {
        if (sheetlet) {
            handleTitleChange({ target: { value: sheetlet.worksheetName } });
            loadFromSavedSheetlet(sheetlet, setIsSheetletASkeleton, dispatch, id, selectedItemToLoad, workspaceID);
        }
    };


    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize='medium' color="info" />
                </IconButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {!isSheetletASkeleton && (
                        <MenuItem
                            onClick={(event) => {
                                event.actionType = 'open';
                                handleClose(event);
                            }}
                            disableRipple
                        >
                            <EditIcon />
                            Open
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => dispatch(setShowEditElementWrapper([true, id]))}
                        disableRipple
                    >
                        <PaletteIcon />
                        Style
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'load';
                            handleClose(event);
                        }}
                        disableRipple
                    >
                        <FileOpenIcon />
                        Load
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />

                    {/* Grid */}
                    <MenuItem
                        onClick={(event) => {
                            dispatch(updateSheetletGrid(id, !shouldHideGrid));
                            event.actionType = 'toggleGrid';
                            handleClose();
                        }}
                        disableRipple
                    >
                        <GridIcon />
                        {!shouldHideGrid ? 'Hide Grid' : 'Show Grid'}
                    </MenuItem>
                    {/* Headers */}
                    <MenuItem
                        onClick={(event) => {
                            dispatch(updateSheetletHeaders(id, !shouldHideHeaders));
                            event.actionType = 'toggleHeaders';
                            handleClose();
                        }}
                        disableRipple
                    >
                        <HeadersIcon />
                        {!shouldHideHeaders ? 'Hide Headers' : 'Show Headers'}
                    </MenuItem>





                    <Divider sx={{ my: 0.5 }} />




                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'delete';
                            handleClose(event);
                        }}
                        disableRipple
                    >
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </StyledMenu>
            </div>
            {isAlertOpen && (
                <DeleteDialog
                    open={isAlertOpen}
                    title={title}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    type={'Sheetlet'}
                    handleDelete={handleDelete}
                    handleCancel={() => setIsAlertOpen(false)}
                />

            )}


            <Modal
                open={editSheetletOpen}
                onClose={() => setConfirmDialogOpen(true)} // Set the confirmation dialog to open when the Modal is closed
            >
                <Box sx={modalBoxStyle}>
                    <Sheetlet workspaceID={workspaceID} userID={userID} token={token} />
                </Box>
            </Modal>
            <LoadSheetletDialog setAnchor={setAnchorEl} open={loadOpen} setOpen={setLoadOpen} setSheetlet={handleLoadFromSavedSheetlet}
                workspaceMetadata={true} workspaceID={workspaceID} server={server} setSelectedItemToLoad={setSelectedItemToLoad} />
        </>
    );
}
