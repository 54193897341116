// authReducer.js
import { validateToken } from "../../api/tokenValidation";
import { UPDATE_CANVASES } from "../actions";
import { SET_GUEST_PROMPTS, setAutoShowTourWorkspace } from "../actions/authActions";

const initialState = {
  userFirstName: null,
  userLastName: null,
  accountID: null,
  googleAccount: null,
  userID: null,
  userEmail: null,
  token: null,
  refreshToken: null,
  isAuthenticated: validateToken(localStorage.getItem('userToken')),
  workspaceID: localStorage.getItem('workspaceID') || '', // Read from local storage or set to null to invoke open a workspace dialog
  workspaceName: localStorage.getItem('workspaceName') || '', // Read from local storage or set to null to invoke open a workspace dialog
  apiURL: process.env.REACT_APP_API_URL,
  workspacesAccessible: [],
  workspaceMetadata: null,
  canvasID: null, //TO-DO: eventually this will need to support all canvases avail to the user, but for now it's just for one specific canvas
  canvasName: null,
  canvases: [],
  isGuestMode: false, //if true, no real user here; just a guest who got in via invite so bypass auth token in calls and restrict access
  guestPrompts: [],
  autoShowTourWorkspace: true
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED':
      localStorage.setItem('userToken', action.user?.signInUserSession.idToken.jwtToken);
      localStorage.setItem('refreshToken', action.user?.signInUserSession.refreshToken.token);
      localStorage.setItem('userID', action.user?.username)
      localStorage.setItem('userName', action.user?.attributes.email)
      localStorage.setItem('userFirstName', action.user?.attributes.userFirstName)
      if (action.user?.attributes.email) {
        localStorage.setItem('userEmail', action.user?.attributes.email)
      }
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        userID: action.user?.username,
        userName: action.user?.attributes.email,
        userEmail: action.user?.attributes.email,
        token: action.user?.signInUserSession.idToken.jwtToken,
        refreshToken: action.user?.signInUserSession.refreshToken.token,
      };
    case 'LOGOUT':
      localStorage.clear()

      return {
        ...state,
        userName: null,
        userEmail: null,
        idToken: null,
        refreshToken: null,
        isAuthenticated: false,
      };
    case 'UPDATE_USER_INFO':
      return {
        ...state,
        accountID: action.user.attributes.accountID,
        userFirstName: action.user.attributes.firstName,
        userLastName: action.user.attributes.lastName,
        googleAccount: action.user.attributes.googleAccount,
        workspacesAccessible: action.user.attributes.workspacesAccessible,
      };
    case 'UPDATE_CANVAS':
      return {
        ...state,
        canvasID: action.canvasID,
        canvasName: action.canvasName,
      };
    case 'SWITCH_WORKSPACE':
      localStorage.setItem('workspaceID', action.workspaceID);
      localStorage.setItem('workspaceName', action.workspaceName);

      return {
        ...state,
        workspaceID: action.workspaceID,
        workspaceName: action.workspaceName,
      };
    case 'UPDATE_CANVASES':
      return {
        ...state,
        canvases: action.payload,
      };
    case 'SET_GUEST_MODE':
      return {
        ...state,
        isGuestMode: true,
      };
    case SET_GUEST_PROMPTS:
      return {
        ...state,
        guestPrompts: action.payload.guestPrompts,
      };
    case 'SET_AUTO_SHOW_TOUR_WORKSPACE':
      return {
        ...state,
        autoShowTourWorkspace: false,
      };
    default:
      return state;
  }
};

export default authReducer;
