// UploadWorksheet.js
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useApi } from '../../api/api';
import { useDropzone } from 'react-dropzone';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import UploadGrey from '../../assets/icons/UploadGrey.svg'
import File from '../../assets/icons/File.svg'
import Trash from '../../assets/icons/Trash.svg'
import { ROUTES } from "../../router/routes";



import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Divider,
    Box,
    TextField,
    Select,
    MenuItem,
    LinearProgress,
} from '@mui/material';
import { set } from 'lodash';
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";
import IconButton from "@mui/material/IconButton";
import Input from "../common/Input/Input";



const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '300px',
    minWidth: '450px',
    fontSize: '14px'
};

const focusedStyle = {
    borderColor: '#FFA6C3',
    backgroundColor: '#FCE7EE'
};

const acceptStyle = {
    borderColor: '#00e676',
    backgroundColor: '#e5fbd7'

};

const rejectStyle = {
    borderColor: '#ff1744',
    backgroundColor: '#ffd7d7'

};

 // when user uploads an image to S3, make sure the filename is safe
function safeS3FileName(str) {
    return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
}




export default function UploadWorksheet({ isOpen, onClose, onRefresh }) {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            // 'application/vnd.ms-excel': [],
            'text/xlsx': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
    });

    // Hook to fetch data
    const { postData: postDataDefault } = useApi();
    const { postData: postDataWorkspaceactions } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const workspaceName = useSelector((state) => state.auth.workspaceName);
    const userID = useSelector((state) => state.auth.userID);
    const userName = useSelector((state) => state.auth.userName);
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    


    const [file, setFile] = useState(null);
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [disableUpload, setDisableUpload] = useState(false);






    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const [step, setStep] = useState(0);  // 0 for file upload, 1 for additional details

    const handleNext = () => {
        setStep(1);
    };

    const handleBack = () => {
        setStep(0);
    };

    useEffect(() => {
        if (acceptedFiles.length > 0 && label === '') {
            setLabel(acceptedFiles[0].name.replace('.pptx', ''));
        }
    }, [acceptedFiles]);

    const handleUpload = async () => {
        setProgress(1);  // Initiate the progress
        setDisableUpload(true);
        // Create the payload based on selected option
        let payload = {
            "action": "uploadSpreadsheet",
            "workspaceID": workspaceID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        try {
            const presignedData = await postDataWorkspaceactions({
                ...payload,
                fileName: safeS3FileName(acceptedFiles[0].name),
                fileType: acceptedFiles[0].type
            });

            await fetch(presignedData.signedRequest, {
                method: 'PUT',
                body: acceptedFiles[0],
                headers: {
                    'Content-Type': acceptedFiles[0].type
                }
            });

            setProgress(100);
            showSnackbar("Spreadsheet file uploaded successfully! Please wait a few moments for the new worksheet to be created.", "success");

            // Directly call the creation of canvas from presentation
            await createWorksheetFromUpload();

        } catch (error) {
            console.error("Error uploading the file:", error);
            setProgress(0);  // Reset the progress
            showSnackbar("Error uploading file", "error");
            setTimeout(() => {
                onClose();
            }, 3000);
        }
    };

    const createWorksheetFromUpload = async () => {
        setIsLoading(true)
        const action = {
            "action": "createLiveWorksheetFromExcel",
            "workspaceID": workspaceID,
            "userID": userID,
            "label": label,
            "description": description,
            "fileName": safeS3FileName(acceptedFiles[0].name)
        };

        try {
            const result = await postDataDefault(action);
            if (result?.worksheetID) {
                // Success
                // dispatch({
                //     type: 'APPLY_LOADED_OBJECTS_STATE',
                //     payload: [],
                // });
                showSnackbar("You will see your spreadsheet as a worksheet momentarily.", "success");
                setTimeout(() => {
                    onRefresh(); 
                }, 5000);
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error creating worksheet from spreadsheet:", error);
        }
    };



    const [progress, setProgress] = useState(-1);

    useEffect(() => {
        let timer;
        if (progress > 0 && progress < 100) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + 3, 100));
            }, 1000);
        } else if (progress >= 100) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const dialogActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">

            {step === 0 && (
                <>
                    <Button
                        onClick={onClose}
                        text={'Cancel'}
                        className={'button-grey'}
                    />
                    <Button
                        className={'button-purple'}
                        onClick={handleNext}
                        color="primary"
                        disabled={acceptedFiles.length === 0}
                        text={'Next'}
                    />
                </>

            )}
            {step === 1 && (
                <>
                    <Button onClick={handleBack} className={'button-grey'} text={'Back'}>
                    </Button>
                    <Button onClick={handleUpload} className={'button-purple'} text={'Upload'} disabled={!label || disableUpload} />
                </>
            )}
        </Box>
    )

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={onClose}
                title={'Upload a Spreadsheet file'}
                actions={dialogActions}
                style={{ minWidth: '500px' }}
            >
                {step === 0 && (
                    <section className="container">
                        <div {...getRootProps({ className: 'dropzone', style })}>
                            <input {...getInputProps()} />
                            <img src={UploadGrey} alt={'upload'} />
                            <div style={{ margin: '8px 0 16px 0' }}>.XLSX</div>
                            <div style={{ color: '#2B1630' }}>Drag 'n' drop your data file here or </div>
                            <a style={{ color: '#BF0A46', cursor: "pointer" }}>click here to select</a>
                        </div>
                        {acceptedFiles.length > 0 && (
                            <>
                                <ul>{acceptedFileItems}</ul>
                            </>
                        )}
                    </section>
                )}

                {step === 1 && (
                    <section>

                        <Box>
                            {
                                acceptedFiles.map(file => (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column', // Elements are stacked vertically.
                                        padding: '12px 16px',
                                        backgroundColor: '#F9F9F9',
                                        borderRadius: '5px',
                                        marginBottom: '16px'
                                    }} key={file.path}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <img src={File} alt={'file'} />
                                            <div style={{
                                                marginLeft: '8px',
                                                flexGrow: 1,
                                                fontSize: '14px'
                                            }}>{file.path}</div>
                                            <img src={Trash} alt={'delete'} style={{ cursor: 'pointer', height: '18px' }} />
                                        </Box>
                                        <Box sx={{ marginTop: '20px' }}>
                                            <Input
                                                placeholder={'Spreasheet name'}
                                                value={label}
                                                onChange={(event) => setLabel(event.target.value)}
                                                fullWidth
                                                inputProps={{ style: { marginBottom: '20px' } }} // Apply margin directly to input element
                                            />
                                        </Box>
                                        <Box sx={{ marginTop: '20px' }}>
                                            <Input
                                                placeholder={'Spreadsheet description'}
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                                fullWidth
                                                inputProps={{ style: { marginBottom: '20px' } }} // Apply margin directly to input element
                                            />
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>



                        {
                            progress !== -1 && !isLoading &&
                            <LinearProgress variant="determinate" value={progress} style={{ marginTop: '20px' }} />
                        }
                    </section>
                )}
                {isLoading ? <LinearProgress variant="indeterminate" value={progress} style={{ marginTop: '20px' }} /> : ''}
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
