import {useEffect, useState} from "react";
import BlendingModal from "./BlendingModal";
import SettingsModal from "./SettingsModal";
import CalculatedColumnsDialog from "./CalculatedColumnsDialog";
import {ServerContext} from "../../../../Source/SheetState";
import {Server} from "../../../../../api/Server";
import {useSelector} from "react-redux";

const AggregatePath = ({ onClose, goBack, inboxID, inboxName, worksheetID, handleSave, metadata,serverContext }) => {
    const [step, setStep] = useState(0)

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <BlendingModal
                        metadata={metadata}
                        index={0}
                        steps={['Aggregate Data', 'Create Calculated Columns', 'Settings']}
                        setStep={setStep}
                        onClose={onClose}
                        goBack={goBack}
                        goForward={() => setStep(1)}
                        title={'Aggregate Data'}
                        subtitle={'Select and configure the data you’d like to use in your new dataset'}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                        isBlending={false}
                    />
                )
            case 1:
                return (
                    <CalculatedColumnsDialog
                        serverContext={serverContext}
                        isBlending={false}
                        steps={['Aggregate Data', 'Create Calculated Columns', 'Settings']}
                        onClose={onClose}
                        goBack={()=> setStep(0)}
                        activeStep={1}
                        goForward={() => setStep(2)}
                        inboxID={inboxID}
                    />
                )
            case 2:
                return (
                    <SettingsModal
                        serverContext={serverContext}
                        inboxID={inboxID}
                        isBlending={false}
                        steps={['Aggregate Data', 'Create Calculated Columns', 'Settings']}
                        setStep={setStep}
                        onClose={onClose}
                        activeStep={2}
                        save={handleSave}
                        goBack={() => setStep(1)}
                        inboxName={inboxName}
                    />
                )
        }
    }

    return (
        <>{renderStepContent()}</>
    );

}


export default AggregatePath;