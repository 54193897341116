import {Box, Typography} from "@mui/material";
import RadioButtonActive from "../../../../assets/icons/RadioButtonActive.svg"
import RadioButtonDisable from "../../../../assets/icons/RadioButtonDisable.svg"

export const TypeBox = ({title, description, active, setActive, index, enabled}) => {
    return (
        <Box
            onClick={() => {if (enabled) {setActive(title)}}}
            className={`dataset-type-box ${active === title && 'selected'}`}>
            {
                active === title ?
                    <img src={RadioButtonActive} alt={'active'}/>
                    :
                    <img src={RadioButtonDisable} alt={'disabled'}/>
            }
            <Box>
                <Typography sx={{fontSize: '16px', color: '#2B1630', fontWeight: 600}}>{title}</Typography>
                <Typography sx={{fontSize: '14px', color: '#635566', marginTop: '4px'}}>{description}</Typography>
            </Box>
        </Box>
    )
}