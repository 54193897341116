import React, { useRef, useState } from "react";
import {Box, IconButton, Menu, MenuItem, Popover, Tooltip, Typography} from "@mui/material";
import SubtractSquare from '../../../assets/icons/SubtractSquare.svg';
import SelectionForeground from '../../../assets/icons/SelectionForeground.svg';
import SelectionBackground from '../../../assets/icons/SelectionBackground.svg';
import CaretRight from '../../../assets/icons/CaretRight.svg';
import { useDispatch } from "react-redux";
import { bringToFront, sendToBack } from "../../../store/actions";
import {tooltipClasses} from "@mui/material/Tooltip";
import { styled } from '@mui/material/styles';


const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        padding: 0,
        color: 'black',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
    },
}));


export const ReorderPicker = ({ origin, objectId, title, closeMenu, tooltip }) => {

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.target);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const getAnchorProps = () => {
        if (origin === 'top') {
            return {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            };
        } else if (origin === 'right') {
            return {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                }
            };
        } else {
            return {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            };
        }
    };

    const handleReorder = (order) => {
        if (order === 'front') {
            dispatch(bringToFront(objectId));
        } else {
            dispatch(sendToBack(objectId));
        }
        handlePopoverClose()
        closeMenu()
    };

    const open = Boolean(anchorEl);

    const TooltipTitle = () => {
        return (
            <Box sx={{backgroundColor: 'white', borderRadius: '4px'}}>
                <MenuItem onClick={() => handleReorder('front')}>
                    <img src={SelectionBackground} alt={'front'} style={{marginRight: '10px'}}/>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>Bring to front</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleReorder('back')}>
                    <img src={SelectionForeground} alt={'back'} style={{marginRight: '10px'}}/>
                    <Typography className={'inter'} sx={{fontSize: '14px'}}>Send to back</Typography>
                </MenuItem>
            </Box>
        )
    }

    return (
        <>
            {title ?
                (
                    <>
                        <CustomTooltip
                            placement={"right-start"}
                            ref={anchorEl}
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            title={<TooltipTitle />}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [-2, -14],
                                        },
                                    },
                                ],
                            }}
                        >
                            <MenuItem sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', cursor: 'pointer', width: '200px'}}>
                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <img style={{ marginRight: '12px', height: '20px' }} src={SubtractSquare} alt={'size'} className={'size-picker'} />
                                    <Typography className={'inter'} sx={{ fontSize: '14px' }}>{title}</Typography>
                                </Box>
                                <img src={CaretRight} alt={'icon'} />
                            </MenuItem>
                        </CustomTooltip>
                    </>
            ) : (
                    <>
                        <Tooltip title={'Reorder'} disableHoverListener={!tooltip}>
                            <IconButton onClick={handlePopoverOpen}>
                                <img src={SubtractSquare} alt={'size'} className={'size-picker'} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handlePopoverClose}
                            {...getAnchorProps()}
                        >
                            <MenuItem onClick={() => handleReorder('front')}>
                                <img src={SelectionBackground} alt={'front'} style={{marginRight: '10px'}} />
                                <Typography className={'inter'} sx={{fontSize: '14px'}}>Bring to front</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => handleReorder('back')}>
                                <img src={SelectionForeground} alt={'back'} style={{marginRight: '10px'}} />
                                <Typography className={'inter'} sx={{fontSize: '14px'}}>Send to back</Typography>
                            </MenuItem>
                        </Popover>
                    </>
            )}
        </>
    );
};
