import {styled} from "@mui/material/styles";
import {Box, Tabs} from "@mui/material";
import { Tab as MuiTab } from '@mui/material';

const CustomTabs = styled(Tabs)({
    '&.MuiTabs-root': {
        padding: '4px 6px !important',
        backgroundColor: '#F2F2F2 !important',
        borderRadius: '5px',
        width: '100%',
        minHeight: 'auto',
    },
    '& .MuiButtonBase-root': {
        textTransform: 'none',
        padding: '8px 16px',
        borderRadius: '3px',
        minHeight: 'auto',
        color: '#979099 !important',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '140%',
        flex: '1',
    },
    '& .MuiButtonBase-root.Mui-selected': {
        backgroundColor: '#FFF',
        color: '#2B1630 !important'
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    }
});

export const Tab = ({
                        tabs,
                        value,
                        onChange,
                        ...props
}) => {

    return (
        <Box sx={{
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            '& .MuiButtonBase-root': { padding: '6px 16px !important' }

        }}>
            <CustomTabs
                {...props}
                value={value}
                onChange={onChange}
            >
                {tabs?.map(tab => (
                    <MuiTab key={tab.key} label={tab.label} value={tab.key} />
                ))}
            </CustomTabs>
        </Box>
    )
}