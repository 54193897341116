import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {Box} from "@mui/material";
import Selector from "../../../common/Selector/Selector";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../../common/Button/Button";

export const MatchFields = ({source, goBack}) => {

    const options = [
        "First Name",
        "Last Name",
        "Email",
    ]

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '48px',
            maxWidth: '620px',
            margin: 'auto'
        }}>
            <Typography
                sx={{
                    color: '#635566',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    paddingTop: '42px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                    letterSpacing: '-0.28px',
                }}
            >
                Great, we’ve got your {source.name} report! Match your report fields to this recipe
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <Box sx={{width: '100%', display: 'flex'}}>
                    <Typography
                        sx={{width: '50%'}}
                        style={{
                            color: 'var(--Neutral-100, #201024)',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '140%',
                            letterSpacing: '-0.24px',
                        }}
                    >
                        Recipe Column
                    </Typography>
                    <Typography
                        sx={{width: '50%'}}
                        style={{
                            color: 'var(--Neutral-100, #201024)',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '140%',
                            letterSpacing: '-0.24px',
                        }}
                    >
                        Your Report Column
                    </Typography>
                </Box>
                <MatchingRow column={"First Name"} options={options}/>
                <MatchingRow column={"Last Name"} options={options}/>
                <MatchingRow column={"Email"} options={options}/>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <Button
                    disabled={false}
                    className="button-purple small"
                >
                    Continue
                </Button>
            </Box>
        </Box>
    )

}

const MatchingRow = ({column, options}) => {

    const [option, setOption] = useState(options[0] || '')

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <Box sx={{
                display: 'flex',
                minWidth: '300px',
                padding: '7px 12px',
                border: '1px solid #E6E4E6',
                backgroundColor: '#F9F9F9',
                height: '38px',
                borderRadius: '5px',
                alignItems: 'center'
            }}>
                <Typography
                    style={{
                        color: 'var(--Neutral-200, #2B1630)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%', // 19.6px
                        letterSpacing: '-0.28px',
                    }}
                >
                    {column}
                </Typography>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Box sx={{
                    width: '20px',
                    height: '1px',
                    background: '#D0CDD1',
                }}/>
            </Box>
            <Box sx={{height: '38px', width: '300px', alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Selector
                    value={option}
                    style={{height: '38px'}}
                    removeMargin
                    onChange={(e) => setOption(e.target.value)}
                >
                    {options && options.map((option, index) => (
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                    ))}
                </Selector>
            </Box>
        </Box>
    )

}