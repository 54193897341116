import React, {useCallback, useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {Slider} from "../../../../../common/Slider/Slider";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {
    FONT_FAMILIES,
    FONT_WEIGHTS,
    H_ALIGNMENT,
    LEGEND_DEFAULT_VALUES, MAX_LEGENDS,
    SYMBOLS,
    V_ALIGNMENT
} from "../styleConsts";
import {debounce, cloneDeep} from "lodash";
import {Switch} from "../../../../../common/Switch/Switch";

export const LegendConfig = ({config, setConfig, chartPreferences}) => {

    const [borderWidth, setBorderWidth] = useState(LEGEND_DEFAULT_VALUES.borderWidth);
    const [fontSize, setFontSize] = useState(LEGEND_DEFAULT_VALUES.textStyle.fontSize);

    useEffect(() => {
        setFontSize(getLegendTextStylePropValue('fontSize'))
        setBorderWidth(getLegendPropValue('borderWidth'))
    }, [config.styleOverrides.legend])

    const debouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.legend[prop] = value
        setConfig(newConfig)
    }, 200), [config]);

    const debouncedTextStyleFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.legend.textStyle[prop] = value
        if (prop === 'fontSize') {
            newConfig.styleOverrides.legend.textStyle.fontScaleFactor = {
                x: value / (window.innerWidth - 600),
                y: value / (window.innerHeight - 112)
            }
        }
        setConfig(newConfig)
    }, 200), [config]);

    const getLegendPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.legend, prop)) return config.styleOverrides.legend[prop]
        if (propExistsOnObject(chartPreferences.legend, prop)) return chartPreferences.legend[prop]
        return LEGEND_DEFAULT_VALUES[prop]
    }

    const getLegendTextStylePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.legend.textStyle, prop)) return config.styleOverrides.legend.textStyle[prop]
        if (propExistsOnObject(chartPreferences.legend?.textStyle, prop)) return chartPreferences.legend.textStyle[prop]
        return LEGEND_DEFAULT_VALUES.textStyle[prop]
    }

    const handleDebounceLegendPropChange = (prop, value, setter) => {
        debouncedFunc(prop, value)
        setter(value)
    }

    const handleLegendPropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.legend[prop] = value
        setConfig(newConfig)
    }

    const handleDebounceLegendTextStylePropChange = (prop, value, setter) => {
        debouncedTextStyleFunc(prop, value)
        setter(value)
    }

    const handleLegendTextStylePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.legend.textStyle[prop] = value
        setConfig(newConfig)
    }

    const showLegend = getLegendPropValue('show')
    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                <Switch
                    checked={showLegend}
                    onClick={() => handleLegendPropChange('show', !showLegend)}
                />
                <Typography className={'inter style-config-label'}>Show legend</Typography>
            </Box>
            {
                renderSelector(
                    'Maximum',
                    getLegendPropValue('maxLegends'),
                    (v) => handleLegendPropChange('maxLegends', v),
                    MAX_LEGENDS
                )
            }
            {
                renderSelector(
                    'Icon',
                    getLegendPropValue('icon'),
                    (v) => handleLegendPropChange('icon', v),
                    SYMBOLS
                )
            }
            {
                renderSelector(
                    'Horizontal alignment',
                    getLegendPropValue('left'),
                    (v) => handleLegendPropChange('left', v),
                    H_ALIGNMENT
                )
            }
            {
                renderSelector(
                    'Vertical alignment',
                    getLegendPropValue('top'),
                    (v) => handleLegendPropChange('top', v),
                    V_ALIGNMENT
                )
            }
            {
                renderSelector(
                    'Orientation',
                    getLegendPropValue('orient'),
                    (v) => handleLegendPropChange('orient', v),
                    [{label: 'Horizontal', value: 'horizontal'}, {label: 'Vertical', value: 'vertical'}]
                )
            }
            {
                renderSelector(
                    'Font family',
                    getLegendTextStylePropValue('fontFamily'),
                    (v) => handleLegendTextStylePropChange('fontFamily', v),
                    FONT_FAMILIES
                )
            }
            {
                renderSelector(
                    'Font weight',
                    getLegendTextStylePropValue('fontWeight'),
                    (v) => handleLegendTextStylePropChange('fontWeight', v),
                    FONT_WEIGHTS
                )
            }
            <Slider
                label={`Font size: ${fontSize}px`}
                value={fontSize}
                min={8}
                max={64}
                step={1}
                onChange={(v) => handleDebounceLegendTextStylePropChange('fontSize', v, setFontSize)}
            />
            {
                renderColorPicker(
                    'Font color',
                    getLegendTextStylePropValue('color'),
                    (v) => handleLegendTextStylePropChange('color', v),
                )
            }
            {
                renderColorPicker(
                    'Background color',
                    getLegendPropValue('backgroundColor'),
                    (v) => handleLegendPropChange('backgroundColor', v)
                )
            }
            {
                renderColorPicker(
                    'Border color',
                    getLegendPropValue('borderColor'),
                    (v) => handleLegendPropChange('borderColor', v),
                )
            }
            <Slider
                label={`Border width: ${borderWidth}px`}
                value={borderWidth}
                min={0}
                max={10}
                step={1}
                onChange={(v) => handleDebounceLegendPropChange('borderWidth', v, setBorderWidth)}
            />
        </Box>
    )
}
