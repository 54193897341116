import React, {useCallback, useState} from "react";
import './Frame.css';
import {ElementWrapper} from "../../common/ElementWrapper/ElementWrapper";
import {updateFramePosition, updateFrameSize, updateFrameTitle} from "../../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import {debounce} from "lodash";

export const FrameElement = ({
                                 id,
                                 title,
                                 initialPosition,
                                 initialSize,
                                 backgroundColor,
                                 backgroundImage,
                                 setShowMenu,
                                 multiProps
                             }) => {

    const frameId = `FrameElement-${id}`;
    const dispatch = useDispatch();
    const activeMode = useSelector(state => state.ui.activeMode);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editTitle, setEditTitle] = useState(false);
    const [frameTitle, setFrameTitle] = useState(title || '');
    const debounceFn = useCallback(debounce((value) => {
        dispatch(updateFrameTitle(id, value))
    }, 1000), []);

    const updateFramePos = (position) => {
        dispatch(updateFramePosition(id, position))
    }

    const updateFrameSz = (size) => {
        dispatch(updateFrameSize(id, size))
    }

    const toggleEditMode = (value) => {
        if (value) setShowMenu(true)
        else setShowMenu(false)
        setIsEditMode(value)
        setEditTitle(false)
    }

    return (
        <ElementWrapper
            id={frameId}
            editMode={isEditMode}
            toggleEditMode={toggleEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementPosition={updateFramePos}
            updateElementSize={updateFrameSz}
            type={'Frame'}
            {...multiProps}
        >
            <Box
                sx={{
                    backgroundColor: backgroundColor,
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    borderRadius: '5px'
                }}
            >
                {
                    backgroundImage &&
                    <img
                    src={backgroundImage.url ? backgroundImage.url.replace('https://scoop-image-uploads.s3.us-west-2.amazonaws.com', 'https://d3lkv74tdz6isd.cloudfront.net') + '?withHeaders=yes' :  backgroundImage.replace('https://scoop-image-uploads.s3.us-west-2.amazonaws.com', 'https://d3lkv74tdz6isd.cloudfront.net') + '?withHeaders=yes'}
                        alt={'background'}
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                            pointerEvents: 'none'
                        }}
                        crossOrigin={'anonymous'}
                    />
                }
                {
                    editTitle ?
                        <input
                            id={'frame-title-input'}
                            value={frameTitle}
                            onChange={(e) => {
                                setFrameTitle(e.target.value)
                                debounceFn(e.target.value)
                            }}
                            className={'edit-title-input'}
                            onClick={(e) => e.stopPropagation()}
                        /> :
                        <Typography
                            onDoubleClick={() => {if (activeMode === 'edit') setEditTitle(true)}}
                            className={'inter frame-title'}
                        >
                            {title}
                        </Typography>
                }
            </Box>
        </ElementWrapper>
    )
}
