import {Tooltip} from "@mui/material";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export const InfoTooltip = ({title}) => {
    return (<Tooltip title={title}>
            <span>
                <IconButton disabled >
                    <InfoOutlinedIcon fontSize={'small'}/>
                </IconButton>
            </span>
    </Tooltip>
    );
}