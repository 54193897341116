import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const TextTypography = styled(Typography)({
  color: '#473766',
});

const Sliders = ({
  weightThreshold,
  supportThreshold,
  maxSupportThreshold,
  displayLinksAboveThreshold,
}) => {
  const marks = [
    { value: 25, label: <TextTypography>25%</TextTypography> },
    { value: 50, label: <TextTypography>50%</TextTypography> },
    { value: 75, label: <TextTypography>75%</TextTypography> },
  ];

  return (
    <Stack direction="row" spacing={2} style={{ marginTop: "20px", padding: "0 26px" }}>
      <Box width="100%">
        <Typography gutterBottom sx={{ color: '#473766', textAlign: 'center', fontSize: '0.875rem' }}>
          Percentage Likelihood
        </Typography>

        <Slider
          value={weightThreshold}
          onChange={(event, val) => displayLinksAboveThreshold(val, supportThreshold)}
          aria-labelledby="weight-slider"
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
          sx={{ color: '#473766' }} // This will make the thumb, track and marks white
        />
      </Box>

      <Box width="100%">
        <Typography gutterBottom sx={{ color: '#473766', textAlign: 'center', fontSize: '0.875rem' }}>
          Number of Occurrences
        </Typography>
        <Slider
          value={supportThreshold}
          onChange={(event, val) => displayLinksAboveThreshold(weightThreshold, val)}
          aria-labelledby="support-slider"
          step={1}
          max={maxSupportThreshold}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}`}
          sx={{ color: '#473766' }} // This will make the thumb, track and marks white
        />
      </Box>
    </Stack>
  );
};

export default Sliders;
