// APIConnector.jsx

import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import Dialog from "../../../common/Dialog/Dialog";
import {Typography, Box, Checkbox, Select, Link} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import './APIConnector.css'
import Button from '../../../common/Button/Button'
import Input from "../../../common/Input/Input";
import Selector from "../../../common/Selector/Selector";
import {Server} from "../../../../api/Server";
import Grid from "@mui/material/Unstable_Grid2";
import {DataGrid} from "@mui/x-data-grid";

export const APIConnector = ({
                                 open, onClose, stepBack, selectedInbox, setSelectedInbox, connectorType
                             }) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector(state => state.auth.token);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
    const [isConnected, setConnected] = React.useState(false);
    const [objectList, setObjectList] = React.useState([]);
    const [fieldList, setFieldList] = React.useState(null);
    const [objectGroupList, setObjectGroupList] = React.useState([]);
    const [selectedFieldList, setSelectedFieldList] = React.useState([]);
    const [selectedObject, setSelectedObject] = React.useState(null);
    const [selectedObjectGroup, setSelectedObjectGroup] = React.useState(null);
    const [settingGroup, setSettingGroup] = React.useState(true);
    const [connectionKey, setConnectionKey] = React.useState(null);
    const [apiKey, setAPIKey] = React.useState(null);
    const [reportName, setReportName] = React.useState("");
    const [extractNow, setExtractNow] = React.useState(true);
    const [APIFeatures, setAPIFeatures] = React.useState(null);
    const [filterModalOpen, setFilterModalOpen] = React.useState(false);
    const [filterField, setFilterField] = React.useState(null);
    const [operator, setOperator] = React.useState("Equals");
    const [filterMap, setFilterMap] = React.useState(new Map());
    const [value, setValue] = React.useState(null)
    const [values, setValues] = React.useState([])

    useEffect(() => {
        if (userID && token && workspaceID) setServer(new Server(workspaceID, userID, token));
    }, [userID, token, workspaceID]);

    useEffect(() => {
        if (selectedInbox && selectedInbox.extractDefinition && objectList) {
            setReportName(selectedInbox.label);
            for (let i = 0; i < objectList.length; i++) {
                if (objectList[i].name === selectedInbox.extractDefinition.objectName) {
                    setSelectedObject(objectList[i]);
                    break;
                }
            }
            let newSelectedFields = [];
            for (let i = 0; i < selectedInbox.extractDefinition.selectedFields.length; i++) {
                newSelectedFields.push(selectedInbox.extractDefinition.selectedFields[i].name);
            }
            setSelectedFieldList(newSelectedFields);
            if (selectedInbox.extractDefinition.filters) {
                for (let i = 0; i < selectedInbox.extractDefinition.filters.length; i++) {
                    filterMap.set(selectedInbox.extractDefinition.filters[i].fieldName, {
                        operator: selectedInbox.extractDefinition.filters[i].operator,
                        values: selectedInbox.extractDefinition.filters[i].values
                    });
                }
            } else {
                filterMap.clear();
            }
        }
    }, [selectedInbox]);

    const testConnected = (interval) => {
        if (server?.token) {
            server.postData({
                "action": "getAPIFeatures", "connectionType": connectorType, "workspaceID": workspaceID
            }, (results) => {
                setAPIFeatures(results);
                if (!results.connectionKey) {
                    // No connection found
                    setConnected(false);
                } else {
                    if (results.hasObjectGroup) {
                        getAPIObjectGroupList(results.connectionKey, results);
                    } else {
                        getAPIObjectList(results.connectionKey, results);
                    }
                    clearInterval(interval);
                    setConnected(true);
                }
            })
        }
    }

    const getAPIObjectGroupList = (connectionKey, apiFeatures) => {
        server.postData({
            "action": "getAPIObjectGroupList",
            "connectorType": connectorType,
            "connectionKey": connectionKey,
            "workspaceID": workspaceID
        }, (results) => {
            if (results.objectGroups) {
                setConnected(true);
                let newObjectGroupList = [];
                results.objectGroups.forEach((objectGroup) => {
                    newObjectGroupList.push({id: objectGroup.name, value: objectGroup.label, group: objectGroup});
                });
                setObjectGroupList(newObjectGroupList);
                setConnectionKey(connectionKey);
                if (selectedInbox.extractDefinition) {
                    /*                    for (let i = 0; i < newObjectList.length; i++) {
                                            if (newObjectList[i].name === selectedInbox.extractDefinition.objectName) {
                                                selectedObject = newObjectList[i];
                                                break;
                                            }
                                        }*/
                }
            }
        })
    }

    const getAPIObjectList = (connectionKey, apiFeatures, objectGroup) => {
        var action = {
            "action": "getAPIObjectList",
            "connectorType": connectorType,
            "connectionKey": connectionKey,
            "workspaceID": workspaceID
        };
        if (objectGroup) {
            action.objectGroup = objectGroup;
        }
        server.postData(action, (results) => {
            if (results.objects) {
                setConnected(true);
                let newObjectList = [];
                results.objects.forEach((object) => {
                    newObjectList.push(object);
                });
                setObjectList(newObjectList);
                setConnectionKey(connectionKey);
                let selectedObject = newObjectList[0];
                if (selectedInbox.extractDefinition) {
                    for (let i = 0; i < newObjectList.length; i++) {
                        if (newObjectList[i].name === selectedInbox.extractDefinition.objectName) {
                            selectedObject = newObjectList[i];
                            break;
                        }
                    }
                }
                handleObjectSelect(connectionKey, selectedObject, apiFeatures, objectGroup);
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (open) {
                testConnected(interval);
            }
        }, 3000);
        if (open) {
            testConnected(interval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [open]);

    function handleObjectSelect(connectionKey, object, apiFeatures, objectGroup) {
        setSelectedObject(object);
        let action = {
            "action": "getAPIObjectFieldList",
            "connectorType": connectorType,
            "connectionKey": connectionKey,
            "workspaceID": workspaceID,
            "objectName": object.name
        }
        if (objectGroup) {
            action.objectGroup = objectGroup;
        }
        server.postData(action, (results) => {
            let newFieldList = [];
            let newSelectedFieldList = [];
            if (results.fields) {
                for (let i = 0; i < results.fields.length; i++) {
                    results.fields[i].id = results.fields[i].name;
                    results.fields[i].value = results.fields[i].label;
                    if (apiFeatures && apiFeatures.supportsFilters && results.fields[i].supportsFilters) {
                        results.fields[i].filter = "filter";
                    }
                    newFieldList.push(results.fields[i]);
                    if (results.fields[i].default) {
                        newSelectedFieldList.push(results.fields[i].name);
                    }
                }
                setFieldList(newFieldList);
                if (!selectedInbox || !selectedInbox.extractDefinition) {
                    setSelectedFieldList(newSelectedFieldList);
                }
            }
        });
    }

    function handleSaveAPIKey() {
        server.postData({
            "action": "saveAPIKey", "connectionType": connectorType, "apiKey": apiKey, "workspaceID": workspaceID
        }, (results) => {
            setAPIKey(apiKey);
        });
    }

    function handleSave() {
        let saveFields = [];
        for (let i = 0; i < selectedFieldList.length; i++) {
            for (let j = 0; j < fieldList.length; j++) {
                if (fieldList[j].name === selectedFieldList[i]) {
                    var obj = {
                        name: fieldList[j].name, label: fieldList[j].label, type: fieldList[j].type,
                    }
                    saveFields.push(obj);
                    break;
                }
            }
        }
        let filters = [];
        if (filterMap.size > 0) {
            for (let [key, value] of filterMap) {
                filters.push({fieldName: key, operator: value.operator, values: value.values})
            }
        }
        let extractDefinition = {
            "connectorType": connectorType,
            "objectName": selectedObject.name,
            "objectLabel": selectedObject.label,
            "fields": saveFields,
            "filters": filters,
        }
        if (selectedObjectGroup) {
            extractDefinition.objectGroup = selectedObjectGroup.group;
        }
        let action = {
            "connectionKey": connectionKey,
            "workspaceID": workspaceID,
            "datasetName": reportName,
            "extractDefinition": extractDefinition,
            "isMostRecent": selectedInbox.isMostRecent,
            "isIncremental": selectedInbox.isIncremental,
            "isMultipleLoads": selectedInbox.isMultipleLoads,
            "snapshot": selectedInbox.isSnapshot === "Snapshot",
            "extractNow": extractNow
        };
        if (selectedInbox && selectedInbox.inboxID) {
            action.action = "saveAPIInbox";
            action.inboxID = selectedInbox.inboxID;
        } else {
            action.action = "createAPIInbox";
        }
        server.postData(action, (results) => {
            setSelectedInbox({...selectedInbox})
        });
    }

    function onFilterModalClose() {
        setFilterModalOpen(false);
    }

    function renderFilterContent(name) {
        var filter = filterMap.get(name);
        if (filter) {
            let filterString = "";
            switch (filter.operator) {
                case "Equals":
                    filterString = "=" + filter.values[0];
                    break;
                case "NotEquals":
                    filterString = "!=" + filter.values[0];
                    break;
                case "In":
                    filterString = "In(";
                    for (let i = 0; i < filter.values.length; i++) {
                        if (i > 0) filterString += ",";
                        filterString += filter.values[i];
                    }
                    filterString += ")";
                    break;
                case "NotIn":
                    filterString = "NotIn(";
                    for (let i = 0; i < filter.values.length; i++) {
                        if (i > 0) filterString += ",";
                        filterString += filter.values[i];
                    }
                    filterString += ")";
                    break;
                case "LastDay":
                    filterString = "Last Day";
                    break;
                case "LastWeek":
                    filterString = "Last Week";
                    break;
                case "LastMonth":
                    filterString = "Last Month";
                    break;
                case "LastYear":
                    filterString = "Last Year";
                    break;
            }
            return <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Link sx={{width: 40}}
                      onClick={() => {
                          var newMap = new Map();
                          for (let key in filterMap) {
                              if (key !== name) {
                                  newMap.set(key, filterMap[key]);
                              }
                          }
                          setFilterMap(newMap);
                      }}><Typography>clear</Typography></Link><Typography
                sx={{width: '100%', ml: 1}}>{filterString}</Typography></Box>;
        } else {
            return "";
        }
    }

    let objectName = "Object";
    let hasObjectGroup = false;
    let objectGroupName = "Group";
    if (APIFeatures) {
        objectName = APIFeatures.objectName ? APIFeatures.objectName : objectName;
        if (APIFeatures.hasObjectGroup) {
            hasObjectGroup = true;
            objectGroupName = APIFeatures.objectGroupName ? APIFeatures.objectGroupName : objectGroupName;
        }
    }

    return (<>
            {isConnected && hasObjectGroup && settingGroup && <>
                <Dialog
                    open={open}
                    title={'Dataset from ' + APIFeatures?.connectorType + ' ' + objectGroupName}
                    onClose={onClose}
                    actions={<>
                        <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                    </>}
                    maxWidth={800} sx={{p: 2}}
                >
                    <Box> <Typography sx={{mt: 2}}>Select the {objectGroupName} you would like to retrieve</Typography>
                        <DataGrid autoHeight columns={[{
                            field: "value",
                            headerName: "Value",
                            type: "string",
                            sortable: false,
                            editable: false,
                            width: 350,
                            headerAlign: 'center'
                        }]}
                                  pageSizeOptions={[15]}
                                  initialState={{
                                      pagination: {
                                          paginationModel: {page: 0, pageSize: 15},
                                      },
                                  }}
                                  rows={objectGroupList}
                                  rowHeight={25}
                                  onRowClick={(event) => {
                                      setSelectedObjectGroup(event.row);
                                      getAPIObjectList(connectionKey, APIFeatures, event.row.group);
                                      setSettingGroup(false);
                                  }
                                  }
                                  sx={{
                                      '& .Mui-checked': {
                                          color: '#E50B54',
                                      }, '& .MuiDataGrid-columnHeaders': {
                                          display: 'none',
                                      },
                                  }}
                        /></Box> </Dialog>
            </>}
            {isConnected && (!hasObjectGroup || !settingGroup) && <>
                <Dialog
                    open={open}
                    title={'Dataset from ' + APIFeatures?.connectorType + ' ' + objectName}
                    onClose={onClose}
                    actions={<>
                        <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                        <Button className={'button-purple small'}
                                disabled={reportName.trim().length === 0 || selectedFieldList.length === 0}
                                onClick={() => {
                                    handleSave();
                                    stepBack();
                                    onClose();
                                }}>{selectedInbox && selectedInbox.extractDefinition ? "Save" : "Create"} Data
                            Source</Button>
                    </>}
                    maxWidth={800} sx={{p: 2}}
                >
                    <Input
                        sx={{width: '100%'}}
                        label={'Dataset name'}
                        onChange={(event) => {
                            setReportName(event.target.value)
                        }}
                        value={reportName}
                    />
                    <Box sx={{
                        padding: '12px 16px',
                        fontSize: '14px',
                        background: '#F9F9F9',
                        borderRadius: '5px',
                        color: '#635566'
                    }}>
                        <Selector
                            sx={{width: '100%', height: 35}}
                            label="Choose object type to retrieve"
                            value={selectedObject ? selectedObject.name : null}
                        >{objectList.map((object) => <MenuItem value={object.name} onClick={() => {
                            handleObjectSelect(connectionKey, object, APIFeatures);
                        }}
                        >{object.label}</MenuItem>)}</Selector>
                        {fieldList &&
                            <Box> <Typography sx={{mt: 2}}>Select the fields you would like to retrieve</Typography>
                                <DataGrid autoHeight disableRowSelectionOnClick={true} columns={[{
                                    field: "value",
                                    headerName: "Value",
                                    type: "string",
                                    sortable: false,
                                    editable: false,
                                    width: 350,
                                    headerAlign: 'center'
                                }, {
                                    field: "filter",
                                    headerName: "Filter",
                                    type: "string",
                                    sortable: false,
                                    editable: false,
                                    width: 350,
                                    headerAlign: 'center',
                                    renderCell: (params) => {
                                        if (params.value === "filter") {
                                            if (filterMap.get(params.id)) {
                                                return <Box>{renderFilterContent(params.id)}</Box>
                                            } else {
                                                return <Link onClick={() => {
                                                    for (let i = 0; i < fieldList.length; i++) {
                                                        if (fieldList[i].name === params.id) {
                                                            setFilterField(fieldList[i]);
                                                            setFilterModalOpen(true);
                                                            if (fieldList[i].fieldType === "DateTime") {
                                                                setOperator("LastDay");
                                                            }
                                                            break;
                                                        }
                                                    }
                                                }}
                                                ><Typography>filter</Typography></Link>
                                            }
                                        }
                                    }
                                }]}
                                          pageSizeOptions={[15]}
                                          initialState={{
                                              pagination: {
                                                  paginationModel: {page: 0, pageSize: 15},
                                              },
                                          }}
                                          rows={fieldList}
                                          rowHeight={25}
                                          checkboxSelection={APIFeatures.canSelectFields}
                                          rowSelectionModel={APIFeatures.canSelectFields ? selectedFieldList : []}
                                          onRowSelectionModelChange={(ids) => {
                                              setSelectedFieldList(ids);
                                          }}
                                          sx={{
                                              '& .Mui-checked': {
                                                  color: '#E50B54',
                                              }, '& .MuiDataGrid-columnHeaders': {
                                                  display: 'none',
                                              },
                                          }}
                                /></Box>}
                    </Box>
                    <Grid container spacing={2} sx={{mt: -2, mb: 0, width: '100%'}}>
                        <Grid xs={0.5}><Checkbox sx={{
                            padding: 0, '&.Mui-checked': {color: '#E50B54'}, color: '#E6E4E6'
                        }} checked={extractNow} onChange={() => {
                            setExtractNow(!extractNow)
                        }}/></Grid><Grid xs={11.5}><Typography>Extract
                        data now</Typography></Grid>
                    </Grid>
                </Dialog>
                {filterField && <Dialog open={filterModalOpen} onClose={onFilterModalClose}
                                        actions={<>
                                            <Button className={'button-grey small'} onClick={() => {
                                                setFilterModalOpen(false);
                                                setFilterField(null);
                                                setValues([]);
                                            }}>Cancel</Button>
                                            <Button className={'button-purple small'}
                                                    disabled={(!values || values.length === 0) && filterField.fieldType !== "DateTime" && !value}
                                                    onClick={() => {
                                                        if (operator === "In" || operator === "NotIn") {
                                                            let newValues = [];
                                                            for (let i = 0; i < values.length; i++) {
                                                                newValues.push(values[i].value);
                                                            }
                                                            filterMap.set(filterField.name, {
                                                                operator: operator, values: newValues
                                                            });
                                                        } else {
                                                            filterMap.set(filterField.name, {
                                                                operator: operator, values: [value]
                                                            });
                                                        }
                                                        setFilterModalOpen(false);
                                                        setValues([]);
                                                    }}>Set Filter</Button>
                                        </>}>
                    <Typography>Filter on field {filterField.label}</Typography>
                    <Box sx={{width: 500, height: 50, display: 'flex'}}>
                        <Select sx={{height: 32, mr: 2}} value={operator} onChange={event => {
                            setOperator(event.target.value);
                        }}>
                            {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('Equals') &&
                                <MenuItem value={"Equals"}>Equals</MenuItem>}
                            {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('NotEquals') &&
                                <MenuItem value={"NotEquals"}>Not Equals</MenuItem>}
                            {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('In') &&
                                <MenuItem value={"In"}>In</MenuItem>}
                            {filterField.fieldType !== "DateTime" && APIFeatures.supportedFilterOperators.includes('NotIn') &&
                                <MenuItem value={"NotIn"}>Not In</MenuItem>}
                            {filterField.fieldType === "DateTime" && <MenuItem value={"LastDay"}>Last Day</MenuItem>}
                            {filterField.fieldType === "DateTime" && <MenuItem value={"LastWeek"}>Last Week</MenuItem>}
                            {filterField.fieldType === "DateTime" &&
                                <MenuItem value={"LastMonth"}>Last Month</MenuItem>}
                            {filterField.fieldType === "DateTime" && <MenuItem value={"LastYear"}>Last Year</MenuItem>}
                        </Select>
                        {filterField.fieldType !== "DateTime" && <Input sx={{width: 300}} onChange={(event) => {
                            setValue(event.target.value);
                        }}></Input>}{(operator === "In" || operator === "NotIn") &&
                        <Button className={'button-purple small'} sx={{height: 32, ml: 2}}
                                onClick={(event) => {
                                    let newValues = [];
                                    let id = 0;
                                    for (let i = 0; i < values.length; i++) {
                                        newValues.push({id: i, value: values[i].value});
                                    }
                                    newValues.push({id: values.length, value: value});
                                    setValues(newValues);
                                }}>Add</Button>}
                    </Box>
                    {(operator === "In" || operator === "NotIn") && values && <DataGrid autoHeight columns={[{
                        field: "value",
                        headerName: "Value",
                        type: "string",
                        sortable: false,
                        editable: false,
                        width: '100%',
                        headerAlign: 'center',
                    }]}
                                                                                        initialState={{
                                                                                            pagination: {
                                                                                                paginationModel: {
                                                                                                    page: 0,
                                                                                                    pageSize: 10
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                        rows={values}
                                                                                        rowHeight={25}
                                                                                        onRowSelectionModelChange={(ids) => {
                                                                                        }}
                                                                                        sx={{
                                                                                            '& .MuiDataGrid-columnHeaders': {
                                                                                                display: 'none',
                                                                                            },
                                                                                        }}
                    />}
                </Dialog>}
            </>}
            {!isConnected && APIFeatures && !APIFeatures.apiKey && <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorType + ' ' + objectName}
                onClose={onClose}
                actions={<>
                    <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                </>}
                style={{width: '500px'}}

            >
                <Box sx={{
                    padding: '12px 16px', fontSize: '14px', background: '#F9F9F9', borderRadius: '5px', color: '#635566'
                }}>
                    {APIFeatures && <>
                        <Typography>Click here to connect to {connectorType}</Typography>
                        <a href={APIFeatures.oauthLink}
                           target="_blank">Here</a>
                    </>}
                    {!APIFeatures && <>
                        <Typography>No API Found</Typography>
                    </>}
                </Box>
            </Dialog>}
            {!isConnected && APIFeatures && APIFeatures.apiKey && <Dialog
                open={open}
                title={'Dataset from ' + APIFeatures?.connectorType + ' ' + objectName}
                onClose={onClose}
                actions={<>
                    <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                    <Button className={'button-purple small'} onClick={handleSaveAPIKey}>Save Key</Button>
                </>}
                style={{width: '500px'}}

            >
                <Box sx={{
                    padding: '12px 16px', fontSize: '14px', background: '#F9F9F9', borderRadius: '5px', color: '#635566'
                }}>
                    {APIFeatures && <>
                        <Typography>Enter your API Key to connect to {connectorType}</Typography>
                        <Input sx={{width: 300}} value={apiKey} onChange={(event) => {
                            setAPIKey(event.target.value);
                        }}></Input>                    </>}
                    {!APIFeatures && <>
                        <Typography>No API Found</Typography>
                    </>}
                </Box>
            </Dialog>}
        </>

    );

};


