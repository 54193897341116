import {Box, Typography} from "@mui/material";
import './NewDatasetModal.css'

export const DatasetOptionBox = ({title, description, icon, index, selected, setSelected, tag}) => {
    return (
        <Box className={`dataset-option-box ${selected === title && 'selected'}`} onClick={() => setSelected(title)}
             index={index} sx={{ position: 'relative' }}>
            {tag &&
                <Box sx={{
                    display: 'flex',
                    height: '26px',
                    padding: '4px 6px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    borderRadius: '5px',
                    background: '#F9F9F9',
                    position: 'absolute',
                    top: '6px',
                    right: '6px',
                }}>
                    <Typography sx={{fontSize: '12px'}}>
                        {tag}
                    </Typography>
                </Box>
            }
            <Box sx={{display:'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%'}}>
                <img src={icon} alt={'icon'} style={{}}/>
                {/*{
                    tag &&
                    <Box sx={{
                        display: 'flex',
                        height: '26px',
                        padding: '4px 8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '5px',
                        background: '#F9F9F9',
                        position: 'relative',
                        bottom:'20px',
                        left:'70px',
                    }}>
                        <Typography sx={{fontSize: '12px'}}>
                            {tag}
                        </Typography>
                    </Box>
                }*/}
            </Box>
            <Typography sx={{color: '#2B1630', fontWeight: 600}}>{title}</Typography>
            <Typography sx={{fontSize: '14px', color: '#635566'}}>{description}</Typography>
        </Box>
    )
}