import Dialog from "../../../../common/Dialog/Dialog";
import Button from "../../../../common/Button/Button";
import Stepper from "../../../../common/Stepper/Stepper";
import {Box, checkboxClasses, Divider, Tab, Tabs, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Switch} from "../../../../common/Switch/Switch";
import Info from "../../../../../assets/icons/Info.svg";
import Input from "../../../../common/Input/Input";
import Selector from "../../../../common/Selector/Selector";
import Checkbox from '../../../../common/Checkbox/Checkbox';
import React, {useState} from "react";
import { styled } from '@mui/material/styles';
import MenuItem from "@mui/material/MenuItem";

const primaryColor = '#201024';
const secondaryColor = '#635566';

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-root': {
        backgroundColor: '#F2F2F2',
        color: '#979099'
    },
    '& .MuiTabs-flexContainer': {
        gap: '4px',
    },
    '& .MuiTabs-scroller': {
        backgroundColor: '#F2F2F2',
        borderRadius: '5px',
        padding: '4px 6px',
        maxHeight: '42px',
    },
    '& .MuiButtonBase-root': {
        width: '177px',
        minHeight: '34px',
        padding: '8px 16px',
        color: '#979099',
        borderRadius: '3px',
    },
    '& .MuiButtonBase-root.Mui-selected': {
        width: '177px',
        minHeight: '34px',
        padding: '8px 16px',
        color: '#979099',
        backgroundColor: '#FFF',
        borderRadius: '3px',
    },
    '& .MuiTabs-indicator': {
       display: 'none',
    },
});

const frequencies = ['Automatic', 'Custom']
const tabs = ['Daily', 'Weekly', 'Monthly']
const possibleTimes = [
    '01:00 AM', '01:30 AM', '02:00 AM', '02:30 AM', '03:00 AM', '03:30 AM',
    '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', '06:30 AM',
    '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', '09:00 AM', '09:30 AM',
    '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM',
    '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM',
    '04:00 PM', '04:30 PM', '05:00 PM', '05:30 PM', '06:00 PM', '06:30 PM',
    '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', '09:30 PM',
    '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM', '12:00 AM', '12:30 AM'
]
const possibleDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']


const SettingsModal = ({
                           onClose,
                           setStep,
                           steps,
                           activeStep,
                           goBack,
                           inboxName,
                           isBlending,
                           save,
                            inboxID,
                           serverContext,

}) => {

    const [tab, setTab] = useState(0);
    const [queryOneCheck, setQueryOneCheck] = useState(false)
    const [queryTwoCheck, setQueryTwoCheck] = useState(false)

    const [currentInboxName, setCurrentInboxName] = useState(inboxName)
    const [inboxDescription, setInboxDescription] = useState('')

    const [frequencySelected, setFrequencySelected] = useState(frequencies[0])
    const [timeSelected, setTimeSelected] = useState(possibleTimes[0])
    const [daySelected, setDaySelected] = useState(possibleDays[0])

    const sheetRange = {
        "sheetType": "CalculatedSource",
        "inboxID": inboxID,
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const renderFrequency = () => {
        switch (tab) {
            case 0:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#201024' }}>Time</Typography>
                        <Selector
                            onChange={(event) => setTimeSelected(event.target.value)}
                            value={timeSelected}
                            style={{height: '38px'}}
                        >
                            {possibleTimes.map((time, index) => (
                                <MenuItem key={index} value={time}>
                                    {time}
                                </MenuItem>
                            ))}
                        </Selector>
                    </Box>
                )
            case 1:
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 600, color: '#201024' }}>Day & Time</Typography>
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                            <Selector
                                onChange={(event) => setDaySelected(event.target.value)}
                                value={daySelected}
                                style={{height: '38px'}}
                            >
                                {possibleDays.map((day, index) => (
                                    <MenuItem key={index} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Selector>
                            <Selector
                                onChange={(event) => setTimeSelected(event.target.value)}
                                value={timeSelected}
                                style={{height: '38px'}}
                            >
                                {possibleTimes.map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Selector>
                        </Box>
                    </Box>
                )
            case 2:
                return <></>
        }
    }

    const handleSave = () => {
/*        const row = document.getElementById('queryColumn1Header')
        let cell1 = serverContext.serverData.cells[row][1];
        let cell2 = serverContext.serverData.cells[row][3];
        cell1.f = cell1.sheetObject.addresses[0] + "=" + cell2.sheetObject.addresses[0];
        serverContext.updateCell(sheetRange, 11, 1, cell1, ()=>{}, null, serverContext);*/
        save()
        onClose()
    }

    return (
        <Dialog
            style={{ width: '600px' }}
            maxWidth={false}
            open
            onClose={() => {
                onClose()
                setStep(0)
            }}
            actions={
                <>
                    <Button className={'button-grey small'} onClick={goBack}>
                        Back
                    </Button>
                    <Button className={'button-purple small'} onClick={handleSave}>
                        Create Dataset
                    </Button>
                </>
            }
            title={ <Stepper width={'90%'} activeStep={activeStep} steps={steps} /> }
        >
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingTop: '16px' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '600', color: primaryColor }}>
                    Settings
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Input
                        onChange={(e) => setCurrentInboxName(e.target.value)}
                        value={currentInboxName}
                        label={'Name'}
                        size={'small'}
                    />
                    <Input
                        onChange={(e) => setInboxDescription(e.target.value)}
                        value={inboxDescription}
                        label={'Description'}
                        size={'small'}
                        multiline={true}
                        rows={5}
                        padding
                    />
                   {/* <Selector
                        value={frequencySelected}
                        onChange={(event) => setFrequencySelected(event.target.value)}
                        label={
                            <Box sx={{ display: 'flex', gap: '6px' }}>
                                <Typography sx={{ fontSize: '12px', fontWeight: '600', alignSelf: 'center', gap: '4px' }}>
                                    Frequency
                                </Typography>
                                <Tooltip title={'Frequency is the time period for which the data is aggregated.'}>
                                    <img src={Info} alt={'info'} />
                                </Tooltip>
                            </Box>
                        }
                        size={'small'}
                    >
                        {frequencies.map((value, index) => (
                            <MenuItem key={index} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Selector>*/}
                    {isBlending &&
                        <>
                            <Box sx={{display: 'flex', gap: '9px'}}>
                                <Checkbox
                                    checked={queryOneCheck}
                                    onClick={() => setQueryOneCheck(!queryOneCheck)}
                                />
                                <Box>
                                    <Typography sx={{fontSize: '14px', fontWeight: '600', color: primaryColor}}>
                                        Queried dataset 1
                                    </Typography>
                                    <Typography sx={{fontSize: '14px', color: secondaryColor}}>
                                        Reprocess whenever dataset is updated
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '9px' }}>
                                <Checkbox
                                    checked={queryTwoCheck}
                                    onClick={() => setQueryTwoCheck(!queryTwoCheck)}
                                />
                                <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight:'600', color: primaryColor }}>
                                        Queried dataset 2
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px', color: secondaryColor }}>
                                        Reprocess whenever dataset is updated
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    }
                    {/*<Box>
                        <StyledTabs value={tab} onChange={handleChange} aria-label="basic tabs example">
                            {tabs.map((tab, index) => (
                                <Tab key={index} label={tab} />
                            ))}
                        </StyledTabs>
                        {frequencySelected === 'Custom' && renderFrequency()}
                    </Box>*/}
                </Box>
            </Box>
        </Dialog>
    )

}

export default SettingsModal;