import React, { useEffect, useState } from "react";
import Dialog from '../../common/Dialog/Dialog';
import Selector from "../../common/Selector/Selector";
import Button from "../../common/Button/Button";
import { Box } from "@mui/material";
import "./ProcessSelectionModal.css";

const ProcessSelectionModal = ({
    isOpen,
    closeModal,
    fetchNewDiagram,
    preliminaryData,
    reportSeriesData,
    setSelectedFilter,
    selectedReportSeriesTable,
    setSelectedReportSeriesTable,
    setDropdownFilter,
    setSelectedSuccess,
    setSelectedColumn,
    selectedColumn,
    selectedSuccess
}) => {

    const [dropdownReportItems, setDropdownReportItems] = useState([]);
    const [dropdownColumns, setDropdownColumns] = useState([]);
    const [dropdownSuccess, setDropdownSuccess] = useState([]);

    useEffect(() => {
        if (preliminaryData && reportSeriesData && isOpen) {
            const reportDropdownValues = preliminaryData.map(d => {
                const found = reportSeriesData.find(r => r.reportSeriesID === d.reportSeriesTableID);
                return found ? { label: found.inboxLabel, value: d.reportSeriesTableID.toString() } : null;
            }).filter(Boolean);
            setDropdownReportItems(reportDropdownValues);
            // Only set the default report item if one has not already been selected
            if (!selectedReportSeriesTable && reportDropdownValues.length > 0) {
                setSelectedReportSeriesTable(reportDropdownValues[0].value);
            }
            if (selectedReportSeriesTable) {
                const report = preliminaryData.find(d => d.reportSeriesTableID.toString() === selectedReportSeriesTable);
                if (report) {
                    const columnOptions = report.changeColumns.map(c => ({ label: c.columnName, value: c.columnName }));
                    setSelectedColumn(columnOptions[0].value);
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (selectedReportSeriesTable && preliminaryData) {
            const report = preliminaryData.find(d => d.reportSeriesTableID.toString() === selectedReportSeriesTable);
            if (report) {
                const columnOptions = report.changeColumns.map(c => ({ label: c.columnName, value: c.columnName }));
                setDropdownColumns(columnOptions);

                // Only set the default column if one has not already been selected
                if (!selectedColumn && columnOptions.length > 0) {
                    setSelectedColumn(() => columnOptions[0].value);
                }
            }
        }
    }, [selectedReportSeriesTable, preliminaryData, selectedColumn]);

    useEffect(() => {
        if (selectedColumn && selectedReportSeriesTable && preliminaryData) {
            const report = preliminaryData.find(d => d.reportSeriesTableID.toString() === selectedReportSeriesTable);
            const column = report?.changeColumns.find(c => c.columnName === selectedColumn);
            if (column) {
                const successOptions = column.values.map(v => ({ label: `${v}`, value: `${v}` }));
                setDropdownSuccess(successOptions);
                setSelectedSuccess(successOptions[0]?.value);
            }
        }
    }, [selectedColumn, selectedReportSeriesTable, preliminaryData]);

    // Handle selections
    const reportSelected = (event) => {
        setSelectedReportSeriesTable(event.target.value);
    };

    const columnSelected = (event) => {
        setSelectedColumn(event.target.value);
    };

    const successSelected = (event) => {
        setSelectedSuccess(event.target.value);
    };

    // Actions for the modal
    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
            <Button onClick={closeModal} className='primary-button' text='Cancel' />
            <Button onClick={() => fetchNewDiagram()} className='primary-button button-purple' text='Generate Diagram'/>
        </Box>
    );

    return (
        <Dialog onClose={closeModal} open={isOpen} title="Select a Dataset to Analyze" actions={actions} fullWidth>
            <Selector value={selectedReportSeriesTable || ''} onChange={reportSelected} label='Select a Dataset' native>
                {dropdownReportItems.map(report => (
                    <option key={report.value} value={report.value}>
                        {report.label}
                    </option>
                ))}
            </Selector>

            <Selector value={selectedColumn || ''} onChange={columnSelected} label='Select an Attribute' native>
                {dropdownColumns.map(column => (
                    <option key={column.value} value={column.value}>
                        {column.label}
                    </option>
                ))}
            </Selector>

            <Selector value={selectedSuccess || ''} onChange={successSelected} label='Select a value that indicates a "successful" outcome' native>
                {dropdownSuccess.map(success => (
                    <option key={success.value} value={success.value}>
                        {success.label}
                    </option>
                ))}
            </Selector>
        </Dialog>
    );
};

export default ProcessSelectionModal;
