import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "./Dialog";
import React from "react";
import {Box} from "@mui/material";
import Button from "../Button/Button";
import PropTypes from "prop-types";

const SaveChangesDialog =({open,onClose,handleDontSave,handleSave,...props})=>{
const actions=(
    <Box display="flex" justifyContent="flex-end" width="100% " gap= "8px">
        <Button
            onClick={handleDontSave}
            text={"Don't Save"}
            className={'primary-button'}
        >
        </Button>
        <Button onClick={handleSave}
                className={'primary-button button-purple'}
                text={'Save'}
        >
        </Button>
    </Box>
)
    return(
        <Dialog
            open={open}
            onClose={onClose}
            title={'Save Changes'}
            actions={actions}
            {...props}
        >
            <DialogContentText>
                Do you want to save the changes to this chart?
            </DialogContentText>

        </Dialog>
    )
}
SaveChangesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleDontSave: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};
export default SaveChangesDialog;