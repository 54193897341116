import ChartBar from '../../assets/icons/ChartBar.svg';
import TreeStructure from '../../assets/icons/TreeStructure.svg';
import Shapes from '../../assets/icons/Shapes.svg';
import SlidersHorizontal from '../../assets/icons/SlidersHorizontal.svg';
import Table from '../../assets/icons/Table.svg';
import SelectionAll from '../../assets/icons/SelectionAll.svg';

export const mainToolbarItems = [
    {
        text: 'Chart',
        icon: ChartBar,
        label: 'Chart/Table'
    },
    {
        text: 'Sheetlet',
        icon: Table,
        label: 'Sheetlet'
    },
    {
        text: 'Process Diagram',
        icon: TreeStructure,
        label: 'Process Diagram'
    },
    {
        text: 'Prompt',
        icon: SlidersHorizontal,
        label: 'Prompt'
    },
    {
        text: 'Frame',
        icon: SelectionAll,
        label: 'Frame'
    },
    {
        text: 'Objects',
        icon: Shapes,
        label: 'Objects'
    }
]
