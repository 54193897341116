// Jira.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../api/api';
import { useSelector } from "react-redux";
import Dialog from "../../../common/Dialog/Dialog";
import { Step, Stepper, StepLabel, Typography, StepContent, Box, Grid } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import './Jira.css'
import Button from '../../../common/Button/Button'

import datasourceDefs from '../../../../api/datasourceConfig'
import Input from "../../../common/Input/Input";
import Selector from "../../../common/Selector/Selector";

const bot = datasourceDefs.find(b => b.name === "Jira");

export const Jira = ({ onClose, stepBack, type, setAlert, basicConfiguration }) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const navigate = useNavigate();
    const { postData } = useApi(bot.API_URL);
    // TO-DO  delete hardcoded URL!!!!
    // https://scoopanalytics.atlassian.net/jira/software/projects/SBP/issues/?jql=project%20%3D%20%22SBP%22%20ORDER%20BY%20created%20DESC
    const [url, setUrl] = useState('https://xxx.atlassian.net/jira/...');
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [reports, setReports] = useState([]);
    const [isReportTransactional, setIsReportTransactional] = useState(false);
    const [allowMutipleLoads, setAllowMutipleLoads] = useState(false);
    const [reportName, setReportName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [nextStep, setNextStep] = useState(false)
    const [isConnetionTested, setIsConnetionTested] = useState(false)
    const [botUID, setBotUID] = useState('')
    const [botPWD, setBotPWD] = useState('')


    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        // Validate URL
        setIsUrlValid(bot.urlValidation.test(newUrl));
    };


    const runBot = async () => {
        const action = {
            "action": "testRobot",
            "service": bot.service,
            "reportURL": url,
            "botUID": botUID,
            "botPWD": botPWD,
            "label": "Jira TEST",
            "inboxName": "Jira_TEST",
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        const result = await postData(action);
        return result;
    };


    const handleRunBot = async () => {
        setIsLoading(true);
        try {
            const data = await runBot();
            if (data?.status === 'error') {
                setAlert({ message: 'Failed to fetch data from Jira. Please check the credentials you provided.', severity: 'error' })
            } else {
                setAlert({ message: 'Jira connector is connected successfully', severity: 'success' })
                setIsConnetionTested(true)
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    function createInboxName(userID, reportName) {
        // Combine userID and reportName with an underscore
        let combinedName = `${userID}_${reportName.substring(0, 30)}`;

        // Replace characters not in the allowed list with an underscore
        // Allowed characters: alphanumeric, plus (+), minus (-), equals (=), dot (.), comma (,), underscore (_), colon (:), at (@)
        let scrubbedName = combinedName.replace(/[^a-zA-Z0-9\+\-\=\.\,\_\:\@]/g, '_');

        return scrubbedName;
    }


    const createBot = async () => {
        const action = {
            "action": "createRobot",
            "inboxName": createInboxName(userID, reportName),
            "keepOnlyCurrent": basicConfiguration.isMostRecent,
            "incremental": basicConfiguration.isIncremental,
            "enableMultipleSameDayLoads": basicConfiguration.isMultipleLoads,
            "label": reportName,
            "workspaceID": workspaceID,
            "reportURL": url,
            "isTransactional": type === 'Transactional',
            "userName": userID,
            "service": bot.service,
            "botUID": botUID,
            "botPWD": botPWD,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        const result = await postData(action);
        return result;
    };

    const handleCreateBot = () => {
        createBot().then((results) => {
            console.log(results)
            if (results?.status === 'success')
                setAlert({ message: 'Jira app connector created successfully', severity: 'success' })
            else if (results?.status === 'error')
                setAlert({ message: results?.message ?? 'Failed to create Jira app connector', severity: 'error' })
            setNextStep(false)
            stepBack()
            onClose()

        })
    }

    return (
        <>
            {!nextStep ?
                <Dialog
                    open
                    title={'New Report from Jira'}
                    icon={bot.icon}
                    onClose={onClose}
                    maxWidth="lg"
                    actions={
                        <>
                            <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                            {url && botUID && botPWD &&
                                <Button
                                    className={'button-purple small'}
                                    onClick={() => setNextStep(true)}
                                    disabled={!isConnetionTested}   
                                > I've done this </Button>
                            }
                        </>
                    }>
                    <DialogContent style={{ width: '800px' }}>
                        <Stepper orientation="vertical">
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',
                                    },
                                }}>
                                    {'Enter the User Name and Password for the Jira Account you want to connect to'}
                                </StepLabel>
                                <StepContent>
                                    {/* Layout the inputs side by side using Grid */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Input
                                                label="User Name"
                                                fullWidth
                                                variant="outlined"
                                                value={botUID}
                                                onChange={(event) => setBotUID(event.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                label="User Password"
                                                type="password" // Hide password input
                                                fullWidth
                                                variant="outlined"
                                                value={botPWD}
                                                onChange={(event) => setBotPWD(event.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',
                                    },
                                }}>
                                    Paste the entire report URL below
                                </StepLabel>
                                <StepContent>
                                    <Input
                                        label="Jira Reports URL"
                                        fullWidth
                                        variant="outlined"
                                        value={url}
                                        onChange={handleUrlChange}
                                        error={!isUrlValid}
                                        helperText={!isUrlValid ? "Invalid Jira Reports URL" : ""}
                                    />
                                </StepContent>
                            </Step>
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',

                                    },
                                }}>
                                    Run Connectivity Test
                                </StepLabel>
                                <StepContent>
                                    {url && botUID && botPWD &&
                                        <Button
                                            className={'button-purple small'}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleRunBot}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Run a Test
                                        </Button>
                                    }
                                </StepContent>
                            </Step>

                            {/* Additional steps can be added similarly */}

                            {isLoading &&
                                <LinearProgress variant="indeterminate" style={{ marginTop: '20px', height: '10px' }} />
                            }
                        </Stepper>
                    </DialogContent>
                </Dialog>
                :
                <Dialog open
                    title={'New Report from Jira'}
                    icon={bot.icon}
                    onClose={onClose}
                    actions={
                        <>
                            <Button className={'button-grey small'} onClick={() => setNextStep(false)}>Back</Button>
                            <Button className={'button-purple small'} onClick={() => handleCreateBot()}> Create
                                Dataset </Button>
                        </>
                    }
                    style={{ width: '500px' }}

                >
                    <Box sx={{ padding: '12px 16px', fontSize: '14px', background: '#F9F9F9', borderRadius: '5px', color: '#635566' }} >
                        <Typography>Data will be Scooped from <b>{reportName}</b></Typography>
                    </Box>
                    <Input
                        sx={{ width: '100%' }}
                        label={'Dataset name'}
                        onChange={(event) => {
                            setReportName(event.target.value)
                        }}
                        value={reportName}
                    />

                </Dialog>
            }
        </>

    );

};


