// Hubspot.jsx

import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import Dialog from "../../../common/Dialog/Dialog";
import {Typography, Box, Checkbox, Select, Link, Grid} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import './APIConnector.css'
import Button from '../../../common/Button/Button'
import Selector from "../../../common/Selector/Selector";
import {Server} from "../../../../api/Server";
import {DataGrid} from "@mui/x-data-grid";

export const APIWriteBack = ({
                                 open, stepBack, onClose, selectedInbox, setSelectedInbox, metadata
                             }) => {
        const userID = useSelector((state) => state.auth.userID);
        const workspaceID = useSelector((state) => state.auth.workspaceID);
        const token = useSelector(state => state.auth.token);
        const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
        const [objectList, setObjectList] = React.useState([]);
        const [fieldList, setFieldList] = React.useState(null);
        const [selectedObject, setSelectedObject] = React.useState(null);
        const [apiConnection, setApiConnection] = React.useState(null);
        const [inboxColumns, setInboxColumns] = React.useState([]);
        const [sourceTable, setSourceTable] = React.useState(null);
        const [recordIDColumn, setRecordIDColumn] = React.useState(null);
        const [fieldMap, setFieldMap] = React.useState(new Map());

        useEffect(() => {
            if (userID && token && workspaceID) setServer(new Server(workspaceID, userID, token));
        }, [userID, token, workspaceID]);

        useEffect(() => {
            if (selectedInbox && selectedInbox.writebackDefinition && metadata && metadata.apiConnections) {
                for (let i = 0; i < metadata.apiConnections.length; i++) {
                    if (metadata.apiConnections[i].connectionKey === selectedInbox.writebackDefinition.connectionKey) {
                        setApiConnection(metadata.apiConnections[i]);
                        getAPIObjectList(metadata.apiConnections[i]);
                        break;
                    }
                }
            }
        }, [selectedInbox])

        const getAPIObjectList = (apiConnection) => {
            server.postData({
                "action": "getAPIObjectList",
                "connectorType": apiConnection.connectionType,
                "connectionKey": apiConnection.connectionKey,
                "workspaceID": workspaceID
            }, (results) => {
                if (results.objects) {
                    let newObjectList = [];
                    results.objects.forEach((object) => {
                        if (object.supportsWriteback) {
                            newObjectList.push(object);
                            if (selectedInbox && selectedInbox.writebackDefinition && selectedInbox.writebackDefinition.writebackObject === object.name) {
                                handleObjectSelect(apiConnection, object);
                            }
                        }
                    });
                    setObjectList(newObjectList);
                }
            })
        }

        function handleObjectSelect(apiConnection, object) {
            setSelectedObject(object);
            server.postData({
                "action": "getAPIObjectFieldList",
                "connectorType": apiConnection.connectionType,
                "connectionKey": apiConnection.connectionKey,
                "workspaceID": workspaceID,
                "objectName": object.name
            }, (results) => {
                let newFieldList = [];
                for (let i = 0; i < results.fields.length; i++) {
                    if (!results.fields[i].supportsWriteback) continue;
                    results.fields[i].id = results.fields[i].name;
                    results.fields[i].value = results.fields[i].label;
                    newFieldList.push(results.fields[i]);
                }
                setFieldList(newFieldList);
                if (selectedInbox.writebackDefinition && selectedInbox.writebackDefinition.sourceTable) {
                    for (let i = 0; i < selectedInbox.tables.length; i++) {
                        if (selectedInbox.tables[i].name === selectedInbox.writebackDefinition.sourceTable.name) {
                            handleSelectTable(selectedInbox.tables[i]);
                            break;
                        }
                    }
                }
            });
        }

        function handleSelectTable(selectedTable) {
            let newInboxColumns = [""];
            for (let i = 0; i < selectedInbox.tables.length; i++) {
                if (selectedInbox.tables[i].tableName !== selectedTable.tableName)
                    continue;
                for (let j = 0; j < selectedInbox.tables[i].columns.length; j++) {
                    newInboxColumns.push(selectedInbox.tables[i].columns[j].columnName)
                }
            }
            setSourceTable(selectedTable);
            setInboxColumns(newInboxColumns);
            if (selectedInbox.writebackDefinition) {
                if (selectedInbox.writebackDefinition.recordIDColumn) {
                    setRecordIDColumn(selectedInbox.writebackDefinition.recordIDColumn);
                }
                let newFieldMap = new Map();
                for (let i = 0; i < selectedInbox.writebackDefinition.fieldMappings.length; i++) {
                    newFieldMap.set(selectedInbox.writebackDefinition.fieldMappings[i].objectField,
                        selectedInbox.writebackDefinition.fieldMappings[i].mappedColumnName);
                }
                setFieldMap(newFieldMap);
            }
        }


        function handleSave() {
            let writebackDefinition = {
                "connectionKey": apiConnection.connectionKey,
                "writebackObject": selectedObject.name,
                "sourceTable": sourceTable.tableName,
                "recordIDColumn": recordIDColumn,
                "fieldMappings": []
            }
            fieldMap.forEach((inboxColumn, fieldName) => {
                writebackDefinition.fieldMappings.push({
                    "objectField": fieldName,
                    "mappedColumnName": inboxColumn
                });
            })
            let action = {
                "action": "saveWritebackDefinition",
                "inboxID": selectedInbox.inboxID,
                "writebackDefinition": writebackDefinition
            };
            server.postData(action, (results) => {
            });
            setSelectedObject(null);
            setFieldList(null);
            setSourceTable(null);
            setRecordIDColumn(null);
            selectedInbox.writebackDefinition = writebackDefinition;
            setSelectedInbox({...selectedInbox});
            fieldMap.clear();
        }

        return (<>
            <Dialog
                open={open}
                title={'Write Back to API Object'}
                onClose={onClose}
                actions={<>
                    <Button className={'button-grey small'} onClick={() => {
                        setSelectedObject(null);
                        setFieldList(null);
                        setSourceTable(null);
                        setRecordIDColumn(null);
                        stepBack();
                    }}>Back</Button>
                    <Button className={'button-purple small'}
                            onClick={() => {
                                handleSave();
                                stepBack();
                                onClose();
                            }}>{selectedInbox && selectedInbox.writebackDefinition ? "Save" : "Create"} Writeback</Button>
                </>}
                maxWidth={800} sx={{p: 2}}
            >
                {metadata && metadata.apiConnections && <Selector
                    sx={{width: '100%', height: 35}}
                    label="Choose API connection to use for writeback"
                    value={apiConnection ? apiConnection.connectionKey : null}
                >{metadata.apiConnections.map((connection) => <MenuItem key={connection.connectionKey}
                                                                        value={connection.connectionKey}
                                                                        onClick={() => {
                                                                            setApiConnection(connection);
                                                                            getAPIObjectList(connection);
                                                                        }}
                >{connection.connectionType}</MenuItem>)}</Selector>}
                {apiConnection && objectList && <Selector
                    sx={{width: '100%', height: 35}}
                    label="Choose the target object for writeback"
                    value={selectedObject ? selectedObject.name : null}
                >{objectList.map((object) => <MenuItem key={object.name} value={object.name}
                                                       onClick={() => {
                                                           handleObjectSelect(apiConnection, object);
                                                       }}
                >{object.label}</MenuItem>)}</Selector>}
                {selectedObject && <Box>
                    <Grid container>
                        <Grid xs={6}>
                            <Selector
                                sx={{width: '100%', height: 35}}
                                label="Choose the Scoop table to use for mapping"
                                value={sourceTable ? sourceTable.tableName : null}
                            >{selectedInbox.tables.map((table) => <MenuItem key={table.tableName}
                                                                            value={table.tableName}
                                                                            onClick={() => {
                                                                                handleSelectTable(table);
                                                                            }}
                            >{table.tableName}</MenuItem>)}</Selector>
                        </Grid>
                        <Grid xs={6}>
                            <Selector
                                sx={{width: '100%', height: 35}}
                                label="Choose the Scoop column to use as ID"
                                value={recordIDColumn ? recordIDColumn : null}
                            >{inboxColumns.map((inboxColumn) => <MenuItem key={inboxColumn} value={inboxColumn}
                                                                          onClick={() => {
                                                                              setRecordIDColumn(inboxColumn);
                                                                          }}
                            >{inboxColumn}</MenuItem>)}</Selector>
                        </Grid>
                    </Grid>
                    <Typography sx={{mt: 2}}>Choose Scoop columns to map</Typography>
                    {fieldList && <DataGrid autoHeight disableRowSelectionOnClick={true} columns={[{
                        field: "label",
                        headerName: "Object Field",
                        type: "string",
                        sortable: false,
                        editable: false,
                        width: 350,
                        headerAlign: 'center'
                    }, {
                        field: "scoopColumn",
                        headerName: "Scoop Column to Map",
                        type: "string",
                        sortable: false,
                        editable: false,
                        width: 350,
                        headerAlign: 'center',
                        renderCell: (params) => {
                            return <Selector value={fieldMap.get(params.row.name) ? fieldMap.get(params.row.name) : ''}>
                                {inboxColumns.map((inboxColumn) => <MenuItem key={inboxColumn} sx={{height: 20}}
                                                                             onClick={() => {
                                                                                 if (inboxColumn === '') {
                                                                                     fieldMap.delete(params.row.name);
                                                                                 } else {
                                                                                     fieldMap.set(params.row.name, inboxColumn);
                                                                                 }
                                                                                 setFieldMap(new Map(fieldMap));
                                                                             }}
                                                                             value={inboxColumn}>{inboxColumn}</MenuItem>)}
                            </Selector>
                        }
                    }]}
                                            pageSizeOptions={[10]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {page: 0, pageSize: 10},
                                                },
                                            }}
                                            rows={fieldList}
                                            rowHeight={25}
                                            onRowSelectionModelChange={(ids) => {
                                            }}
                    />} </Box>}
                <span></span>
            </Dialog>
        </>);

    }
;


