// ImageElement.js
import React, { useEffect, useState, useRef } from 'react';
import ImagePlaceholder from './image_placeholder.png'; // Import the PNG file
import { useDispatch, useSelector } from 'react-redux';
import { updateImagePosition, updateImageSize } from '../../../store/actions';
import ImageEdit from './ImageEdit';
import { useApi } from '../../../api/api';
import { ElementWrapper } from "../../common/ElementWrapper/ElementWrapper";


const ImageElement = ({
    id,
    initialPosition,
    initialSize,
    content,
    multiProps
}) => {

    const ariaLabel = { 'aria-label': 'description' };
    const { postData } = useApi();
    const dispatch = useDispatch();
    const activeMode = useSelector(state => state.ui.activeMode)
    const [isImageASkeleton, setIsImageASkeleton] = React.useState(true); //when there is no image data to display
    const [isEditMode, setIsEditMode] = useState(false); // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [editImageOpen, setEditImageOpen] = useState(false);
    const [wrapperSize, setWrapperSize] = useState(initialSize)
    const [imageBase64, setImageBase64] = useState('');
    const userState = useSelector((state) => state.auth);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';

    useEffect(() => {
        if (content?.imageURL) {
            setIsImageASkeleton(false);
            setIsImageASkeleton(false);
            fetchImageAsBase64(content.imageURL);
        }
    }, [content?.imageURL])

    const fetchImageAsBase64 = (url) => {
        const action = {
            "action": "returnBase64Image",
            "S3Key": url,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        fetch(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userState.token}`
            },
            body: JSON.stringify(action),
        })
            .then(response => {
                if (response.ok) {
                    return response.json(); // Parse the response as JSON
                }
                throw new Error('Network response was not ok.'); // Handle non-200 responses
            })
            .then(data => {
                const { base64Image } = data; // Extract the base64Image property from the parsed JSON
                setIsImageASkeleton(false);
                setImageBase64(base64Image); // No need to prepend `url()` here, assuming base64Image is already formatted correctly
            })
            .catch(error => console.error('Error fetching and converting image:', error));
    };


    const updateImagePos = (position) => {
        dispatch(updateImagePosition(id, position))
    }

    const updateImageSz = (size) => {
        dispatch(updateImageSize(id, size))
    }

    return (
        <ElementWrapper
            id={`ImageElement-${id}`}
            key={`ImageElement-${id}`}
            type={'Image'}
            setWrapperSizeTrack={setWrapperSize}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementSize={updateImageSz}
            updateElementPosition={updateImagePos}
            {...multiProps}
            objectId={id}
        >
            {isEditMode && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <ImageEdit
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        id={id}
                    />
                </div>
            )}

            {isImageASkeleton && (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        backgroundImage: `url(${ImagePlaceholder})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    }}
                />
            )}
            {
                !isImageASkeleton &&
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        backgroundImage: `url(${imageBase64})`, // Use the base64 image data,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                    }}
                />

            }
        </ElementWrapper >
    );
};

export default ImageElement;
