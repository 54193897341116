// VideoElement.js
import React, { useEffect, useState, useRef } from 'react';
import VideoPlaceholder from '../../../assets/icons/FilmStrip.svg'; // Import the PNG file
import { useDispatch, useSelector } from 'react-redux';
import { updateVideoPosition, updateVideoSize } from '../../../store/actions';
import VideoEdit from './VideoEdit';
import { useApi } from '../../../api/api';
import { ElementWrapper } from "../../common/ElementWrapper/ElementWrapper";


const VideoElement = ({
    id,
    initialPosition,
    initialSize,
    content,
    multiProps
}) => {

    const ariaLabel = { 'aria-label': 'description' };
    const { postData } = useApi();
    const dispatch = useDispatch();
    const activeMode = useSelector(state => state.ui.activeMode)
    const [isVideoASkeleton, setIsVideoASkeleton] = React.useState(true); //when there is no video data to display
    const [isEditMode, setIsEditMode] = useState(false); // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [editVideoOpen, setEditVideoOpen] = useState(false);
    const [wrapperSize, setWrapperSize] = useState(initialSize)

    const userState = useSelector((state) => state.auth);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';

    useEffect(() => {

        if (content?.videoURL) {
            setIsVideoASkeleton(false);
        }

    }, [content?.videoURL])


    const updateVideoPos = (position) => {
        dispatch(updateVideoPosition(id, position))
    }

    const updateVideoSz = (size) => {
        dispatch(updateVideoSize(id, size))
    }

    return (
        <ElementWrapper
            id={`VideoElement-${id}`}
            key={`VideoElement-${id}`}
            type={'Video'}
            setWrapperSizeTrack={setWrapperSize}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialPosition={initialPosition}
            initialSize={initialSize}
            updateElementSize={updateVideoSz}
            updateElementPosition={updateVideoPos}
            {...multiProps}
            objectId={id}
        >
            {isEditMode && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <VideoEdit
                        isMenuOpen={isMenuOpen}
                        setIsMenuOpen={setIsMenuOpen}
                        id={id}
                    />
                </div>
            )}

            {isVideoASkeleton && (
                <div
                    style={{
                        height: '60%',
                        width: '60%',
                        backgroundImage: `url(${VideoPlaceholder})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative',
                        top: '20%',
                        left: '20%',
                    }}
                />
            )}
            {
                // add crosshatching when in edit mode so the user can edit the video without playing it
                !isVideoASkeleton &&
                <div style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <iframe
                        src={content?.videoURL}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            pointerEvents: activeMode === 'edit' ? 'none' : 'auto'
                        }}>
                    </iframe>
                </div>

            }


        </ElementWrapper >
    );
};

export default VideoElement;
