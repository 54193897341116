import React, {useEffect, useState} from "react";
import './ScrollBars.css';
import {useDispatch, useSelector} from "react-redux";
import {setMaxScroll} from "../../../store/actions/uiActions";

export const ScrollBars = (props) => {

    const {width: containerWidth, height: containerHeight} = props
    const dispatch = useDispatch();
    const zoom = useSelector(state => state.ui.zoom)
    const maxScroll = useSelector(state => state.ui.maxScroll)
    const promptSelecting = useSelector(state => state.prompt.promptSelecting)
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const [scroll, setScroll] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [axis, setAxis] = useState('');
    const [verticalScrollbarHeight, setVerticalScrollbarHeight] = useState(0);
    const [horizontalScrollbarWidth, setHorizontalScrollbarWidth] = useState(0);
    const maxScrollbarHeight = 200;
    const maxScrollbarWidth = 800;
    const minScrollbarHeight = 40;
    const minScrollbarWidth = 100;

    useEffect(() => {
        // probably add throttle to increase performance in the future
        const canvas = document.getElementById('canvas-scrollable')
        let maxScrollX;
        let maxScrollY;
        if (zoom <= 1) {
            maxScrollX = (containerWidth - window.innerWidth) - (containerWidth - Math.round(containerWidth * zoom))
            maxScrollY = (containerHeight - window.innerHeight) - (containerHeight - Math.round(containerHeight * zoom))
            const newHeight = (window.innerHeight / maxScrollY) * window.innerHeight * 0.5
            if (newHeight > maxScrollbarHeight) setVerticalScrollbarHeight(maxScrollbarHeight)
            else setVerticalScrollbarHeight(newHeight)
            const newWidth = (window.innerWidth / maxScrollX) * window.innerWidth * 0.5
            if (newWidth > maxScrollbarWidth) setHorizontalScrollbarWidth(maxScrollbarWidth)
            else setHorizontalScrollbarWidth(newWidth)
        } else {
            const newHeight = verticalScrollbarHeight / zoom
            const newWidth = horizontalScrollbarWidth / zoom
            if (newHeight < minScrollbarHeight) setVerticalScrollbarHeight(minScrollbarHeight)
            else setVerticalScrollbarHeight(newHeight)
            if (newWidth < minScrollbarWidth) setHorizontalScrollbarWidth(minScrollbarWidth)
            else setHorizontalScrollbarWidth(newWidth)
            maxScrollX = containerWidth - window.innerWidth
            maxScrollY = containerHeight - window.innerHeight
        }
        dispatch(setMaxScroll({x: maxScrollX, y: maxScrollY}))
        const handleScroll = () => {
            setScroll({x: canvas.scrollLeft, y: canvas.scrollTop})
            const scrollLeft = canvas.scrollLeft
            const scrollTop = canvas.scrollTop
            if (zoom <= 1) {
                if (scrollLeft > maxScrollX ) canvas.scrollTo(maxScrollX, scrollTop)
                if (scrollTop > maxScrollY) canvas.scrollTo(scrollLeft, maxScrollY)
            }
        }
        handleScroll()
        canvas.addEventListener('scroll', handleScroll)
        return () => canvas.removeEventListener('scroll', handleScroll)
    }, [zoom])

    const calculatePositions = () => {
        if (zoom <= 1) {
            const verticalScrollbarTop = (isGuestMode ? 0 : 120) + (scroll.y * (window.innerHeight - verticalScrollbarHeight - 130)) / maxScroll.y;
            const horizontalScrollbarLeft = 5 + (scroll.x * (window.innerWidth - horizontalScrollbarWidth - 10)) / maxScroll.x;
            return {verticalScrollbarTop, horizontalScrollbarLeft}
        } else {
            // improve this, not super important
            let verticalScrollbarTop = (isGuestMode ? 0 : 120) + (scroll.y / maxScroll.y) * (window.innerHeight - verticalScrollbarHeight - 120);
            let horizontalScrollbarLeft = 5 + (scroll.x / maxScroll.x) * (window.innerWidth - horizontalScrollbarWidth - 5);
            const maxVerticalScroll = window.innerHeight - verticalScrollbarHeight - 5;
            if (verticalScrollbarTop > maxVerticalScroll) verticalScrollbarTop = maxVerticalScroll;
            const maxHorizontalScroll = window.innerWidth - horizontalScrollbarWidth - 5;
            if (horizontalScrollbarLeft > maxHorizontalScroll) horizontalScrollbarLeft = maxHorizontalScroll;
            return { verticalScrollbarTop, horizontalScrollbarLeft };
        }
    }

    const handleMouseDown = (e, axis) => {
        setIsDragging(true)
        setAxis(axis)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const handleMove = (e) => {
        const canvas = document.getElementById('canvas-scrollable')
        if (axis === 'horizontal') {
            const offsetX = e.clientX - (horizontalScrollbarWidth / 2);
            const percentageScrolled = offsetX / (canvas.clientWidth - horizontalScrollbarWidth)
            const scrollLeft = percentageScrolled * maxScroll.x;
            if (scrollLeft > 0) {
                setScroll({x: scrollLeft, y: canvas.scrollTop})
                canvas.scrollTo(scrollLeft, canvas.scrollTop)
            }
        }
        if (axis === 'vertical') {
            const offsetY = e.clientY - (verticalScrollbarHeight / 2) - 115;
            const percentageScrolled = offsetY / (canvas.clientHeight - verticalScrollbarHeight)
            const scrollTop = percentageScrolled * maxScroll.y;
            if (scrollTop > 0) {
                setScroll({x: canvas.scrollLeft, y: scrollTop})
                canvas.scrollTo(canvas.scrollLeft, scrollTop)
            }
        }
    }

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const scrollbarsPositions = calculatePositions()

    return (
        <>
            {
                maxScroll.y > 0 &&
                <div
                    style={{
                        top: scrollbarsPositions.verticalScrollbarTop,
                        right: promptSelecting ? 405 : 5,
                        width: 10,
                        height: verticalScrollbarHeight,
                        cursor: isDragging ? 'grabbing' : 'grab'
                    }}
                    className={'scrollbar'}
                    onMouseDown={(e) => handleMouseDown(e, 'vertical')}
                />
            }
            {
                maxScroll.x > 0 &&
                <div
                    style={{
                        bottom: 5,
                        left: scrollbarsPositions.horizontalScrollbarLeft,
                        height: 10,
                        width: horizontalScrollbarWidth,
                        cursor: isDragging ? 'grabbing' : 'grab'
                    }}
                    className={'scrollbar'}
                    onMouseDown={(e) => handleMouseDown(e, 'horizontal')}
                />
            }
        </>
    )
}
