import {Box, Typography} from "@mui/material";
import {Switch} from "../../../../../common/Switch/Switch";
import React, {useCallback, useState} from "react";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {FONT_FAMILIES, FONT_WEIGHTS, TABLE_DEFAULT_VALUES} from "../styleConsts";
import {Slider} from "../../../../../common/Slider/Slider";
import {debounce, cloneDeep} from "lodash";

export const TableConfig = ({
    config,
    setConfig,
    isHeader,
    chartPreferences
}) => {
    const [fontSize, setFontSize] = useState(
        isHeader ? config.styleOverrides?.table?.headerFontSize || TABLE_DEFAULT_VALUES.headerFontSize :
        config.styleOverrides?.table?.fontSize || TABLE_DEFAULT_VALUES.fontSize);

    const debouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config);
        newConfig.styleOverrides.table[prop] = value;
        setConfig(newConfig);
    }, 200), [config]);

    const handleDebouncedTablePropValueChange = (prop, value, setter) => {
        debouncedFunc(prop, value);
        setter(value);
    }

    const handleTablePropValueChange = (prop, value) => {
        const newConfig = cloneDeep(config);
        newConfig.styleOverrides.table[prop] = value
        setConfig(newConfig)
    }

    const getTablePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.table, prop)) return config.styleOverrides.table[prop]
        if (propExistsOnObject(chartPreferences.table, prop)) return chartPreferences.table[prop]
        return TABLE_DEFAULT_VALUES[prop];
    }

    const hideBorder = getTablePropValue('hideBorder');

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            {
                !isHeader &&
                    <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                        <Switch
                            checked={hideBorder}
                            onClick={() => handleTablePropValueChange('hideBorder', !hideBorder)}
                        />
                        <Typography className={'inter style-config-label'}>Hide border</Typography>
                    </Box>
            }
            {
                isHeader &&
                    renderColorPicker(
                        'Background color',
                        getTablePropValue('headerBackgroundColor'),
                        (v) => handleTablePropValueChange('headerBackgroundColor', v)
                    )
            }
            {
                renderColorPicker(
                    'Font color',
                    getTablePropValue(isHeader ? 'headerFontColor' : 'fontColor'),
                    (v) => handleTablePropValueChange(isHeader ? 'headerFontColor' : 'fontColor', v)
                )
            }
            {
                renderSelector(
                    'Font family',
                    getTablePropValue(isHeader ? 'headerFontFamily' : 'fontFamily'),
                    (v) => handleTablePropValueChange(isHeader ? 'headerFontFamily' : 'fontFamily', v),
                    FONT_FAMILIES
                )
            }
            {
                renderSelector(
                    'Font weight',
                    getTablePropValue(isHeader ? 'headerFontWeight' : 'fontWeight'),
                    (v) => handleTablePropValueChange(isHeader ? 'headerFontWeight' : 'fontWeight', v),
                    FONT_WEIGHTS
                )
            }
            {
                <Slider
                    label={`Font size: ${fontSize}px`}
                    value={fontSize}
                    min={8}
                    max={24}
                    step={1}
                    onChange={(v) => handleDebouncedTablePropValueChange(isHeader ? 'headerFontSize' : 'fontSize', v, setFontSize)}
                />
            }
        </Box>
    )
}