import {OBJECT_TYPES} from "../types";

export class FrameClass {

    constructor(id, title, x, y, width, height, backgroundColor, presentationIndex, hidden, backgroundImage) {
        this.id = id;
        this.title = title;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.backgroundColor = backgroundColor;
        this.presentationIndex = presentationIndex;
        this.hidden = hidden;
        this.backgroundImage = backgroundImage;
        this.type = OBJECT_TYPES.FRAME;
    }

    static newFrame(zoom, presentationIndex, backgroundImage, x, y, width, height, title, backgroundColor, id) {
        const canvasScrollElement = document.getElementById('canvas-scrollable')
        return new FrameClass(
            id ? id : Math.random(),
            title ? title : 'Unnamed frame',
            x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
            y ? y : (200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
            width ? width : 1280,
            height ? height : 720,
            backgroundColor ? backgroundColor : '#6191F233',
            presentationIndex,
            false,
            backgroundImage
        );
    }
}
