import Box from "@mui/material/Box";
import './NavBar.css'
import {Tooltip} from "@mui/material";

const NavbarItem=({ icon, label, selected, onClick })=> {

    return (
        <Tooltip title={label}>
            <Box
                aria-label={label}
                onClick={onClick}
                className={'navbar-item'}
                sx={{backgroundColor: selected ? '#422E47' : 'inherit', padding:'0 16px'}}
            >
                {icon}
            </Box>
        </Tooltip>
    );
}
export default NavbarItem;
