import React, {useCallback, useState} from "react";
import './ElementInfo.css';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";
import {updateElementTitle} from "../../../store/actions";

export const ElementInfo = ({title, type, id}) => {

    const dispatch = useDispatch();
    const activeMode = useSelector(state => state.ui.activeMode);
    const [editTitle, setEditTitle] = useState(false);
    const [elementTitle, setElementTitle] = useState(title || '');
    const debounceFn = useCallback(debounce((value) => {
        dispatch(updateElementTitle(id, value))
    }, 200), []);

    return (
        <Box className={'element-info-container'}>
            {
                editTitle ?
                    <input
                        value={elementTitle}
                        onChange={(e) => {
                            setElementTitle(e.target.value)
                            debounceFn(e.target.value)
                        }}
                        className={'edit-element-title-input'}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setEditTitle(false);
                            }
                        }}
                    /> :
                    <Typography
                        onDoubleClick={() => {if (activeMode === 'edit') setEditTitle(true)}}
                        className={'inter element-info-text'}
                    >
                        {title + ' - ' + type}
                    </Typography>
            }
        </Box>
    )
}
