import Dialog from "../../../common/Dialog/Dialog";
import {Box, Typography} from "@mui/material";
import './NewDatasetModal.css';
import SalesforceIcon from '../../../../assets/icons/Salesforce.svg'
import LinkedInIcon from '../../../../assets/icons/LinkedIn.svg';
import GoogleSheets from '../../../../assets/icons/GoogleSheets.svg';
import GoogleAnalytics from '../../../../assets/icons/GoogleAnalytics.svg';
import JiraIcon from '../../../../assets/icons/Jira.svg';
import HubspotIcon from '../../../../assets/icons/Hubspot.svg';
import AirtableIcon from '../../../../assets/icons/Airtable.svg';
import OtherIcon from '../../../../assets/icons/Email.svg'
// import AWS from '../../../../assets/icons/AWS.svg';
import GoogleDataStudioIcon from '../../../../assets/icons/GoogleDataStudio.svg';
import SharetribeIcon from '../../../../assets/icons/Sharetribe.svg';
import DatabaseBlack from '../../../../assets/icons/DatabaseBlack.svg'
import PipedriveIcon from '../../../../assets/icons/PipedriveIcon.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import ApolloIcon from '../../../../assets/icons/apollo.svg';
import {useEffect, useState} from "react";
import {Hubspot} from "../../Appconnect/Hubspot/Hubspot";
import {LinkedIn} from "../../Appconnect/LinkedIn/LinkedIn";
import {GSheets} from "../../Appconnect/GSheets/GSheets";
import {GoogleDataStudio} from "../../Appconnect/GoogleDataStudio/GoogleDataStudio";
import {Sharetribe} from "../../Appconnect/Sharetribe/Sharetribe";
import {Jira} from "../../Appconnect/Jira/Jira";
import {SelectType} from "./SelectType";
import {Salesforce} from "../../Appconnect/GMail/Salesforce";
import {Other} from "../../Appconnect/GMail/Other";
import {Database} from "../../Appconnect/Database/Database";
import datasourceDefs from "../../../../api/datasourceConfig";
import {SelectAPIorReport} from "./SelectAPIorReport";
import {APIConnector} from "../../Appconnect/API/APIConnector";

const renderComingSoon = () => {
    return (
        <Typography
            sx={{
                paddingBottom: '1px',
                paddingRight: '4px',
                fontSize: '10px',
                position: 'absolute',
                top: '2px',
                right: '1px',
                color: '#E50B54',
                fontFamily: 'Inter',
                fontStyle: 'normal',
            }}
        >
            {/* Coming Soon! */}
        </Typography>
    )
}

export const ConnectDatasource = ({onClose, setAlert}) => {
    const [source, setSource] = useState('')
    const [type, setType] = useState('')
    const [step, setStep] = useState(0)
    const [isSnapshotDefault, setIsSnapshotDefault] = useState(false)
    const [basicConfiguration, setBasicConfiguration] = useState(null)
    const [isAPI, setIsAPI] = useState(false);

    const handleContinue = (data) => {
        setBasicConfiguration(data)
        setStep(2)
    }

    useEffect(() => {
        datasourceDefs.find(f => f.name === source)?.isSnapshotDefault ? setIsSnapshotDefault(true) : setIsSnapshotDefault(false)
        // console.log(datasourceDefs.find(f => f.name === source), source)
    }, [source])

    const renderSource = () => {
        switch (source) {
            case 'HubSpot':
                if (isAPI) {
                    return <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                        connectorType="HubSpot"
                    />
                } else {
                    return <Hubspot
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)} type={type}
                        setAlert={setAlert}
                    />
                }
            case 'Pipedrive':
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(3)} type={type}
                    setAlert={setAlert}
                    connectorType="Pipedrive"
                />
            case
            'LinkedIn'
            :
                return <LinkedIn
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                />
            case
            'GSheets'
            :
                return <GSheets
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                />
            case
            'GoogleDataStudio'
            :
                return <GoogleDataStudio
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                />
            case
            'Salesforce'
            :
                if (isAPI) {
                    return <APIConnector
                        selectedInbox={basicConfiguration}
                        setSelectedInbox={setBasicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(3)} type={type}
                        setAlert={setAlert}
                        connectorType="Salesforce"
                    />
                } else {
                    return <Salesforce
                        basicConfiguration={basicConfiguration}
                        open={true}
                        onClose={onClose}
                        stepBack={() => setStep(1)}
                        type={type}
                        setAlert={setAlert}
                    />
                }
            case
            'Airtable'
            :
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                    connectorType="Airtable"
                />
            case
            'Jira'
            :
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                    connectorType="Jira"
                />
            case
            'Database'
            :
                return <Database
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />
            case
            'Sharetribe'
            :
                return <Sharetribe
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />
            case
            'Close'
            :
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                    connectorType="Close"
                />
            case
            'Apollo'
            :
                return <APIConnector
                    selectedInbox={basicConfiguration}
                    setSelectedInbox={setBasicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)} type={type}
                    setAlert={setAlert}
                    connectorType="Apollo"
                />
            case
            'Other'
            :
                return <Other
                    basicConfiguration={basicConfiguration}
                    open={true}
                    onClose={onClose}
                    stepBack={() => setStep(1)}
                    type={type}
                    setAlert={setAlert}
                />

            default:
                return renderSourceSelection()
        }
    }

    const renderSourceSelection = () => {
        return (
            <Dialog
                open={true}
                onClose={onClose}
                title={'Dataset from Source'}
            >
                <Typography sx={{fontSize: '14px', color: '#635566'}}>Select the datasource you want to Scoop
                    data
                    from</Typography>
                <Box className={'sources-grid-container'}>

                    <Box
                        className={'source-box'}
                        index={0}
                        onClick={() => {
                            setStep(3)
                            setSource('Salesforce')
                        }}
                    >
                        <img
                            src={SalesforceIcon}
                            alt={'salesforce'}
                            className={'source-icon'}
                            style={{backgroundColor: '#00A1E00D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Salesforce</Typography>
                    </Box>
                    {/* empty slot */}
                    {/* <Box
                        className={'source-box'}
                        index={1}
                        onClick={() => {
                            setStep(1)
                            setSource('LinkedIn')
                        }}
                    >
                        <img
                            src={LinkedInIcon}
                            alt={'LinkedIn'}
                            className={'source-icon'}
                            style={{ backgroundColor: '#107A400D' }}
                        />
                        <Typography sx={{ fontSize: '14px' }}>LinkedIn</Typography>
                    </Box> */}
                    <Box
                        className={'source-box'}
                        index={2}
                        onClick={() => {
                            setStep(1)
                            setSource('GSheets')
                        }}
                    >
                        <img
                            src={GoogleSheets}
                            alt={'google sheets'}
                            className={'source-icon'}
                            style={{backgroundColor: '#21A4640D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Google Sheets</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={5}
                        onClick={() => {
                            setStep(3)
                            setSource('HubSpot')
                        }}
                    >
                        <img
                            src={HubspotIcon}
                            alt={'hubspot'}
                            className={'source-icon'}
                            style={{backgroundColor: '#FF7A590D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>HubSpot</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={5}
                        onClick={() => {
                            setStep(3)
                            setSource('Pipedrive')
                        }}
                    >
                        <img
                            src={PipedriveIcon}
                            alt={'pipedrive'}
                            className={'source-icon'}
                            style={{backgroundColor: '#FF7A590D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Pipedrive</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={5}
                        onClick={() => {
                            setStep(1)
                            setSource('Close')
                        }}
                    >
                        <img
                            src={CloseIcon}
                            alt={'close'}
                            className={'source-icon'}
                            style={{backgroundColor: '#FF7A590D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Close</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={5}
                        onClick={() => {
                            setStep(1)
                            setSource('LinkedIn')
                        }}
                    >
                        <img
                            src={LinkedInIcon}
                            alt={'LinkedIn'}
                            className={'source-icon'}
                            style={{backgroundColor: '#FF7A590D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>LinkedIn</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={6}
                        onClick={() => {
                            setStep(1)
                            setSource('Airtable')
                        }}
                    >
                        <img
                            src={AirtableIcon}
                            alt={'Airtable'}
                            className={'source-icon'}
                            style={{backgroundColor: '#0000000D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Airtable</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={9}
                        onClick={() => {
                            setStep(1)
                            setSource('Database')
                        }}
                    >
                        <img src={DatabaseBlack}
                             alt={'database'}
                             className={'source-icon'}
                             style={{backgroundColor: '#0000000D'}}/>
                        <Typography sx={{fontSize: '14px'}}>Database</Typography>
                    </Box>
                    <Box
                        className={'source-box'}
                        index={10}
                        onClick={() => {
                            setStep(1)
                            setSource('GoogleDataStudio')
                        }}
                    >
                        <img
                            src={GoogleDataStudioIcon}
                            alt={'google data studio'}
                            className={'source-icon'}
                            style={{backgroundColor: 'rgba(38, 132, 255, 0.05)'}}/>
                        <Typography sx={{fontSize: '14px'}}>Google Data Studio</Typography>
                        {/* {renderComingSoon()} */}
                    </Box>
                    <Box
                        className={'source-box'}
                        index={10}
                        onClick={() => {
                            setStep(1)
                            setSource('Apollo')
                        }}
                    >
                        <img
                            src={ApolloIcon}
                            alt={'apollo'}
                            className={'source-icon'}
                            style={{backgroundColor: 'rgba(38, 132, 255, 0.05)'}}/>
                        <Typography sx={{fontSize: '14px'}}>Apollo</Typography>
                        {/* {renderComingSoon()} */}
                    </Box>
                    <Box
                        className={'source-box'}
                        index={10}
                        onClick={() => {
                            setStep(1)
                            setSource('Sharetribe')
                        }}
                    >
                        <img
                            src={SharetribeIcon}
                            alt={'google data studio'}
                            className={'source-icon'}
                            style={{backgroundColor: 'rgba(38, 132, 255, 0.05)'}}/>
                        <Typography sx={{fontSize: '14px'}}>Sharetribe</Typography>
                        {/* {renderComingSoon()} */}
                    </Box>
                    {/* <Box
                        className={'source-box disabled-element'}
                        index={7}
                        onClick={() => setSource('AWS')}
                    >
                        <img
                            src={AWS}
                            alt={'aws'}
                            className={'source-icon'}
                            style={{ backgroundColor: '#FF99000D' }}
                        />
                        <Typography sx={{ fontSize: '14px' }}>AWS</Typography>
                        {renderComingSoon()}
                    </Box> */}
                    {/* <Box
                        className={'source-box disabled-element'}
                        index={3}
                        onClick={() => {
                            setStep(1)
                            setSource('Google Analytics')
                        }}
                    >
                        < img
                            src={GoogleAnalytics}
                            alt={'google analytics'}
                            className={'source-icon'}
                            style={{backgroundColor: '#E374000D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Google Analytics</Typography>
                        {renderComingSoon()}
                    </Box> */}
                    <Box
                        className={'source-box'}
                        index={4}
                        onClick={() => {
                            setStep(1)
                            setSource('Jira')
                        }}
                    >
                        <img
                            src={JiraIcon}
                            alt={'Jira'}
                            className={'source-icon'}
                            style={{backgroundColor: '#0000000D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Jira</Typography>
                    </Box>

                    <Box
                        className={'source-box'}
                        index={0}
                        onClick={() => {
                            setStep(1)
                            setSource('Other')
                        }}
                    >
                        <img
                            src={OtherIcon}
                            alt={'Other'}
                            className={'source-icon'}
                            style={{backgroundColor: '#00A1E00D'}}
                        />
                        <Typography sx={{fontSize: '14px'}}>Other</Typography>
                    </Box>
                </Box>

            </Dialog>
        )

    }


    const renderStepContent = () => {
        switch (step) {
            case 0:
                return renderSourceSelection()
            case 1:
                return <SelectType
                    handleContinue={handleContinue}
                    index={1}
                    setType={setType}
                    step={step}
                    setStep={setStep}
                    onClose={onClose}
                    isSnapshotDefault={isSnapshotDefault}
                />
            case 2:
                return renderSource()
            case 3:
                return <SelectAPIorReport
                    index={3}
                    setType={setType}
                    step={step}
                    setStep={setStep}
                    onClose={onClose}
                    handleContinue={(active) => {
                        if (active === 'Report Data Source') {
                            setIsAPI(false);
                        } else if (active === 'API Data Source') {
                            setIsAPI(true);
                        }
                        setStep(1);
                    }}
                    sourceName={source}
                />
            default:
                return renderSourceSelection()
        }
    }

    return (<>
        {renderStepContent()}
    </>)
}