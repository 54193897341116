import {
    SET_LAST_EDITED_PROMPT,
    SET_PROMPT_SELECTING,
    SET_PROMPT_SELECTING_OBJECTS,
    SET_UPDATED_WORKSHEET,
    TOGGLE_PROMPT_OBJECT
} from "../actions/promptActions";

const initialState = {
    currentPrompt: null,
    updatedWorksheet: '',
    promptSelecting: false,
    promptSelectingObjects: [],
    lastEditedPrompt: null
};

const promptReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROMPT_SELECTING:
            return {...state, promptSelecting: action.payload.toggle, currentPrompt: action.payload.id};
        case SET_PROMPT_SELECTING_OBJECTS:
            return {...state, promptSelectingObjects: action.payload};
        case TOGGLE_PROMPT_OBJECT:
            const newState = {...state}
            if (newState.promptSelectingObjects.includes(action.payload)) {
                newState.promptSelectingObjects = newState.promptSelectingObjects.filter(id => id !== action.payload)
            } else {
                newState.promptSelectingObjects = [...newState.promptSelectingObjects, action.payload]
            }
            return newState
        case SET_LAST_EDITED_PROMPT:
            return {...state, lastEditedPrompt: action.payload};
        case SET_UPDATED_WORKSHEET:
            return {...state, updatedWorksheet: action.payload};
        default:
            return state;
    }
};

export default promptReducer;
