import { Card, CardContent, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "../../../common/Button/Button";
import Info from "../../../../assets/icons/Info.svg";
import { useState, useEffect } from "react";
import { ConfigureDialog } from "./ConfigureDialog";
import { Database } from "../../../screens/Appconnect/Database/Database";
import { Hubspot } from "../../Appconnect/Hubspot/Hubspot";
import Alert from '@mui/material/Alert';
import { Toast } from "../../../common/Toast/Toast";
import { useSelector } from "react-redux";
import { useApi } from '../../../../api/api';
import LinearProgress from '@mui/material/LinearProgress';
import { ConfigDialog } from "../ConfigDialog";

export const DatasetCard = ({ dataset, server, refreshInbox }) => {
    const [openConfigure, setOpenConfigure] = useState(false);
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [alert, setAlert] = useState(null);
    const userID = useSelector((state) => state.auth.userID);
    const { postData } = useApi("https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions");
    const [isLoading, setIsLoading] = useState(false);
    const [datasetType, setDatasetType] = useState('');

    useEffect(() => {
        switch (dataset.inboxType) {
            case 'robot': 
                setDatasetType(dataset.botService ? 'Data Connector' : 'File Upload'); 
                break;
            case 'calculated': 
                setDatasetType('Blended'); 
                break;
            default: 
                setDatasetType('Email'); 
                break;
        }
    }, [dataset.inboxType, dataset.botService]);

    if (!dataset) return null;

    const runBot = async () => {
        const action = {
            "action": "runBot",
            "inboxID": dataset.inboxID,
            "userID": userID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        try {
            const result = await postData(action);
            return result;
        } catch (error) {
            console.error('RunBot error:', error); // Debug log
            setAlert({
                message: 'The process is taking some time, your new data will become available soon.',
                severity: 'info'
            })
            setIsLoading(false);
            return null;
        }
    };

    const handleRunBot = () => {
        setIsLoading(true);
        runBot().then((results) => {
            if (results?.status === 'success') {
                setAlert({ message: 'App Connector ran successfully!', severity: 'success' });
            } else if (results?.status === 'error') {
                setAlert({
                    message: 'The process is taking some time, your new data will become available soon.',
                    severity: 'info'
                });
            } else if (!results) {
                // Handle the case where the API call failed and returned null
                setAlert({
                    message: 'The process is taking some time, your new data will become available soon.',
                    severity: 'info'
                })
            }
            setIsLoading(false);
        });
    };

    return (
        <>
            <Card
                sx={{ minWidth: 275, backgroundColor: '#FFFFFF', borderRadius: '5px', height: '100%', padding: '32px' }}>
                <CardContent>
                    <Typography sx={{ fontSize: '24px' }} component="div">
                        {dataset.label}
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontSize: '14px' }} color="text.secondary">
                        {dataset.description}
                    </Typography>
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: '#635566', marginRight: '5px' }}>
                                Source
                            </Typography>
                            <Tooltip aria-label="info" title={''}>
                                <img src={Info} alt={'info'} />
                            </Tooltip>
                        </div>

                        <Typography variant="body2" component="p">
                            {datasetType}
                        </Typography>
                    </div>
                    {/* GJ took out, why do we need method if it's same as source? */}
                    {/* <div style={{ marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: '#635566', marginRight: '5px' }}>
                                Method
                            </Typography>
                            <Tooltip aria-label="info" title={''}>
                                <img src={Info} alt={'info'} />
                            </Tooltip>
                        </div>
                        <Typography variant="body2" component="p">
                            {dataset.inboxType}
                        </Typography>
                    </div> */}
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: '#635566', marginRight: '5px' }}>
                                Type
                            </Typography>
                            <Tooltip aria-label="info">
                                <img src={Info} alt={'info'} />
                            </Tooltip>
                        </div>

                        <Typography variant="body2" component="p">
                            {dataset.transactional ? 'Transactional' : 'Snapshot'}
                        </Typography>
                    </div>
                    <div style={{ marginBottom: '16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: '#635566', marginRight: '5px' }}>
                                Scoop Frequency
                            </Typography>
                            <Tooltip aria-label="info" title={''}>
                                <img src={Info} alt={'info'} />
                            </Tooltip>
                        </div>

                        <Typography variant="body2" component="p">
                            Daily
                        </Typography>
                    </div>
                </CardContent>

                {datasetType === 'Data Connector' &&
                    <Button className={'small'} sx={{ backgroundColor: '#F2F2F2', marginLeft: '8px' }}
                        onClick={handleRunBot}>Extract data now</Button>
                }
                {!dataset.readOnly &&
                    <Button className={'small'} sx={{ backgroundColor: '#F2F2F2', marginLeft: '8px' }} onClick={() => {
                        console.log(dataset)
                        setOpenConfigure(true);
                    }}>Configure</Button>
                }
                {isLoading &&
                    <LinearProgress variant="indeterminate" style={{ marginTop: '20px', height: '10px' }} />
                }
            </Card>
            {dataset.inboxType === 'robot' && dataset.botService === 'R19' && openAdvanced &&
                <Database
                    open={openAdvanced}
                    onClose={() => setOpenAdvanced(false)}
                    initialDatasetDetails={dataset}
                    afterSave={() => setOpenConfigure(false)}
                    setAlert={setAlert}
                    stepBack={() => {
                        setOpenAdvanced(false);
                        setOpenConfigure(true);
                    }}
                />
            }
            {dataset.inboxType !== 'robot' && openConfigure &&
                <ConfigDialog
                    isEmailSource={dataset.inboxType === 'email'}
                    isTransactional={dataset.transactional}
                    server={server}
                    isEditing={true}
                    inbox={dataset}
                    open={openConfigure}
                    onClose={() => setOpenConfigure(false)}
                    refreshInbox={refreshInbox}
                />
            }
            {datasetType === 'Data Connector' && dataset.botService === 'R19' && openConfigure &&
                <ConfigDialog
                    isTransactional={dataset.transactional}
                    isEditing={true}
                    inbox={dataset}
                    open={openConfigure}
                    goToAdvance={() => {
                        setOpenAdvanced(true);
                        setOpenConfigure(false);
                    }}
                    onClose={() => setOpenConfigure(false)}
                    refreshInbox={refreshInbox}
                    server={server}
                />
            }
            {datasetType === 'Data Connector' && dataset.botService === 'R17' &&
                <Hubspot
                    open={openConfigure}
                    onClose={() => setOpenConfigure(false)}
                    initialDatasetDetails={dataset}
                    afterSave={() => setOpenConfigure(false)}
                    setAlert={setAlert}
                />
            }
            {alert && alert.message &&
                <Toast
                    alert={alert}
                    onClose={() => setAlert(null)}
                />
            }
        </>
    );
};
