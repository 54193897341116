import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

export const Toast = ({alert, onClose}) => {
    return (
        <Snackbar
            open={Boolean(alert)}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Alert onClose={onClose} severity={alert?.severity} variant="filled">
                {alert?.message}
            </Alert>
        </Snackbar>    )
}