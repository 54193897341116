import Button from "../../../../common/Button/Button";
import React, {useEffect, useState} from "react";
import Dialog from "../../../../common/Dialog/Dialog";
import Stepper from "../../../../common/Stepper/Stepper";
import {Box, Divider, Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import {TabContext, TabPanel} from "@mui/lab";
import Sheetlet from "../../../../Source/Sheetlet";
import {renderToString} from "react-dom/server";
import {DataGrid} from "@mui/x-data-grid";
import googleSheetsIcon from '../../../../../assets/icons/GoogleSheets.svg'

const primaryColor = '#201024';
const secondaryColor = '#635566';

const CustomTabs = styled(Tabs)({
    '& .MuiButtonBase-root': {
        textTransform: 'none',
    },
    '& .MuiButtonBase-root.Mui-selected': {
        color: '#E50B54',
        fontWeight: 600,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#E50B54',
    }
});

const CustomTab = styled(Tab)({});

const CalculatedColumnsDialog = ({
                                     onClose,
                                     goBack,
                                     activeStep,
                                     steps,
                                     goForward,
                                     inboxID,
                                     isBlending,
                                     serverContext
}) => {

    const [tabValue, setTabValue] = useState('0');
    const [previewData, setPreviewData] = useState(null);

    const sheetRange = {
        "sheetType": "CalculatedSource",
        "inboxID": inboxID,
    }

    const [outputDefinition, setOutputDefinition] = useState(null);

    useEffect(() => {
        { tabValue === '1' ? updatePreviewData() : getOutputDefinition() }
    }, [tabValue]);

    const getOutputDefinition = async () => {
        await serverContext.server.sheetPostData({
            "action": "getCSWDefinition",
            "inboxID": inboxID,
        }, processOutputDefinition);
    }

    const processOutputDefinition = (results) => {
        if (!results.queries) return;
        setOutputDefinition(results);
    }

    const updatePreviewData = async () => {
        await serverContext.server.sheetPostData({
            "action": "getCSWPreviewData",
            "inboxID": inboxID,
            "singleQuery": !isBlending,
        }, processPreviewData);
    }

    const processPreviewData = (results) => {
        if (results && results.rows && results.columns) setPreviewData(results);
    }

    return (
        <Dialog
            style={{width: "1168px", height: '790px', maxHeight: '790px'}}
            maxWidth={false}
            maxHeight={false}
            open
            onClose={() => {
                onClose()
            }}
            actions={
                <>
                    <Button
                        className={'button-grey small'}
                        onClick={goBack}
                    >
                        Back
                    </Button>
                    <Button
                        className={'button-purple small'}
                        onClick={goForward}
                    >
                        Continue
                    </Button>
                </>
            }
            title={<Stepper width={'45%'} activeStep={activeStep} steps={steps}/>}
        >
            <Divider/>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <Box>
                    <Typography sx={{color: primaryColor, fontSize: '24px', fontWeight: 600}}>
                        Create Calculated Columns
                    </Typography>
                    {isBlending ?
                        <p style={{color: secondaryColor, fontSize: '14px'}}>
                            Blend both data queries together by matching fields and creating blending conditions.
                            <a href={'#'} style={{color: '#BF0A46', fontSize: '14px', textDecoration: 'none'}}> Watch
                                the video </a>
                            to learn how.
                        </p> :
                        <p style={{color: secondaryColor, fontSize: '14px'}}>
                            Lorem ipsum dolor sit amet consectetur. Venenatis at adipiscing sapien fames integer eu
                            scelerisque orci.
                        </p>
                    }
                </Box>
                <Box>
                    <TabContext value={tabValue}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <CustomTabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
                                <CustomTab value={'0'} label={isBlending ? 'Blend' : 'Create'}/>
                                <CustomTab value={'1'} label="Preview Data"/>
                            </CustomTabs>
                        </Box>
                        <Box>
                            <TabPanel value={'0'}>
                                <Sheetlet
                                    isBlending={isBlending}
                                    serverContext={serverContext}
                                    sheetRange={sheetRange}
                                    locked={true}
                                    height={400}
                                    cornerHTML={
                                        '<a href="https://docs.google.com/spreadsheets/d/' + outputDefinition?.worksheetID + '" target="_blank">' +
                                            renderToString(<img alt={'Google sheets'} src={googleSheetsIcon} />) +
                                        '</a>'
                                    }
                                    addNew={getOutputDefinition}
                                />
                            </TabPanel>
                            <TabPanel value={'1'}>
                                {previewData &&
                                    <Box>
                                        <DataGrid
                                            rowHeight={20}
                                            rows={previewData.rows}
                                            columns={previewData.columns}
                                            sx={{
                                                color: "text.secondary",
                                                '& div.MuiListItemIcon-root': {color: "#000"}
                                            }}
                                        />
                                    </Box>
                                }
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Box>
        </Dialog>
    )

}

export default CalculatedColumnsDialog;