// CalculatedColumnsDialog.js
import React, {useEffect, useState} from "react";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Link,
    Typography
} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import Sheetlet from "./Sheetlet";
import {renderToString} from "react-dom/server";
import AppsIcon from "@mui/icons-material/Apps";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {Server} from "../../api/Server";
import {useSelector} from "react-redux";
import {ServerContext} from "./SheetState";
import Button from "../common/Button/Button";
import {ScoopLoader} from "../common/Spinner/ScoopLoader";

export function CalculatedColumnsDialog({open, setOpen, inboxID, serverContext}) {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const [previewData, setPreviewData] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentServerContext, setCurrentServerContext] = useState(null);

    useEffect(() => {
        serverContext ? setCurrentServerContext(serverContext) : setCurrentServerContext(new ServerContext(new Server(workspaceID, userID, token)));
    }, [serverContext]);
    const [sheetRange, setSheetRange] = React.useState({
        "sheetType": "CalculatedField",
        "advanced" : false,
        "inboxID": inboxID
    });
    
    function handleClose(event) {
        if (event.code === "Escape")
            return;
        setOpen(false);
        setPreviewData(null);
        setInitialized(false);
    }

    function handleSave(event) {
        setOpen(false);
        currentServerContext.server.postData({
            "action": "flushWorksheetCache",
            "sheetRange": {
                "inboxID": inboxID
            }
        }, () => {
        });
        setPreviewData(null);
        setInitialized(false);
    }

    function handleCancel(event) {
        setOpen(false);
        currentServerContext.server.postData({
            "action": "clearWorksheetCache",
            "sheetRange": {
                "inboxID": inboxID
            }
        }, () => {
        });
        setPreviewData(null);
        setInitialized(false);
    }

    function processPreviewData(results) {
        setLoading(false);
        if (results && results.rows) {
            setPreviewData(results);
        } else {
            setPreviewData(null);
        }
    }

    function updatePreviewData() {
        currentServerContext.server.sheetPostData({
            "action": "getCFWPreviewData",
            "inboxID": inboxID
        }, processPreviewData);
    }

    if (!initialized && open) {
/*        updatePreviewData();*/
        sheetRange.inboxID = inboxID;
        setSheetRange({...sheetRange});
        setLoading(true);
        setInitialized(true);
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'1000px'}>
            <DialogTitle sx={{color: 'text.secondary'}}>Create and Modify Calculated Dataset Columns</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create and edit custom calculated columns that can be added to your raw report source data. You can
                    also choose to not include columns from your raw reports.
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel sx={{color: 'text.secondary'}} control={<Switch checked={sheetRange.advanced}
                                                                                      onChange={(event) => {
                                                                                          sheetRange.advanced = !sheetRange.advanced;
                                                                                          setSheetRange({...sheetRange});
                                                                                      }}/>}
                                      label="Advanced"/>
                </FormGroup>
                <Sheetlet serverContext={currentServerContext} sheetRange={sheetRange} locked={!sheetRange.advanced}
                          embeddedSizeProps={{height: sheetRange.advanced ? 310 : 270}}
                          cornerHTML={previewData ? '<a href="https://docs.google.com/spreadsheets/d/' + previewData.worksheetID + '" target="_blank">' + renderToString(
                              <AppsIcon/>) + '</a>' : ''}/>
                <Box sx={{
                    mt: 2,
                    mb: 1,
                    color: 'text.secondary',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <Typography>Preview Results:</Typography>
                    <Button variant="contained"
                            className={'button-purple'}
                            onClick={() => {
                                updatePreviewData();
                                setLoading(true);
                            }}>Update Preview</Button>
                </Box>
                {previewData &&
                    <Box>
                        <DataGrid rowHeight={20} rows={previewData.rows} columns={previewData.columns}
                                  sx={{color: "text.secondary", '& div.MuiListItemIcon-root': {color: "#000"}}}/>
                    </Box>}
                {!previewData && loading && <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <ScoopLoader size={56}/>
                </Box>}
            </DialogContent>
            <DialogActions>
                <Button className={'small'} onClick={handleCancel}>Cancel</Button>
                <Button variant="contained" className={'button-purple small'} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
