import React, {useCallback, useEffect, useState} from "react";
import {Slider} from "../../../../../common/Slider/Slider";
import {Box} from "@mui/material";
import {MARGIN_DEFAULT_VALUES} from "../styleConsts";
import {debounce, cloneDeep} from "lodash";
import {propExistsOnObject} from "../utils";

export const MarginConfig = ({config, setConfig, chartPreferences}) => {

    const [leftMargin, setLeftMargin] = useState(MARGIN_DEFAULT_VALUES.left)
    const [rightMargin, setRightMargin] = useState(MARGIN_DEFAULT_VALUES.right)
    const [topMargin, setTopMargin] = useState(MARGIN_DEFAULT_VALUES.top)
    const [bottomMargin, setBottomMargin] = useState(MARGIN_DEFAULT_VALUES.bottom)

    useEffect(() => {
        setLeftMargin(getGridPropValue('left'))
        setRightMargin(getGridPropValue('right'))
        setTopMargin(getGridPropValue('top'))
        setBottomMargin(getGridPropValue('bottom'))
    }, [config])

    const debouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.grid[prop] = value
        setConfig(newConfig)
    }, 200), [config]);

    const handleDebounceAxisPropValueChange = (prop, value, setter) => {
        setter(value)
        debouncedFunc(prop, value)
    }

    const getGridPropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.grid, prop)) return config.styleOverrides.grid[prop]
        if (propExistsOnObject(chartPreferences.grid, prop)) return chartPreferences.grid[prop]
        return MARGIN_DEFAULT_VALUES[prop]
    }

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            <Slider
                label={`Left margin: ${leftMargin}`}
                value={leftMargin.split('%')[0]}
                onChange={(v) => handleDebounceAxisPropValueChange('left', v + '%', setLeftMargin)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Right margin: ${rightMargin}`}
                value={rightMargin.split('%')[0]}
                onChange={(v) => handleDebounceAxisPropValueChange('right', v + '%', setRightMargin)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Top margin: ${topMargin}`}
                value={topMargin.split('%')[0]}
                onChange={(v) => handleDebounceAxisPropValueChange('top', v + '%', setTopMargin)}
                min={0}
                max={100}
                step={1}
            />
            <Slider
                label={`Bottom margin: ${bottomMargin}`}
                value={bottomMargin.split('%')[0]}
                onChange={(v) => handleDebounceAxisPropValueChange('bottom', v + '%', setBottomMargin)}
                min={0}
                max={100}
                step={1}
            />
        </Box>
    )
}
