import React, { useEffect, useRef, useState } from 'react'
import './CanvasBox.css';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import IconButton from "@mui/material/IconButton";
import TrashRed from "../../../../assets/icons/TrashRed.svg";
import Duplicate from "../../../../assets/icons/Duplicate.svg";
import TextAa from "../../../../assets/icons/TextAa.svg";
import Image from "../../../../assets/icons/Image.svg";
import Input from "../../../common/Input/Input";
import Dialog from "../../../common/Dialog/Dialog";
import Button from "../../../common/Button/Button";
import { useApi } from "../../../../api/api";


export const CanvasBox = ({ canvasID, canvasName, thumbnailURL, index, handleDeleteDialog }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buttonRef = useRef();
    const [showOptions, setShowOptions] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [brokenImage, setBrokenImage] = useState(false);
    const [renameInput, setRenameInput] = useState(canvasName);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';
    const { postData: postCanvasData } = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const objects = useSelector(state => state.objects);
    const zoom = useSelector(state => state.ui.zoom);
    const lastSaved = useSelector(state => state.ui.lastSaved);
    const backgroundColor = useSelector(state => state.ui.backgroundColor || '#ffffff');
    const presentationID = useSelector(state => state.ui.presentationID);

    const [newCanvasName, setNewCanvasName] = useState(canvasName)

    useEffect(() => {
        setNewCanvasName(canvasName)
    }, [canvasName]);

    const handleClick = () => {
        dispatch({
            type: 'APPLY_LOADED_OBJECTS_STATE',
            payload: [],
        });
        dispatch({
            type: 'UPDATE_CANVAS',
            canvasID: '',
            canvasName: '',
        });
        navigate(`${canvasID}`);
    };

    const imgURL = `${thumbnailURL}?${new Date().getTime()}`.replace('https://scoop-canvas-thumbnails.s3.us-west-2.amazonaws.com', 'https://d2wzostg4fezcw.cloudfront.net') || "https://static.thenounproject.com/png/5682184-200.png"

    const handleOptionsOpen = (e) => {
        e.stopPropagation()
        setMenuOpen(true)
    }

    const handleOptionsClose = (e) => {
        e.stopPropagation()
        setMenuOpen(false)
        setShowOptions(false)
    }

    const handleDelete = (e) => {
        e.stopPropagation()
        setMenuOpen(false)
        handleDeleteDialog(canvasID)
    }

    const handleRename = (e) => {
        e.stopPropagation()
        setRenameDialogOpen(true)
        setMenuOpen(false)
    }

    const handleDuplicate = (e) => {
        e.stopPropagation()
        setMenuOpen(false)
        const action = {
            "action": "duplicateCanvas",
            "userID": userID,
            "canvasID": canvasID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        postCanvasData(action).then(() => {
            // TO-DO: maybe instead of reloading, a more elegant way is to have canvases re-read from the server and re-list canvases
            window.location.reload()
        })
    }

    const handleSaveRename = () => {
        const action = {
            "action": "renameCanvas",
            "userID": userID,
            "canvasID": canvasID,
            "canvasName": renameInput,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        postCanvasData(action).then(() => {
            setNewCanvasName(renameInput)
            setRenameDialogOpen(false)
        })
    }

    const renameActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={() => {
                setRenameDialogOpen(false)
                setRenameInput('')
            }} text={'Cancel'} />
            <Button onClick={handleSaveRename} text={'Save'} className={'button-purple'} disabled={renameInput === newCanvasName} />
        </Box>
    )

    return (
        <>
            <Box
                className={'canvas-box'}
                onClick={handleClick}
                index={index}
                onMouseEnter={() => setShowOptions(true)}
                onMouseLeave={() => setShowOptions(false)}
            >
                <Box className={'canvas-image-container'}>
                    {
                        brokenImage ?
                            <img src={Image} alt={'canvas-placeholder'} style={{ height: '100%', opacity: 0.1 }} /> :
                            <img src={imgURL} alt={'canvas-image'} onError={() => setBrokenImage(true)} />
                    }
                </Box>
                <Box className={'canvas-text-container'}>
                    <Typography className={'canvas-name'}>
                        {newCanvasName || "Untitled Canvas"}
                    </Typography>
                </Box>
                {
                    showOptions &&
                    <Box className={'options-container'}>
                        <Box />
                        <IconButton
                            onClick={handleOptionsOpen}
                            sx={{ backgroundColor: 'white', padding: '4px', borderRadius: '5px', border: '1px solid #E6E4E6', color: 'inherit' }}
                            ref={buttonRef}>
                            <MoreHorizRoundedIcon fontSize={'small'} />
                        </IconButton>
                    </Box>
                }
                <Menu open={menuOpen} anchorEl={buttonRef.current} onClose={handleOptionsClose}>
                    <MenuItem onClick={(e) => handleRename(e)}>
                        <img src={TextAa} alt={'text'} style={{ height: 18, width: 18 }} />
                        <Typography className={'inter'} sx={{ ml: '8px', fontSize: '14px' }}>Rename</Typography>
                    </MenuItem>

                    <MenuItem onClick={(e) => handleDuplicate(e)}>
                        <img src={Duplicate} alt={'text'} style={{ height: 16, width: 16 }} />
                        <Typography className={'inter'} sx={{ ml: '8px', fontSize: '14px' }}>Duplicate</Typography>
                    </MenuItem>

                    <MenuItem onClick={handleDelete}>
                        <img src={TrashRed} alt={'trash'} style={{ height: 18, width: 18 }} />
                        <Typography className={'inter'} sx={{ ml: '8px', fontSize: '14px', color: '#EB382A' }}>Delete</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <Dialog
                actions={renameActions}
                open={renameDialogOpen}
                title={'Rename canvas'}
                onClose={() => {
                    setRenameDialogOpen(false)
                    setRenameInput('')
                }}
                style={{ width: 500 }}
            >
                <Input value={renameInput} onChange={(e) => setRenameInput(e.target.value)} />
            </Dialog>
        </>
    );
};
