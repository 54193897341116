import {Button as MuiButton} from "@mui/material";
import React from "react";
import './Button.css'
 const Button = ({onClick, text, className, disabled, children, ...props }) => {
    return(
        <MuiButton
            onClick={onClick}
            disabled={disabled}
            className={`primary-button ${className} ${disabled && 'button-disabled'}`}
            {...props}>
            {text ||children }
        </MuiButton>
    )
}
export default Button;
