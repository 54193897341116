// LinkedIn.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../api/api';
import { useSelector } from "react-redux";
import Dialog from "../../../common/Dialog/Dialog";
import { Step, Stepper, StepLabel, Typography, StepContent, Box } from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import './LinkedIn.css'
import Button from '../../../common/Button/Button'

import datasourceDefs from '../../../../api/datasourceConfig'
import Input from "../../../common/Input/Input";
import Selector from "../../../common/Selector/Selector";

const bot = datasourceDefs.find(b => b.name === "LinkedIn");

export const LinkedIn = ({ open, onClose, stepBack, type, setAlert, initialDatasetDetails, basicConfiguration }) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const navigate = useNavigate();
    const { postData } = useApi(bot.API_URL);
    const [url, setUrl] = useState('https://www.linkedin.com/campaignmanager/accounts/<<your_account>>/campaign-groups');
    const [isUrlValid, setIsUrlValid] = useState(true);
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState('');
    const [isReportTransactional, setIsReportTransactional] = useState(false);
    const [allowMutipleLoads, setAllowMutipleLoads] = useState(false);
    const [reportName, setReportName] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [nextStep, setNextStep] = useState(false)
    // set if user is editing vs creating new
    const [isEditMode, setIsEditMode] = useState(false);
    const [inboxName, setInboxName] = useState(null); //used only if inboxName is passed in as part of update call
    const [inboxID, setInboxID] = useState(null); //used only if inboxID is passed in as part of update call

    useEffect(() => {
        if (initialDatasetDetails) {
            setIsEditMode(true);
            setReportDescription(initialDatasetDetails.description);
            setReportName(initialDatasetDetails.label);
            setInboxName(initialDatasetDetails.inboxName);
            setIsReportTransactional(initialDatasetDetails.isTransactional || false);
            setAllowMutipleLoads(initialDatasetDetails.enableMultipleSameDayLoads || false);
            setInboxID(initialDatasetDetails.inboxID);
            setUrl(initialDatasetDetails.reportLink);
        }

    }, [initialDatasetDetails]);


    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);

        // Validate URL
        setIsUrlValid(bot.urlValidation.test(newUrl));
    };



    const readReports = async () => {
        const action = {
            action: "listReports",
            service: bot.service,
            reportURL: url,
            botUID: bot.botUID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        const result = await postData(action);
        return result;
    };

    const handleGetReports = async () => {
        setIsLoading(true);
        try {
            const data = await readReports();

            const jsonObj = JSON.parse(data.reports);

            // Map to get only the "text" values, skipping the first item
            const reports = jsonObj.slice(1).map(item => item.text);
            setReports(reports);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadedReports();
    }, [reports]);

    const loadedReports = () => {
        if (reports.length > 0 && selectedReport === '') {
            setSelectedReport(reports[0]);
        }
    };


    function createInboxName(userID, selectedReport) {
        // Combine userID and selectedReport with an underscore
        let combinedName = `${userID}_${selectedReport.substring(0, 30)}`;
    
        // Replace characters not in the allowed list with an underscore
        // Allowed characters: alphanumeric, plus (+), minus (-), equals (=), dot (.), comma (,), underscore (_), colon (:), at (@)
        let scrubbedName = combinedName.replace(/[^a-zA-Z0-9\+\-\=\.\,\_\:\@]/g, '_');
    
        // Generate a random 3-digit number to help ensure uniqueness
        const randomDigits = Math.floor(100 + Math.random() * 900);
    
        // Append the random 3-digit number to the scrubbedName
        return `${scrubbedName}_${randomDigits}`;
    }


    const createBot = async () => {
        const action = {
            action: "createRobot",
            inboxName: createInboxName(userID, selectedReport),
            label: selectedReport,
            keepOnlyCurrent: basicConfiguration.isMostRecent,
            incremental: basicConfiguration.isIncremental,
            enableMultipleSameDayLoads: basicConfiguration.isMultipleLoads,
            description: reportDescription,
            workspaceID: workspaceID,
            reportURL: url,
            isTransactional: type === 'Transactional',
            userName: userID,
            service: bot.service,
            botUID: bot.botUID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        const result = await postData(action);
        return result;
    };

    const handleCreateBot = () => {
        createBot().then((results) => {
            console.log(results)
            if (results?.status === 'success')
                setAlert({ message: 'LinkedIn bot created successfully', severity: 'success' })
            else if (results?.status === 'error')
                setAlert({ message: results?.message ?? 'Failed to create LinkedIn Bot', severity: 'error' })
            setNextStep(false)
            stepBack()
            onClose()

        })
    }

    return (
        <>
            {!nextStep ?
                <Dialog
                    open={open}
                    title={'Report from LinkedIn'}
                    icon={bot.icon}
                    onClose={onClose}
                    maxWidth="lg"
                    actions={
                        <>
                            <Button className={'button-grey small'} onClick={stepBack}>Back</Button>
                            <Button
                                className={'button-purple small'}
                                onClick={() => setNextStep(true)}
                                disabled={selectedReport === ''}
                            > I've done this </Button>
                        </>
                    }>
                    <Typography>
                        Share your LinkedIn Campaign Report with Scoop to start importing data
                    </Typography>
                    <DialogContent style={{ width: '800px' }}>
                        <Stepper orientation="vertical">
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',
                                    },
                                }}>
                                    <>
                                        Add the email <b>{bot.botUID}</b> to your campaign manager account users. Select the campaign group, campaign, or specific ad and paste the resulting LinkedIn browser's URL below.
                                    </>
                                </StepLabel>
                            </Step>
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',
                                    },
                                }}>
                                    Paste the entire report URL below
                                </StepLabel>
                                <StepContent>
                                    <Input
                                        label="LinkedIn Reports URL"
                                        fullWidth
                                        variant="outlined"
                                        value={url}
                                        onChange={handleUrlChange}
                                        error={!isUrlValid}
                                        helperText={!isUrlValid ? "Invalid LinkedIn Reports URL" : ""}
                                    />
                                    {url &&
                                        <Button
                                            className={'button-purple small'}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleGetReports}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Fetch Reports List
                                        </Button>
                                    }
                                </StepContent>
                            </Step>
                            <Step active={true}>
                                <StepLabel StepIconProps={{
                                    style: {
                                        color: '#F2F2F2',

                                    },
                                }}>
                                    Select Report
                                </StepLabel>
                                <StepContent>
                                    {reports.length > 0 &&
                                        <Selector
                                            fullWidth
                                            variant="outlined"
                                            value={selectedReport || ''}
                                            onChange={(e) => {
                                                setSelectedReport(e.target.value)
                                            }}
                                            style={{ marginTop: '20px' }}
                                        >
                                            {reports.map((report, index) => (
                                                <MenuItem key={index} value={report}>
                                                    {report}
                                                </MenuItem>
                                            ))}
                                        </Selector>
                                    }
                                </StepContent>
                            </Step>

                            {/* Additional steps can be added similarly */}

                            {isLoading &&
                                <LinearProgress variant="indeterminate" style={{ marginTop: '20px', height: '10px' }} />
                            }
                        </Stepper>
                    </DialogContent>
                </Dialog>
                :
                <Dialog
                    open={open}
                    title={'New Report from LinkedIn'}
                    icon={bot.icon}
                    onClose={onClose}
                    actions={
                        <>
                            <Button className={'button-grey small'} onClick={() => setNextStep(false)}>Back</Button>
                            <Button className={'button-purple small'} onClick={() => handleCreateBot()}> Create
                                Report </Button>
                        </>
                    }
                    style={{ width: '500px' }}

                >
                    <Box sx={{ padding: '12px 16px', fontSize: '14px', background: '#F9F9F9', borderRadius: '5px', color: '#635566' }} >
                        <Typography> data will be Scooped from <b>{selectedReport}</b></Typography>
                    </Box>
                    <Input
                        sx={{ width: '100%' }}
                        label={'Dataset name'}
                        onChange={(event) => {
                            setReportName(event.target.value)
                            setReportDescription(event.target.value) //name and description are same since label will be used for report name and description will be used for what the user entered
                        }}
                        value={reportName}
                    />
                    {/* <Input
                        sx={{ width: '100%' }}

                        multiline
                        rows={2}
                        label={'Dataset description (optional)'}
                        onChange={(event) => {
                            setReportDescription(event.target.value)
                        }}
                        value={reportDescription}
                    /> */}

                </Dialog>
            }
        </>

    );

};


