import React, {useEffect, useState, useMemo, useCallback} from "react";
import './Process.css'
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../api/api";
import { useApi2 } from "./api2";
import { useLocation } from 'react-router-dom';
import {
    Box,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    Tooltip,
    Stack,
    Chip,
    RadioGroup,
    Radio,
    Select, ListItemText, Drawer
} from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NewIcon from "@mui/icons-material/NoteAdd";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ExcelIcon from "@mui/icons-material/SimCardDownload";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
// If you want an icon for Refresh

import { Server } from "../../../api/Server";
import ProcessSelectionModal from "./ProcessSelectionModal";
import { FilterGenerator } from "./FilterGenerator";
import _, {debounce} from "lodash";
import GoDiagramWrapper from "./Go-diagram-wrapper/GoDiagramWrapper";
import Sliders from "./Sliders";
import LoadDiagram from "./LoadDiagram/LoadDiagram";
import SaveDiagram from "./SaveDiagram/SaveDiagram";
import { DateFilter } from "./DateFilter";
import DeleteDiagram from "./DeleteDiagram/DeleteDiagram";
import Filters from "./ProcessFilters/ProcessFilters";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ReactECharts from "echarts-for-react";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox } from "@mui/joy";
import { Toast } from "../../common/Toast/Toast";
import Typography from "@mui/material/Typography";
import processPlaceholder from "../../Objects/ProcessDiagram/process_diagram_skeleton.png";
import { ScoopLoader } from "../../common/Spinner/ScoopLoader";
import PaletteIcon from "@mui/icons-material/PaletteOutlined";
import Selector from "../../common/Selector/Selector";
import {ScoopTheme} from "../../Insights/Style";
import {ScoopColorPicker} from "../../common/ScoopColorPicker/ScoopColorPicker";
import {Slider} from "../../common/Slider/Slider";
import Button from "../../common/Button/Button";
import {renderColorPicker} from "../Explorer/DrawerTabs/Style/utils";

// optionally pass in the name of a diagram to load; if this exists, load it instead of showing normal dialog
export const Process = ({ embeddedDiagram, activePrompts, fromEdit }) => {

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-ui_information' : 'ui_information';
    const { postData: postUIInformationCall } = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);
    const { postData2: postUIInformationCall2 } = useApi2(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);
    const [server, setServer] = useState(new Server(workspaceID, userID, token));
    const [loadOpen, setLoadOpen] = useState(false);
    const [workspaceMetadata, setWorkspaceMetadata] = useState(null)
    const [showSelectionModal, setShowSelectionModal] = useState(false);
    const [openStyleDrawer, setOpenStyleDrawer] = useState(false);
    const [linkColor, setLinkColor] = useState('#314656');
    const [backgroundColor, setBackgroundColor] = useState(null);
    const [nodeColor, setNodeColor] = useState(null);
    const [linkOpacity, setLinkOpacity] = useState(0.2);
    const [linkOpacityValue, setLinkOpacityValue] = useState(0.2);
    const [selectedTheme, setSelectedTheme] = useState("None");
    const [sankeyKey, setSankeyKey] = useState('' + Math.random());


    //Prelim data describing all tables/columns diagrams can be pulled out of
    const [reportSeriesData, setReportSeriesData] = useState([]);
    const [preliminaryData, setPreliminaryData] = useState();
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [selectedSuccess, setSelectedSuccess] = useState(null);
    const [selectedReportSeriesTable, setSelectedReportSeriesTable] = useState(null);
    //filters
    const [dropdownFilter, setDropdownFilter] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    // eventually selectedFilters will be deprecated in favor od selectedFiltersV2
    // const [selectedFilters, setSelectedFilters] = useState([]);
    // Version 2 of the selected filters, now also containing the selectedColumn and selectedFilter for each
    const [selectedFiltersV2, setSelectedFiltersV2] = useState([]);
    const [isLoadedDiagram, setIsLoadedDiagram] = useState(false);
    const [loadedDiagramName, setLoadedDiagramName] = useState("");
    const [filterSnapshotDate, setFilterSnapshotDate] = useState(null);
    let [sankey, setSankey] = useState(false); // process diagram by default
    const [toFinalStage, setToFinalStage] = useState(false); // by default, info should show stats to get to next stage. If user toggles to true, show stats to final stage

    //for starters, set the weight threshold to be this value; the user can slide later
    const minPercentThreshold = 20;
    const minSupportThreshold = 20;
    const maxsupportThreshold = 100;

    // define bounds of nodes so fit perfectly on screen
    const [maxWidth, setMaxWidth] = useState(window.innerWidth - 100);
    const [maxHeight, setMaxHeight] = useState(window.innerHeight - 100);
    const [minTop, setMinTop] = useState(140);
    const [maxTop, setMaxTop] = useState(660);

    const [nodeDataArray, setNodeDataArray] = useState([]);
    const [originalNodeDataArray, setOriginalNodeDataArray] = useState([]);
    const [linkDataArray, setLinkDataArray] = useState([]);
    const [originalLinkDataArray, setOriginalLinkDataArray] = useState([]);

    const [weightThreshold, setWeightThreshold] = useState(minPercentThreshold);
    const [supportThreshold, setSupportThreshold] = useState(minSupportThreshold);
    const [showSourceView, setShowSourceView] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [loadDiagramModal, setLoadDiagramModal] = useState(false);
    const [saveDiagramModal, setSaveDiagramModal] = useState(false);
    const [filtersDiagramModal, setFiltersDiagramModal] = useState(false);
    const [deleteDiagramModal, setDeleteDiagramModal] = useState(false);
    const [dateFilterModal, setDateFilterModal] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [isSaveAsEnabled, setIsSaveAsEnabled] = useState(false);
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);

    const [filterButtonVisible, setFilterButtonVisible] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false)
    const location = useLocation();
    // if we're editing a diagram, load it <--this is called via a route from the diagram element on the canvas
    const editDiagram = location.state?.editDiagram;
    const editDiagramWorkspaceMetadata = location.state?.workspaceMetadata;

    // when a diagram is loaded, store its name here and that new:false; when the user creates a new diagram, store name here and new:true
    const [displayedDiagram, setDisplayedDiagram] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [sankeyOption, setSankeyOption] = useState({
        animation: false,
        series: {
            type: 'sankey',
            nodeAlign: 'right',
            layout: 'none',
            emphasis: {
                focus: 'trajectory'
            }
        }
    }
    );
    const [sankeyLoading, setSankeyLoading] = useState(false);
    const [stepBased, setStepBased] = useState(true);
    const [numSteps, setNumSteps] = useState(7);
    const [sankeyPeriod, setSankeyPeriod] = useState("Weekly");
    const [startingValues, setStartingValues] = React.useState([]);

    const [alert, setAlert] = useState(false)

    const opacityDebounce = useCallback(debounce((value) => {
        setLinkOpacity(value)
        setSankeyKey('' + Math.random())
    }, 200), [])

    useEffect(() => {
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer);
        setSelectedColumn(null);
        setSelectedSuccess(null);
        setSelectedReportSeriesTable(null);
        setReportSeriesData([]);
        setPreliminaryData([]);
        if (userID && token && workspaceID) {
            setIsLoading(true);
            newServer.postData({
                "action": "listProcesses",
                "workspaceID": workspaceID,
            }, ({ processes: data }) => setPreliminaryData(data));
            newServer.postData({
                "action": "getReportSeriesMetadata",
                "workspaceID": workspaceID,
                "userID": userID,
                "includeColumnMetadata": false
            }, ({ tables: data }) => setReportSeriesData(data));
            setIsLoading(false);
            newServer.postData({
                "action": "getWorkspaceMetadata",
            }, (data) => {
                setWorkspaceMetadata(data)
            });
        }
    }, [userID, token, workspaceID]);

    useEffect(() => {
        if (reportSeriesData && preliminaryData && (embeddedDiagram?.processDiagram != displayedDiagram || editDiagram)) {
            if (embeddedDiagram?.embedded) {
                loadDiagram(embeddedDiagram.processDiagram);
                setDisplayedDiagram(embeddedDiagram?.processDiagram);
            } else if (editDiagram) {
                if (!displayedDiagram) {
                    loadDiagram(editDiagram.processDiagram);
                    setDisplayedDiagram(editDiagram.processDiagram);
                }
            } else {
                setShowSelectionModal(true);
            }
        }
    }, [reportSeriesData, preliminaryData, embeddedDiagram]);

    useEffect(() => {
        if (loadedDiagramName) {
            setIsSaveEnabled(true);
            setIsDeleteEnabled(true);
            setIsSaveAsEnabled(true);
        } else {
            setIsSaveEnabled(false);
            setIsDeleteEnabled(false);
        }
    }, [loadedDiagramName]);

    useEffect(() => {
        if (selectedTheme && selectedTheme.colorScheme && !backgroundColor) {
            setBackgroundColor(selectedTheme.colorScheme.backgroundColor)
        }
    }, [selectedTheme]);

    useEffect(() => {
        if (selectedTheme && selectedTheme.colorScheme && !nodeColor) {
            setNodeColor(selectedTheme.colorScheme.backgroundColor)
        }
    }, [selectedTheme]);

    const onCloseDiagramModal = () => {
        setShowSelectionModal(false);
    }

    const onCloseLoadDiagramModal = () => {
        setLoadDiagramModal(false);
    }

    const onCloseSaveDiagramModal = () => {
        setSaveDiagramModal(false);
    }

    const onCloseDeleteDiagramModal = () => {
        setDeleteDiagramModal(false);
    }

    const onCloseFiltersDiagramModal = () => {
        setFiltersDiagramModal(false);
    }

    const onApplyFilters = () => {
        setFiltersDiagramModal(false);
        fetchData();
    }

    const onCloseDateFilter = () => {
        setDateFilterModal(false);
    }

    // arrange nodes within a given window -- called from multiple places
    function fitNodesToWindow(nodes, width, height) {
        const nodeSize = 140;  // Assuming nodes are 140x140 units in size
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

        // Find the current bounds
        nodes?.forEach(node => {
            let x, y;
            if (typeof node.loc === 'string') {
                [x, y] = node.loc.split(' ').map(Number);
            } else {
                ({ x, y } = node.loc);
            }
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x + nodeSize);  // Add node size to x to consider its full width
            maxY = Math.max(maxY, y + nodeSize);  // Add node size to y to consider its full height
        });

        // Calculate scale factors and center offset
        const scaleX = .8 * width / (maxX - minX);
        const scaleY = .8 * height / (maxY - minY);
        const offsetX = (maxX + minX) / 2;
        const offsetY = (maxY + minY) / 2;

        // Normalize, scale, and offset node positions
        return nodes?.map(node => {
            let x, y;
            if (typeof node.loc === 'string') {
                [x, y] = node.loc.split(' ').map(Number);
            } else {
                ({ x, y } = node.loc);
            }
            return {
                ...node,
                loc: `${((x - offsetX) * scaleX)} ${((y - offsetY) * scaleY)}`
            };
        });
    }

    useEffect(() => {
        if (embeddedDiagram && embeddedDiagram.embedded != null) {
            setMaxWidth(embeddedDiagram.width);
            setMaxHeight(embeddedDiagram.height);

            const scaledNodes = fitNodesToWindow(nodeDataArray, embeddedDiagram.width, embeddedDiagram.height);
            const updatedNodeDataArray = nodeDataArray?.map((node, index) => {
                const newLoc = scaledNodes[index].loc;
                return {
                    ...node,
                    loc: newLoc
                };
            });
            setNodeDataArray(updatedNodeDataArray);

            const scaleOrigNodes = fitNodesToWindow(originalNodeDataArray, embeddedDiagram.width, embeddedDiagram.height);
            const updatedOriginalNodeDataArray = originalNodeDataArray?.map((node, index) => {
                const newLoc = scaleOrigNodes[index];
                const loc = typeof node.loc === 'string' ? node.loc : `${newLoc.x} ${newLoc.y}`;
                return {
                    ...node,
                    loc: loc
                };
            });
            setOriginalNodeDataArray(updatedOriginalNodeDataArray);
        }
    }, [embeddedDiagram?.width, embeddedDiagram?.height, isLoading]);

    const resetState = async () => {
        console.log("**state resetting")
        setNodeDataArray([]);
        setOriginalNodeDataArray([]);
        setLinkDataArray([]);
        setOriginalLinkDataArray([]);
        setWeightThreshold(minPercentThreshold);
        setSupportThreshold(minSupportThreshold);
        setShowSourceView(false);
        setFilterModal(false);
        setFilterButtonVisible(false);
        setStartDate(null);
        setEndDate(null);
        //setPreliminaryData();
        setDropdownFilter([]);
        setSelectedFilter(null);
        // setSelectedFilters([]);
        // setSelectedFilters2([]);
        // setSnapshotDiagrams([]);
        setFilterSnapshotDate(null);
        setLoadedDiagramName("");
        setToFinalStage(false);
        setDisplayedDiagram(null)
    };

    const fetchNewDiagram = async (nodes = selectedNodes) => {
        await resetState();

        setShowSelectionModal(false);
        setIsLoading(true);

        //first, reset everything diagram-related to nulls
        setNodeDataArray([]);
        setLinkDataArray([]);

        let JSONBody = {
            action: "getProcessInsightDiagram",
            reportSeriesTableID: selectedReportSeriesTable,
            workspaceID: workspaceID,
            attribute: selectedColumn,
            filter: FilterGenerator(selectedFiltersV2, filterSnapshotDate, selectedReportSeriesTable, reportSeriesData),
            successValue: selectedSuccess,
            startDate: startDate,
            endDate: endDate,
            step: toFinalStage ? "final" : "next",
        };

        //add to JSONBody "from" and "to" if those exist
        let fromTo = returnFromTo(_.sortBy(nodes, "order"));
        if (fromTo) {
            JSONBody.from = fromTo.from;
            JSONBody.to = fromTo.to;
        }

        await server.postData(JSONBody, (data) => {
            if (data.error) {
                setAlert(data.error)
                setIsLoading(false)
                setIsSaveEnabled(false)
                setIsSaveAsEnabled(false)
                return
            }
            if (data.nodeDataArray) {
                // Create a temporary array to store updated nodes
                const updatedNodeDataArray = data.nodeDataArray
                    ?.map((n, counter) => {

                        return {
                            key: n.key,
                            node: n.node,
                            value: n.value,
                            isChecked: true,
                            order: counter,
                            selectedSuccess: selectedSuccess,
                            duration: n.duration || null,
                            loc: `${n.x} ${n.y}`,
                        };
                    });

                // Scale the nodes to fit the window
                const scaledNodes = fitNodesToWindow(updatedNodeDataArray, maxWidth, maxHeight);

                // Update the locations of the nodes
                const scaledNodeDataArray = updatedNodeDataArray
                    ?.map((node, index) => {
                        return {
                            ...node, loc: scaledNodes[index].loc
                        };
                    });

                // Update state with the newly scaled nodes

                setNodeDataArray([...scaledNodeDataArray]);


                setOriginalNodeDataArray([...scaledNodeDataArray]);
                setLinkDataArray([...data.linkDataArray]);

                setOriginalLinkDataArray([...data.linkDataArray]);
                setFilterButtonVisible(true);
                displayLinksAboveThreshold(weightThreshold, supportThreshold, [...data.linkDataArray]);
                // setIsLoadedDiagram(false);
                // setLoadedDiagramName("");
                setIsLoading(false);
                setAlert(false);
                setIsSaveAsEnabled(true);

                sankey && fetchSankey(stepBased, numSteps, sankeyPeriod, startingValues);
            }
        });
    }
    const fetchData = async (nodes = selectedNodes, keepCoordinates = true, start, end) => {
        setIsLoading(true);
        setShowSelectionModal(false);
        setError(false);
        const clonedExistingNodes = _.cloneDeep(nodeDataArray.length === 0 ?
            originalNodeDataArray.filter(n => n.isChecked) : nodeDataArray);
        //first, reset everything diagram-related to nulls
        setNodeDataArray([]);
        setLinkDataArray([]);
        const from = start ? start : startDate;
        const to = end ? end : endDate;

        const nodeDataArrayTemp = [];
        let JSONBody = {
            action: "getProcessInsightDiagram",
            reportSeriesTableID: selectedReportSeriesTable,
            workspaceID: workspaceID,
            attribute: selectedColumn,
            filter: FilterGenerator(selectedFiltersV2, filterSnapshotDate, selectedReportSeriesTable, reportSeriesData),
            successValue: selectedSuccess,
            startDate: from,
            endDate: to,
            step: toFinalStage ? "final" : "next",
        };

        //add to JSONBody "from" and "to" if those exist
        let fromTo = returnFromTo(_.sortBy(nodes, "order"));
        if (fromTo) {
            JSONBody.from = fromTo.from;
            JSONBody.to = fromTo.to;
        }

        await server.postData(JSONBody, (data) => {
            if (data.nodeDataArray) {
                const nodeKeyMap = new Map();
                data.nodeDataArray.forEach((node) => {
                    const existing = originalNodeDataArray.find((x) => x.node === node.node);
                    if (existing) {
                        nodeKeyMap.set(node.key, existing.key);
                    }
                });
                const updatedNodeDataArray = clonedExistingNodes?.map((n) => {
                    const existingNode = data.nodeDataArray.find((x) => x.node === n.node);
                    if (existingNode) {
                        return {
                            key: n.key,
                            node: existingNode.node,
                            value: existingNode.value,
                            isChecked: true,
                            order: n.order || undefined,
                            selectedSuccess: existingNode.selectedSuccess,
                            duration: n.duration || null,
                            loc: `${n.loc.x} ${n.loc.y}`,
                            __gohashid: n.__gohashid
                        };
                    } else {
                        return n
                    }
                })
                const updatedLinkDataArray = data.linkDataArray
                    ?.map((l) => {
                        const fromKey = nodeKeyMap.get(l.from);
                        const toKey = nodeKeyMap.get(l.to);
                        if (fromKey !== undefined && toKey !== undefined) {
                            const existingLink = originalLinkDataArray.find((x) => x.from === fromKey && x.to === toKey);
                            if (existingLink !== undefined) {
                                return {
                                    ...l,
                                    from: fromKey,
                                    to: toKey,
                                }
                            } else {
                                return null
                            }
                        } else {
                            return null
                        }
                    })
                    .filter((x) => x !== null);
                // Scale the nodes to fit the window
                //const scaledNodes = fitNodesToWindow(updatedNodeDataArray, maxWidth, maxHeight);
                //console.log('scaledNodes: ', scaledNodes)
                // Update the locations of the nodes
                /*const scaledNodeDataArray = updatedNodeDataArray
                    .map((node, index) => {
                        const newLoc = scaledNodes[index];
                        return {
                            ...node, loc: new window.go.Point(newLoc.x, newLoc.y)
                        };
                    });*/
                // Update state with the newly scaled nodes
                //console.log('scaledNodeDataArray: ', ...data.nodeDataArray)
                setNodeDataArray([...updatedNodeDataArray]);
                setOriginalLinkDataArray([...updatedLinkDataArray])
                // Do not change the original data if set already!
                if (originalNodeDataArray.length === 0) {
                    setOriginalNodeDataArray([...data.nodeDataArray]);
                }
                //setLinkDataArray([...data.linkDataArray]);
                // Do not change the original data if set already!

                setFilterButtonVisible(true);
                displayLinksAboveThreshold(weightThreshold, supportThreshold, updatedLinkDataArray);
                // setIsLoadedDiagram(false);
                // setLoadedDiagramName("");
                setIsLoading(false);
            } else {
                setError(true);
                setIsLoading(false);
            }
        });
    }

    const fetchSankey = async (isStepBased, numberOfSteps, sankeyPeriod, allowedStartingValues, result) => {
        if (result) {
            setSankeyLoading(true)
            const toFinal = result.step !== 'undefined' ? result.step === 'final' : false
            let JSONBody = {
                action: "getSankey",
                reportSeriesTableID: result.selectedReportSeriesTable,
                workspaceID: workspaceID,
                attribute: result.selectedColumn,
                filter: FilterGenerator(
                    result.selectedFiltersV2,
                    result.filterSnapshotDate !== 'undefined' ? result.filterSnapshotDate : undefined,
                    result.selectedReportSeriesTable,
                    result.reportSeriesData
                ),
                successValue: result.selectedSuccess,
                startDate: result.startDate,
                endDate: result.endDate,
                step: toFinal ? "final" : "next",
                stepBased: isStepBased,
                sankeyPeriod: sankeyPeriod,
                numSteps: numberOfSteps
            };
            if (allowedStartingValues && allowedStartingValues.length > 0) {
                JSONBody.startingValues = allowedStartingValues;
            }
            await server.postData(JSONBody, (result) => {
                if (result?.data) {
                    const newSankeyOption = { ...sankeyOption };
                    newSankeyOption.series = { ...newSankeyOption.series };
                    newSankeyOption.series.links = result.links;
                    newSankeyOption.series.data = result.data;
                    setSankeyOption(newSankeyOption);
                    setSankeyLoading(false)
                }
            });
        }
        if (selectedColumn && selectedSuccess && selectedReportSeriesTable) {
            setSankeyLoading(true)
            let JSONBody = {
                action: "getSankey",
                reportSeriesTableID: selectedReportSeriesTable,
                workspaceID: workspaceID,
                attribute: selectedColumn,
                filter: FilterGenerator(selectedFiltersV2, filterSnapshotDate, selectedReportSeriesTable, reportSeriesData),
                successValue: selectedSuccess,
                startDate: startDate,
                endDate: endDate,
                step: toFinalStage ? "final" : "next",
                stepBased: isStepBased,
                sankeyPeriod: sankeyPeriod,
                numSteps: numberOfSteps
            };
            if (allowedStartingValues && allowedStartingValues.length > 0) {
                JSONBody.startingValues = allowedStartingValues;
            }
            await server.postData(JSONBody, (result) => {
                if (result?.data) {
                    const newSankeyOption = { ...sankeyOption };
                    newSankeyOption.series = { ...newSankeyOption.series };
                    newSankeyOption.series.links = result.links;
                    newSankeyOption.series.data = result.data;
                    setSankeyOption(newSankeyOption);
                    setSankeyLoading(false)
                }
            });
        }
    }

    // call to only display links that are above a threshold, both for liveValue and lieWeight, respectively
    const displayLinksAboveThreshold = (weightThreshold, supportThreshold, linksData = originalLinkDataArray) => {
        //make fraction 0..1
        const t = weightThreshold / 100;
        const sp = supportThreshold / 100;
        console.log("*displayLinksAboveThreshold, linksData: ", linksData)
        const filterLinkData = _.cloneDeep(linksData);
        linksData.forEach((line) => {
            if (line.lineWeight < t || line.supportPercentile < sp) {
                const index = filterLinkData.findIndex((x) => _.isEqual(x, line));
                if (index !== -1) {
                    filterLinkData.splice(index, 1);
                }
            }
        });

        linksData.forEach((l) => {
            if (l.lineWeight >= t && l.supportPercentile >= sp) {
                if (!doesLinkExist(l, filterLinkData)) {
                    filterLinkData.push(l);
                }
            }
        });
        setLinkDataArray([...filterLinkData]);
        setWeightThreshold(weightThreshold);
        setSupportThreshold(supportThreshold);
    };

    //compare passed in l with the diagram.model.linkDataArray and return true if link exists
    const doesLinkExist = (l, linkDataArray) => {
        const isFound = linkDataArray.filter((d) => d.from === l.from && d.to === l.to);
        return isFound.length > 0;
    };

    const returnFromTo = (selectedNodes) => {
        let toStages = [];
        selectedNodes?.map((s, index) => {
            if (index !== 0) {
                toStages?.push(s?.node);
            }
        });

        let ret = null;

        if (toStages.length > 0) ret = { from: [selectedNodes[0].node], to: toStages };

        return ret;
    };

    const selectedNodes = useMemo(() => {
        return _.sortBy(nodeDataArray.filter((x) => x.isSelected === true), "order");
    }, []);

    const handleDiagramEvent = (event) => {
        const selectedNode = event.subject.first();

        if (!selectedNode) return;

        // Update node location with go.Point
        let updatedLocation = selectedNode.location.copy();
        updatedLocation = `${updatedLocation.x} ${updatedLocation.y}`

        const data = _.cloneDeep(nodeDataArray);
        const originalData = _.cloneDeep(originalNodeDataArray);

        // Grab Indexes
        const existingNodeIndex = data.findIndex((x) => x.key === selectedNode.key);
        const originalExistingNodeIndex = originalData.findIndex((x) => x.key === selectedNode.key);

        // Update Node location
        if (existingNodeIndex !== -1) {
            data[existingNodeIndex].loc = updatedLocation;
            setNodeDataArray([...data]);
        }

        if (originalExistingNodeIndex !== -1) {
            originalData[originalExistingNodeIndex].loc = updatedLocation;
            setOriginalNodeDataArray([...originalData]);
        }
    };

    // the users selected nodes to show/hide, so update displayed nodes based on that
    const nodesSelectionTriggered = (node) => {
        const nodes = [];
        const originalNodes = _.cloneDeep(originalNodeDataArray);
        const filterLinkData = _.cloneDeep(linkDataArray);
        const toDeleteIndex = originalNodes.findIndex((x) => x.node === node.node);

        if (toDeleteIndex !== -1) {
            originalNodes[toDeleteIndex].isChecked = !originalNodes[toDeleteIndex].isChecked;
        }

        var nodeKeysToKeep = originalNodes
            .filter((x) => x.isChecked === true)
            .map((x) => x.key);

        var nodeKeysToDelete = originalNodes
            .filter((x) => x.isChecked === false)
            .map((x) => x.key);

        originalNodes.forEach((n) => {
            if (!nodeKeysToKeep.includes(n.key)) {
                const index = nodes.findIndex((x) => _.isEqual(x, n));
                if (index !== -1) {
                    nodes.splice(index, 1);
                }
            }
        });

        // //add nodes that are in the list of nodes to keep
        originalNodes.forEach((n) => {
            if (nodeKeysToKeep.includes(n.key)) {
                nodes.push(n);
            }
        });

        // //remove all links that don't touch the nodes to keep (note: no idea why need to run this a bunch of times for all to take effect, but need to)
        linkDataArray.forEach((l) => {
            if (nodeKeysToDelete.includes(l.from) || nodeKeysToDelete.includes(l.to)) {
                const index = filterLinkData.findIndex((x) => _.isEqual(x, l));
                if (index !== -1) {
                    console.log(">>removing links: ", l.from, l.to)
                    filterLinkData.splice(index, 1);
                }
            }
        });

        originalLinkDataArray.forEach((l) => {
            if (!nodeKeysToDelete.includes(l.from) && !nodeKeysToDelete.includes(l.to)) {
                if (!doesLinkExist(l, filterLinkData)) {
                    filterLinkData.push(l);
                }
            }
        });

        setNodeDataArray([...nodes]);
        setOriginalNodeDataArray([...originalNodes]);
        displayLinksAboveThreshold(weightThreshold, supportThreshold, filterLinkData);
    };
    //load a diagram
    const loadDiagram = async (diagramName) => {
        setIsLoading(true);
        const JSONBody = {
            action: "getProcessDiagramPreferences",
            userID: userID,
            diagramName: diagramName,
            workspaceID: workspaceID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        const result = await postUIInformationCall(JSONBody);

        if (result) {
            // Assuming embeddedDiagram is available in the scope
            if (embeddedDiagram?.embedded) {

                const scaledNodes = fitNodesToWindow(result.body.json.nodeDataArray, maxWidth, maxHeight);
                const nodeDataArray = result.body.json.nodeDataArray?.map((node, index) => {
                    const newLoc = scaledNodes[index].loc;
                    return {
                        ...node,
                        loc: newLoc
                    };
                });
                setNodeDataArray(nodeDataArray);

                const scaleOrigNodes = fitNodesToWindow(result.body.json.originalNodeDataArray, maxWidth, maxHeight);
                const originalNodeDataArray = result.body.json.originalNodeDataArray?.map((node, index) => {
                    const newLoc = scaleOrigNodes[index].loc;
                    return {
                        ...node,
                        loc: newLoc
                    };
                });
                setOriginalNodeDataArray(originalNodeDataArray);

            } else {
                // Original code for when embeddedDiagram.embedded is not true
                const nodeDataArray = result.body.json.nodeDataArray?.map((x) => {
                    const loc = typeof x.loc === 'string' ? x.loc : `${x.loc.x} ${x.loc.y}`;
                    return { ...x, loc };
                });
                setNodeDataArray(nodeDataArray);
                const originalNodeDataArray = result.body.json.originalNodeDataArray?.map((x) => {
                    const loc = typeof x.loc === 'string' ? x.loc : `${x.loc.x} ${x.loc.y}`;
                    return { ...x, loc };
                });
                setOriginalNodeDataArray(originalNodeDataArray);
            }
            setLinkDataArray(result.body.json.linkDataArray);
            setOriginalLinkDataArray(result.body.json.originalLinkDataArray);
            setWeightThreshold(result.body.json.weightThreshold);
            setSupportThreshold(result.body.json.supportThreshold);
            setShowSourceView(result.body.json.showSourceView);
            setFilterButtonVisible(result.body.json.filterButtonVisible);
            setStartDate(result.body.json.startDate);
            setEndDate(result.body.json.endDate);
            setSelectedSuccess(result.body.json.selectedSuccess);
            setSelectedColumn(result.body.json.selectedColumn);
            setSelectedReportSeriesTable(result.body.json.selectedReportSeriesTable);
            setDropdownFilter(result.body.json.dropdownFilter);
            setSelectedFilter(result.body.json.selectedFilter);
            setSelectedFiltersV2(result.body.json.selectedFiltersV2);
            !sankey && setSankey(result.body.json.sankey ? result.body.json.sankey : false);
            setLinkColor(result.body.json.linkColor ?? '#314656');
            setBackgroundColor(result.body.json.backgroundColor);
            setNodeColor(result.body.json.nodeColor);
            setLinkOpacity(result.body.json.linkOpacity ?? 0.2);
            setLinkOpacityValue(result.body.json.linkOpacity ?? 0.2);
            setStepBased(result.body.json.stepBased ? result.body.json.stepBased : true);
            setNumSteps(result.body.json.numSteps ?? 7);
            setSankeyPeriod(result.body.json.sankeyPeriod || "Weekly");
            setStartingValues(result.body.json.startingValues || []);
            if (embeddedDiagram) setSelectedTheme(embeddedDiagram?.workspaceMetadata?.themes?.find((x) => x.themeID === result.body.json.themeID));
            if (!embeddedDiagram && !embeddedDiagram?.embedded) setSelectedTheme(workspaceMetadata?.themes?.find((x) => x.themeID === result.body.json.themeID));
            if (editDiagram && editDiagramWorkspaceMetadata) setSelectedTheme(editDiagramWorkspaceMetadata?.themes?.find((x) => x.themeID === result.body.json.themeID));

            if (typeof result.body.json.filterSnapshotDate !== "undefined") {
                setFilterSnapshotDate(result.body.json.filterSnapshotDate)
            }

            if (typeof result.body.json.step !== "undefined") {
                let step = result.body.json.step === "final";
                setToFinalStage(step)
            }
            if (result.body.json.sankey || sankey) {
                fetchSankey(
                    !!result.body.json.stepBased,
                    result.body.json.numSteps ?? 7,
                    result.body.json.sankeyPeriod || "Weekly",
                    result.body.json.startingValues || [],
                    result.body.json
                ).then(() => {
                    setIsLoadedDiagram(true);
                    setLoadedDiagramName(diagramName);
                    setLoadDiagramModal(false);
                    setError(false);
                })
            } else {
                setIsLoadedDiagram(true);
                setLoadedDiagramName(diagramName);
                setLoadDiagramModal(false);
                setError(false);
            }
        }
        setIsLoading(false);
    };

    // download diagram as spreadsheet
    const exportSpreadsheet = (nodes = selectedNodes, // filters = selectedFilters,
        keepCoordinates = false) => {
        try {
            let JSONBody = {
                action: "getProcessInsightDiagram",
                reportSeriesTableID: selectedReportSeriesTable,
                workspaceID: workspaceID,
                attribute: selectedColumn,
                filter: FilterGenerator(selectedFiltersV2, filterSnapshotDate, selectedReportSeriesTable, reportSeriesData),
                successValue: selectedSuccess,
                startDate: startDate,
                endDate: endDate,
                excel: true,
                diagramName: loadedDiagramName,
            };
            //add to JSONBody "from" and "to" if those exist
            let fromTo = returnFromTo(_.sortBy(nodes, "order"));
            if (fromTo) {
                JSONBody.from = fromTo.from;
                JSONBody.to = fromTo.to;
            }

            setIsLoading(true);
            server.postDataURL(JSONBody).then((data) => {
                window.open(data, "_blank");
                setIsLoading(false)
                onCloseDiagramModal();
            })
        } catch (error) {
            console.log("error loading Excel: ", error)
        }

    }
    const diagramSavedAs = (diagramName) => {
        setLoadedDiagramName(diagramName);
        saveDiagram(diagramName);
    }
    // save the diagram
    const saveDiagram = async (diagramName) => {
        setIsLoading(true);
        const version = "2.0"

        // let selectedFilters = selectedFiltersV2
        const JSONBody = {
            action: "saveProcessDiagramPreferences",
            userID: userID,
            diagramName: diagramName,
            workspaceID: workspaceID,
            json: {
                version,
                nodeDataArray,
                originalNodeDataArray,
                linkDataArray,
                originalLinkDataArray,
                weightThreshold,
                supportThreshold,
                showSourceView,
                filterButtonVisible,
                startDate,
                endDate,
                selectedSuccess,
                selectedColumn,
                selectedReportSeriesTable,
                dropdownFilter,
                selectedFilter,
                selectedFiltersV2,
                filterSnapshotDate,
                sankey,
                stepBased,
                numSteps,
                sankeyPeriod,
                startingValues,
                step: toFinalStage ? "final" : "next",
                themeID: selectedTheme?.themeID,
                linkColor,
                backgroundColor,
                nodeColor,
                linkOpacity
            },
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        try {
            const result = await postUIInformationCall(JSONBody);
            if (result.statusCode === 200 && result.body === '{"status":"success","message":"diagram preferences saved"}') {
                // console.log(result.body)
                showSnackbar("Diagram saved successfully!", "success");
            } else {
                showSnackbar("Error saving diagram :(", "error");
            }
        } catch (error) {
            console.log("error! ", error)
            showSnackbar("Error saving diagram :(", "error");
        }
        onCloseLoadDiagramModal();
        onCloseDiagramModal();
        onCloseSaveDiagramModal();
        setLoadedDiagramName(diagramName);

        setIsLoading(false);

    };

    // delete the diagram
    const doDeleteDiagram = async (diagramName) => {

        setIsLoading(true);

        const JSONBody = {
            action: "deleteProcessDiagramPreferences",
            userID: userID,
            diagramName: diagramName,
            workspaceID: workspaceID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }


        try {
            const result = await postUIInformationCall(JSONBody);
            if (result.statusCode === 200) {
                // console.log(result.body)
                showSnackbar("Diagram deleted successfully!", "success");
            } else {
                showSnackbar("Error deleting diagram :(", "error");
            }
        } catch (error) {
            console.log("error! ", error)
            showSnackbar("Error deleting diagram :(", "error");
        }

        resetState();

        setIsLoading(false);

    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleToFinalStage = async () => {
        setToFinalStage(prevState => !prevState);
        await fetchData();
    };

    // TO-DO uncomment
    /*    useEffect(() => {
            const fetch = async () => {
                await fetchData()
            }
            if (nodeDataArray.length > 0) {
                console.log('4')
                fetch();
            }
        }, [toFinalStage, startDate, endDate]);*/

    const handleApplyDateChange = async (start, end) => {
        await fetchData(selectedNodes, true, start, end);
        onCloseDateFilter();
    }

    const genCheckboxRow = (node) => {
        return (<div
            className="attributes-item-container" // Use class name directly
            key={`NODES-ATTRIBS-${node.node}`}
            style={{
                background: "#7442D7",
            }}
        >
            <div
                className="label-container" // Use class name directly
                onClick={() => nodesSelectionTriggered(node)}
            >
                {node.isChecked && (<div
                    className="dot" // Use class name directly
                    style={{ background: "pink" }}
                />)}
                <span className="node-text">{node.node}</span>

            </div>
        </div>);
    };

    const getSankeyTheme = () => {
        if (selectedTheme?.colorScheme) {
            const temp = {...ScoopTheme}
            temp.color = selectedTheme.colorScheme.colors.map(c => c.val)
            temp.backgroundColor = selectedTheme.colorScheme.backgroundColor
            return temp
        } else {
            return ScoopTheme
        }
    }

    const getOptionWithStyles = () => {
        if (sankeyOption?.series?.links) {
            const temp = {
                ...sankeyOption,
                backgroundColor: backgroundColor,
            }
            temp.series.links.forEach((link, i) => {
                temp.series.links[i] = {...link, lineStyle: {color: linkColor, opacity: linkOpacity }}
            })
            return temp
        }
        return {
            animation: false,
            backgroundColor: backgroundColor,
            series: {
                type: 'sankey',
                nodeAlign: 'right',
                layout: 'none',
                emphasis: {
                    focus: 'trajectory'
                }
            }
        }
    }

    const handleOpacityDebounce = (value) => {
        setLinkOpacityValue(value)
        opacityDebounce(value)
    }

    const chartSetting = {
        height: window.innerHeight - 200 + "px",
        marginLeft: 50,
        marginRight: 50,
        pointerEvents: 'all'
    };

    if (embeddedDiagram?.embedded) {
        return (
            <Box className={'processdiagram-content'}>
                {
                    sankey ?
                        (
                            sankeyLoading ?
                                <Box sx={{ height: '100%', width: '100%', display: 'grid', placeContent: 'center' }}>
                                    <ScoopLoader />
                                </Box> :
                                <ReactECharts
                                    option={getOptionWithStyles()}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ ...chartSetting, pointerEvents: embeddedDiagram.clickable ? 'all' : 'none' }}
                                    theme={getSankeyTheme()}
                                />
                        ) :
                        (
                            /*isLoading ?
                            <Box sx={{height: '100%', width: '100%', display: 'grid', placeContent: 'center'}}>
                                <ScoopLoader />
                            </Box> :*/
                            <GoDiagramWrapper
                                key={[selectedTheme, nodeColor]}
                                theme={selectedTheme}
                                nodeDataArray={nodeDataArray}
                                originalNodeDataArray={originalNodeDataArray}
                                linkDataArray={linkDataArray}
                                setNodeDataArray={setNodeDataArray}
                                handleDiagramEvent={handleDiagramEvent}
                                backgroundColor={backgroundColor}
                                nodeColor={nodeColor}
                            />
                        )
                }
            </Box>
        );
    }

    let columnList = null;
    if (preliminaryData) {
        for (var i = 0; i < preliminaryData.length; i++) {
            if (preliminaryData[i].reportSeriesTableID === selectedReportSeriesTable) {
                for (var j = 0; j < preliminaryData[i].changeColumns.length; j++) {
                    if (preliminaryData[i].changeColumns[j].columnName === selectedColumn) {
                        columnList = preliminaryData[i].changeColumns[j].values;
                        break;
                    }
                }
                break;
            }
        }
    }

    return (<Box className={'screen-container'}>
        <Box sx={{ minHeight: '56px' }} className={'processdiagram-nav'}>
            {/* LinearProgress bar at the bottom of the Box */}
            {/*            {isLoading && <Box sx={{width: '100%', position: 'absolute', top: 110, left: 0}}>
                <LinearProgress variant="indeterminate"/>
            </Box>}*/}
            <span>Process Analysis</span>
            {loadedDiagramName}
            <Tooltip
                title="View process analysis as a diagram or a Sankey chart"
                sx={{ ml: 3 }}>
                <RadioGroup row value={sankey} onChange={() => {
                    if (!sankey) {
                        fetchSankey(stepBased, numSteps, sankeyPeriod, startingValues);
                    }
                    setSankey(!sankey)
                }}>
                    <FormControlLabel
                        control={<Radio />} value={false}
                        label="Process Diagram " />
                    <FormControlLabel
                        control={<Radio />} value={true}
                        label="Sankey Chart" />
                </RadioGroup>
            </Tooltip>
            <Box className={'processdiagram-nav-actions'}>
                <Box onClick={() => setOpenStyleDrawer(true)} sx={{paddingTop: '4px', height: '30px', cursor: 'pointer'}}>
                    <Tooltip title="Change diagram theme">
                        <PaletteIcon fontSize={"medium"}/>
                    </Tooltip>
                </Box>
                <Box>
                    {isSaveEnabled && (
                        <Tooltip sx={{ padding: "4px 2px" }} title="Save Current Diagram">
                            <IconButton
                                onClick={() => {
                                    saveDiagram(loadedDiagramName);
                                }}
                            >
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isSaveAsEnabled && (
                        <Tooltip title="Save As">
                            <IconButton sx={{ padding: "4px 2px" }} onClick={() => {
                                setSaveDiagramModal(true);
                            }}>
                                <SaveAsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                <Box>
                    <Tooltip sx={{ padding: 0 }} title="New Diagram">
                        <IconButton onClick={() => {
                            setShowSelectionModal(true);
                        }}>
                            <NewIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip sx={{ padding: 0 }} title="Open Saved Diagram">
                        <IconButton onClick={() => {
                            setLoadDiagramModal(true);
                        }}>
                            <FileOpenIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {isDeleteEnabled && (<Tooltip title="Delete Diagram">
                    <IconButton sx={{ padding: "4px 2px" }} onClick={() => {
                        setDeleteDiagramModal(true);
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>)}
                <Box>
                    <Tooltip sx={{ padding: "4px 2px" }} title="Choose a Date Range">
                        <IconButton onClick={() => {
                            setDateFilterModal(true);
                        }}>
                            <CalendarIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip sx={{ padding: "4px 2px" }} title="Filter Diagram">
                        <IconButton onClick={() => {
                            setFiltersDiagramModal(true);
                        }}>
                            <FilterIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Tooltip title="Export to Excel">
                    <IconButton sx={{ padding: "4px 2px", marginRight: sankey ? "207.5px" : 0 }} onClick={() => {
                        exportSpreadsheet();
                    }}>
                        <ExcelIcon />
                    </IconButton>
                </Tooltip>
                {!sankey &&
                    <Tooltip
                        hidden={!sankey}
                        title="By default, stats are between stages. Toggle to ON to see stats on each stage to final stage."
                        sx={{ ml: 3 }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={toFinalStage}
                                        onChange={handleToFinalStage}
                                    />
                                }
                                label="To final stage"
                            />
                        </FormGroup>
                    </Tooltip>
                }
            </Box>
            <ProcessSelectionModal
                isOpen={showSelectionModal}
                closeModal={onCloseDiagramModal}
                fetchNewDiagram={fetchNewDiagram}
                preliminaryData={preliminaryData}
                reportSeriesData={reportSeriesData}
                setSelectedFilter={setSelectedFilter}
                selectedReportSeriesTable={selectedReportSeriesTable}
                setSelectedReportSeriesTable={setSelectedReportSeriesTable}
                setDropdownFilter={setDropdownFilter}
                setSelectedSuccess={setSelectedSuccess}
                setSelectedColumn={setSelectedColumn}
                selectedColumn={selectedColumn}
                selectedSuccess={selectedSuccess}
            // onOpenSelectionOverlay={onOpenSelectionOverlay}
            // openLoadDiagramModal={onOpenLoadDiagramModal}
            // filterButtonVisible={filterButtonVisible}
            />

        </Box>
        {
            error &&
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 100,
                alignSelf: 'center',
                backgroundColor: 'rgb(249, 249, 249)',
                width: '100%',
                height: '82%',
            }}
            >
                <Typography sx={{ color: 'red' }}>
                    No diagram found with specified conditions
                </Typography>
            </Box>
        }
        {!sankey && !isLoading && nodeDataArray.length === 0 && !isSaveAsEnabled &&
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 100,
                alignSelf: 'center',
                backgroundColor: 'rgb(249, 249, 249)',
                width: '100%',
                height: '82%',
            }}
            >
                <img src={processPlaceholder} alt={'chart'} />
                <Typography sx={{ color: '#979099' }}>
                    Create a new Diagram or load an existing one
                </Typography>
            </Box>
        }
        {isLoading &&
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 100,
                alignSelf: 'center',
                backgroundColor: 'rgb(249, 249, 249)',
                width: '100%',
                height: '82%',
            }}
            >
                <ScoopLoader size={72} />
            </Box>
        }
        {selectedNodes.length > 0 && !sankey && (
            <Box
                sx={{
                    position: 'absolute', top: '100px', left: '0px', zIndex: 1000, backgroundColor: '#592F64', // Generic color
                    padding: 2, width: '100%', // borderRadius: '5px',
                }}
            >
                <Stack direction="row" spacing={2}>
                    <Chip
                        label={`From: ${selectedNodes[0]?.node}`}
                        onDelete={() => fetchData([])}
                        color="primary" // MUI's default color
                        variant="filled"
                        deleteIcon={<CloseIcon />}
                    />
                    <ArrowForwardIcon sx={{ width: 24, height: 24, paddingTop: '5px', color: 'white' }} />
                    {selectedNodes.map((x, index) => index === 0 ? null : (
                        <Chip
                            key={index}
                            label={`To: ${x?.node}`}
                            onDelete={() => {
                                // Your swap logic here
                            }}
                            color="secondary" // MUI's default color
                            variant="filled"
                            deleteIcon={<SwapHorizIcon />}
                        />
                    ))}
                    {selectedNodes.length > 1 && (<Chip
                        label="Refresh"
                        onClick={() => fetchData()}
                        sx={{
                            backgroundColor: '#007bff', // Generic color
                            '&:hover': {
                                backgroundColor: '#0056b3', // Darkened generic color
                            }, cursor: 'pointer', color: 'white', // Generic color
                        }}
                    />)}
                </Stack>
            </Box>
        )}
        {!sankey && nodeDataArray.length > 0 && (
            <Box className={'processdiagram-content'}>
                <GoDiagramWrapper
                    key={[selectedTheme, nodeColor]}
                    theme={selectedTheme}
                    nodeDataArray={nodeDataArray}
                    originalNodeDataArray={originalNodeDataArray}
                    linkDataArray={linkDataArray}
                    setNodeDataArray={setNodeDataArray}
                    handleDiagramEvent={handleDiagramEvent}
                    backgroundColor={backgroundColor}
                    nodeColor={nodeColor}
                />
            </Box>
        )}
        {sankey && selectedReportSeriesTable && sankeyOption.series.data && <Box>
            <Stack direction="row" sx={{ ml: 2 }}>
                <RadioGroup row value={stepBased} onChange={() => {
                    const newValue = !stepBased
                    setStepBased(newValue)
                    fetchSankey(newValue, numSteps, sankeyPeriod)
                }}>
                    <FormControlLabel
                        control={<Radio />} value={true}
                        label="Step Based " />
                    <FormControlLabel
                        control={<Radio />} value={false}
                        label="Time Based" />
                </RadioGroup>
                <FormControlLabel sx={{ ml: 10 }} control={<Select value={numSteps} sx={{ height: 30, mr: 2 }}
                    onChange={(event) => {
                        setNumSteps(event.target.value);
                        fetchSankey(stepBased, event.target.value, sankeyPeriod, startingValues);
                    }}>
                    {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]).map((num) =>
                        <MenuItem value={num}>{num}</MenuItem>
                    )}
                </Select>} label="Number of Steps">
                </FormControlLabel>
                {!stepBased && <FormControlLabel sx={{ ml: 10 }}
                    control={<Select value={sankeyPeriod} sx={{ height: 30, mr: 2 }}
                        onChange={(event) => {
                            setSankeyPeriod(event.target.value);
                            fetchSankey(stepBased, numSteps, event.target.value, startingValues);
                        }}>
                        {["Daily", "Weekly", "Monthly", "Quarterly"].map((val) =>
                            <MenuItem value={val}>{val}</MenuItem>
                        )}
                    </Select>} label="Time period between steps">
                </FormControlLabel>
                }
                <FormControlLabel sx={{ ml: 10 }}
                    control={
                        <Select
                            multiple sx={{ height: 30, mr: 2, minWidth: 200 }}
                            value={startingValues}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                var newValues = typeof value === 'string' ? value.split(',') : value;
                                setStartingValues(newValues);
                                fetchSankey(stepBased, numSteps, sankeyPeriod, newValues);
                            }}
                            renderValue={(selected) => selected.join(', ')}>
                            {columnList && columnList?.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={startingValues.indexOf(name) > -1} sx={{ mr: 1 }} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>} label="Starting values">
                </FormControlLabel>
            </Stack>
            <ReactECharts
                key={sankeyKey}
                option={getOptionWithStyles()}
                notMerge={true}
                lazyUpdate={true}
                style={chartSetting}
                theme={getSankeyTheme()}
            />
        </Box>}
        {/* Load diagram modal dialog  */}
        <LoadDiagram
            isOpen={loadDiagramModal}
            closeModal={onCloseLoadDiagramModal}
            loadDiagram={loadDiagram}
        />
        <SaveDiagram
            isOpen={saveDiagramModal}
            closeModal={onCloseSaveDiagramModal}
            diagramSavedAs={diagramSavedAs}
        />
        <DeleteDiagram
            isOpen={deleteDiagramModal}
            closeModal={onCloseDeleteDiagramModal}
            deleteDiagramName={loadedDiagramName}
            doDeleteDiagram={doDeleteDiagram}
        />
        <DateFilter
            handleApplyDateChange={handleApplyDateChange}
            isOpen={dateFilterModal}
            closeModal={onCloseDateFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
        />
        {/* Filters modal dialog */}
        <Filters
            isOpen={filtersDiagramModal}
            toggle={onCloseFiltersDiagramModal}
            applyFilters={onApplyFilters}
            dropdownFilter={dropdownFilter}
            // selectedFilters={selectedFilters}
            // setSelectedFilters={setSelectedFilters}
            selectedFiltersV2={selectedFiltersV2}
            setSelectedFiltersV2={setSelectedFiltersV2}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            preliminaryData={preliminaryData}
            selectedReportSeriesTable={selectedReportSeriesTable}
            filterSnapshotDate={filterSnapshotDate}
            setFilterSnapshotDate={setFilterSnapshotDate}
        />
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                {snackbarMessage}
            </Alert>
        </Snackbar>
        {!sankey &&
            <Stack sx={{ px: 2, maxWidth: '100vw', overflowX: 'scroll', height: '65px' }} direction="row"
                spacing={1}> {/* You can adjust spacing as needed */}
                {originalNodeDataArray?.map(genCheckboxRow)}
            </Stack>
        }
        {!sankey &&
            <Sliders
                weightThreshold={weightThreshold}
                supportThreshold={supportThreshold}
                maxsupportThreshold={maxsupportThreshold}
                displayLinksAboveThreshold={displayLinksAboveThreshold}
            />
        }
        {alert &&
            <Toast
                alert={{
                    message: `Unable to render a process diagram. ${alert}.`,
                    severity: "error"
                }}
                onClose={() => setAlert(false)}
            />
        }
        <Drawer
            anchor={"right"}
            open={openStyleDrawer}
            variant={"persistent"}
            PaperProps={{sx: {marginTop: '112px'}}}
        >
            <Box className={'process-style-drawer'}>
                <Box sx={{position: 'absolute', bottom: 132, right: 24}}>
                    <Button className={'button-grey'} onClick={() => setOpenStyleDrawer(false)}>Done</Button>
                </Box>
                <Selector
                    label={'Theme'}
                    defaultValue={'None'}
                    value={selectedTheme}
                    onChange={(e) => {
                        setSelectedTheme('None');
                        setTimeout(() => {
                            setSelectedTheme(e.target.value);
                        }, 0);
                    }}
                    labelClassName={'selector-label-bold'}
                    sx={{height: 38, fontFamily: 'Inter, sans-serif', fontSize: '14px'}}
                    MenuProps={{sx: {maxHeight: 350}}}
                >
                    <MenuItem value={"None"}>None</MenuItem>
                    {workspaceMetadata?.themes.map((theme, index) => (
                        <MenuItem key={index} value={theme}>
                            {theme.themeName + " (" + theme.canvasName + ")"}
                        </MenuItem>
                    ))}
                </Selector>
                {
                    sankey &&
                    <>
                        <Box className={'column'} sx={{gap: '8px'}}>
                            <Typography className={'inter style-config-label'}>{'Path color'}</Typography>
                            <Box sx={{padding: '0px 4px'}}>
                                <ScoopColorPicker
                                    showLabel
                                    value={linkColor}
                                    onChange={(color) => {
                                        setLinkColor(color)
                                        setSankeyKey('' + Math.random())
                                    }}
                                />
                            </Box>
                            {
                                renderColorPicker(
                                    'Background color',
                                    backgroundColor,
                                    setBackgroundColor
                                )
                            }
                        </Box>
                        <Slider
                            label={'Path opacity: ' + linkOpacityValue}
                            value={linkOpacityValue}
                            onChange={(v) => handleOpacityDebounce(v)}
                            min={0}
                            max={1}
                            step={0.1}
                        />
                    </>
                }
                {
                    !sankey &&
                    <>
                        <Box className={'column'} sx={{gap: '8px'}}>
                            {
                                renderColorPicker(
                                    'Background color',
                                    backgroundColor,
                                    setBackgroundColor
                                )
                            }
                        </Box>
                        <Box className={'column'} sx={{gap: '8px'}}>
                            {
                                renderColorPicker(
                                    'Node color',
                                    nodeColor,
                                    setNodeColor
                                )
                            }
                        </Box>
                    </>
                }
            </Box>
        </Drawer>
    </Box>)
}
