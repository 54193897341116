import {OBJECT_TYPES} from "../types";

export const Worksheet_MIN_SIZE = {"width": 200, "height": 200};

export class WorksheetClass {
  constructor(id, title, x, y, width, height) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.type = OBJECT_TYPES.WORKSHEET;
    this.url = 'https://docs.google.com/spreadsheets/d/10NlnVHTKIGX90cnlro_Fmt8PdA5o62t8ZImdF6Qbon8/edit#gid=872140447';
  }

  static newWorksheet() {
    const canvasScrollElement = document.getElementById('canvas-scrollable')
    return new WorksheetClass(
        Math.random(),
        'My Worksheet',
        200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0),
        200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0),
        1000,
        500
    );
  }
}
