import Dialog from "../../../../common/Dialog/Dialog";
import Stepper from "../../../../common/Stepper/Stepper";
import {Box, Chip, Divider, Tooltip} from "@mui/material";
import Button from "../../../../common/Button/Button";
import Typography from "@mui/material/Typography";
import {Switch} from "../../../../common/Switch/Switch";
import Info from "../../../../../assets/icons/Info.svg";
import Input from "../../../../common/Input/Input";
import Selector from "../../../../common/Selector/Selector";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import {Server} from "../../../../../api/Server";
import {ServerContext} from "../../../../Source/SheetState";
import DefineInputQuery from "./InputQuery/DefineInputQuery";
import {ScoopDatePicker} from "../../../../common/DatePicker/ScoopDatePicker";
import {ScoopLoader} from "../../../../common/Spinner/ScoopLoader";
import {getStringForFilter} from "../../../../Insights/Filter";
import {packFilter, unpackFilters} from "./InputQuery/Filter";
import FilterModal from "./InputQuery/FilterModal";

const primaryColor = '#201024';
const secondaryColor = '#635566';

const timeLevels = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'];
const dataRetrievalOptions = ['Raw data', 'Change data']

const menuItems = [
    {label: 'Equals', value: 'Equals'},
    {label: 'Equals', value: 'In'},
    {label: 'Not Equals', value: 'NotEquals'},
    {label: 'Is Null', value: 'IsNull'},
    {label: 'Is Not Null', value: 'IsNotNull'},
    {label: 'Like', value: 'Like'},
];

const BlendingModal = ({
                           metadata,
                           query,
                           onClose,
                           setStep,
                           steps,
                           activeStep = 0,
                           goBack,
                           title,
                           subtitle,
                           goForward,
                           inboxID,
                           worksheetID,
                           index,
                           isEditing = false,
                           isBlending
                       }) => {

    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);

    const indexRef = useRef(index);

    const [queryName, setQueryName] = useState(query ? query : 'Query ' + (indexRef.current + 1))
    const [advancedToggleOn, setAdvancedToggleOn] = useState(!isBlending)
    const [snapshotToggleOn, setSnapshotToggleOn] = useState(true)
    const [aggregateToggleOn, setAggregateToggleOn] = useState(!isBlending)

    const [datasets, setDatasets] = useState([])
    const [chosenDataset, setChosenDataset] = useState({})
    const [tables, setTables] = useState([])
    const [chosenTable, setChosenTable] = useState({})
    const [isTableLoaded, setIsTableLoaded] = useState(false)

    const [currentInbox, setCurrentInbox] = useState(null)

    const [groupBy, setGroupBy] = useState(0);
    const [aggregateBy, setAggregateBy] = useState(timeLevels[0]);
    const [dataRetrieval, setDataRetrieval] = useState(dataRetrievalOptions[0]);
    const [pickedDate, setPickedDate] = useState(null)
    const [save, setSave] = useState(null)

    const selectedColumnsRef = useRef(null);
    const selectedFiltersRef = useRef({
        index: 0,
        filter: []
    });

    const currentServerContext = new ServerContext(new Server(workspaceID, userID, token))
    const [triggerRender, setTriggerRender] = useState(false)

    const [isNew, setIsNew] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        const getQueries = async () => {
            await currentServerContext.server.sheetPostData({
                "action": "getCSWDefinition",
                "inboxID": inboxID
            }, processQueryDefinition);
        }
        getQueries();
        setIsLoading(false)
    }, [index]);

    useEffect(() => {
        const datasets = Object.keys(metadata.inboxes)
            .filter(key => metadata.inboxes[key].tables.length > 0)
            .map(key => metadata.inboxes[key])
        setDatasets(datasets)
        setChosenDataset(datasets[0])
    }, [metadata, index])

    useEffect(() => {
        const dataset = datasets.find(dataset => dataset.label === chosenDataset.label)
        setCurrentInbox(dataset)
        const nonNullTables = dataset?.tables.filter((table) => table.tableName !== null);
        if (!nonNullTables) return;
        setTables(nonNullTables);
        setChosenTable(nonNullTables[0]);
        setIsTableLoaded(true);
    }, [chosenDataset]);

    const processQueryDefinition = (data) => {
        if (data?.queries[index]) {
            const query = data.queries[index];
            selectedColumnsRef.current = {index: indexRef.current, columns: query.columns};
            selectedFiltersRef.current = {index: indexRef.current, filter: unpackFilters(query.filter)};
            setAggregateBy(query.aggregateLevel)
            setQueryName(query.sheetName)
            const dataset = metadata.inboxes.find(inbox => inbox.inboxID === query.inboxID)
            setChosenDataset(dataset)
            const table = dataset?.tables.find(table => table.reportSeriesTableID === query.tableID)
            setChosenTable(table)
            setAggregateToggleOn(query.aggregate)
            setSnapshotToggleOn(query.useLatestLoad)
            query.startDate && setPickedDate(new Date(query.startDate))
        } else {
            selectedColumnsRef.current = {index: indexRef.current, columns: []};
            if (selectedFiltersRef.current.filter.length > 0) {
                selectedFiltersRef.current.filter = [];
            }
            selectedFiltersRef.current.index = indexRef.current;
        }
        setTriggerRender(!triggerRender)
    }

    const getDefineQuery = () => {
        if (!currentInbox || !chosenTable) return;
        let columns = selectedColumnsRef.current.index === indexRef.current ? selectedColumnsRef.current.columns : [];
        let filters = selectedFiltersRef.current.index === indexRef.current ? selectedFiltersRef.current.filter : [];
        return {
            sheetName: queryName,
            useLatestLoad: snapshotToggleOn,
            usePeriod: snapshotToggleOn ? "latest" : pickedDate ? "since" : undefined,
            startDate: pickedDate ? pickedDate.toLocaleDateString() : null,
            dateKey: parseInt(groupBy),
            inbox: currentInbox.inboxName,
            inboxID: currentInbox.inboxID,
            tableID: chosenTable.reportSeriesTableID,
            columns: columns,
            changes: dataRetrieval === 'Change data',
            aggregate: !isBlending || aggregateToggleOn,
            aggregateLevel: "Daily",
            filter: filters ? packFilter(filters) : undefined,
            isNew: !isEditing && isNew,
        };
    }

    const resetState = () => {
        setSave(() => false)
        setAdvancedToggleOn(false)
        setSnapshotToggleOn(true)
        setQueryName('Query ' + (indexRef.current + 1))
        setChosenDataset(() => {})
        setChosenTable(() => {})
        setGroupBy(() => 0)
        setAggregateBy(() => timeLevels[0])
        setDataRetrieval(() => dataRetrievalOptions[0])
        setPickedDate(() => null)
        setDatasets(() => [])
        setTables(() => [])
        setIsTableLoaded(() => false)
        selectedColumnsRef.current = {index: indexRef.current, columns: []};
        setAnchorEl(null)
    }

    const handleContinue = () => {
        isNew && setIsNew(false)
        setSave(() => true)
    }

    const continueToNext = () => {
        goForward();
        !isEditing && indexRef.current === 0 ? indexRef.current = 1 : indexRef.current = 0
    }

    const setSelectedColumns = (column) => {
        if (!column || column.length === 0 || typeof column !== "string") return;
        // Check if the column already exists
        const existingIndex = selectedColumnsRef.current.columns.findIndex(c => c.columnName === column);
        // If the column exists, remove it
        if (existingIndex !== -1) {
            const newColumns = selectedColumnsRef.current.columns.filter(c => c.columnName !== column);
            selectedColumnsRef.current = {index: indexRef.current, columns: newColumns};
        } else {
            // Add the new column
            const col = {columnName: column, columnTypes: "String"};
            const cols = [...selectedColumnsRef.current.columns, col];
            selectedColumnsRef.current = {index: indexRef.current, columns: cols};
        }
        // Trigger re-render
        setTriggerRender(!triggerRender);
    }

    const setSelectedFilters = (filter) => {
        if (!filter || filter.length === 0) return;
        selectedFiltersRef.current.filter = filter;
        setAnchorEl(null)
    }

    const handleDeleteFilter = (index) => {
        if (!selectedFiltersRef.current.filter) return;
        selectedFiltersRef.current.filter = selectedFiltersRef.current?.filter
            .filter((f, i) => i !== index);
        setTriggerRender(!triggerRender)
        selectedFiltersRef.current.filter = selectedFiltersRef.current.filter.filter(filter => filter.filterValue.values.length > 0);
        setAnchorEl(null)
    }

    const handleFilterEdit = (event, filter) => {
        setAnchorEl({
            anchorEl: event.currentTarget,
            column: filter.attributeName
        })
    }

    const handleFilterChange = (filter) => {
        selectedFiltersRef.current.filter = filter;
        setTriggerRender(prev => !prev);
    };

    return (
        <Dialog
            style={{width: "1168px", height: '750px'}}
            maxWidth={false}
            maxHeight={false}
            open
            onClose={() => {
                onClose()
                setStep(0)
            }}
            actions={
                <>
                    {!isEditing ?
                        <>
                            <Button className={'button-grey small'} onClick={() => {
                                indexRef.current === 1 ? indexRef.current = 0 : indexRef.current = 1
                                resetState()
                                goBack()
                            }}>
                                Back
                            </Button>
                            <Button
                                style={{padding: 0}}
                                className={'button-purple small'}
                                onClick={handleContinue}
                                disabled={!queryName || !isTableLoaded || isLoading || selectedColumnsRef?.current?.columns?.length === 0}
                            >
                                {isLoading ?
                                    <ScoopLoader sx={{padding: '0 !important'}} size={42}/>
                                    :
                                    'Continue'
                                }
                            </Button>
                        </>
                        :
                        <Button
                            style={{padding: 0}}
                            className={'button-purple small'}
                            onClick={() => handleContinue()}
                            disabled={isLoading || selectedColumnsRef?.current?.columns?.length === 0}
                        >
                            {isLoading ?
                                <ScoopLoader sx={{padding: '0 !important'}} size={42}/>
                                :
                                'Save'
                            }
                        </Button>
                    }
                </>
            }
            title={!isEditing && <Stepper width={'45%'} activeStep={activeStep} steps={steps}/>}
        >
            {!isEditing && <Divider/>}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '24px', padding: '8px'}}>
                <Box>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Typography sx={{color: primaryColor, fontSize: '24px', fontWeight: 600}}>
                            {!isEditing ? title : 'Edit Query'}
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                            <Switch checked={advancedToggleOn} onClick={() => setAdvancedToggleOn(!advancedToggleOn)}/>
                            <Typography sx={{color: secondaryColor, fontSize: '12px'}}>
                                Advanced
                            </Typography>
                        </Box>
                    </Box>
                    <Typography sx={{color: secondaryColor, fontSize: '14px'}}>
                        {subtitle}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                    <Box sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                            <Typography
                                sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}
                            >
                                Query name
                            </Typography>
                            <Tooltip title={'The name of the query that will be used to aggregate the data'}>
                                <img src={Info} alt={'info'}/>
                            </Tooltip>
                        </Box>
                        <Input
                            value={queryName}
                            padding={'0px 12px !important'}
                            onChange={(e) => setQueryName(e.target.value)}
                            size={'small'}
                            style={{height: '38px', paddingTop: '4px'}}
                        />
                    </Box>
                    <Box sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                            Select dataset
                        </Typography>
                        <Selector
                            onChange={(e) => setChosenDataset(e.target.value)}
                            value={chosenDataset || {}}
                            style={{height: '38px'}}
                        >
                            {datasets && datasets.map((dataset, index) => (
                                <MenuItem key={index} value={dataset}>{dataset.label}</MenuItem>
                            ))}
                        </Selector>
                    </Box>
                    <Box sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                            Select table
                        </Typography>
                        <Selector onChange={(e) => setChosenTable(e.target.value)}
                                  value={chosenTable || {}}
                                  style={{height: '38px'}}
                        >
                            {tables && tables.map((table, index) => (
                                <MenuItem key={index} value={table}>{table.tableName}</MenuItem>
                            ))}
                        </Selector>
                    </Box>
                    <Box sx={{
                        width: snapshotToggleOn ? '222px' : '116px',
                        height: '59px',
                        flexDirection: 'column',
                        gap: '4px'
                    }}>
                        <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                            Use latest snapshot
                        </Typography>
                        <Switch
                            checked={snapshotToggleOn}
                            sx={{mt: '12px'}}
                            onClick={() => {
                                setSnapshotToggleOn(!snapshotToggleOn)
                                setPickedDate(null)
                            }}
                        />
                    </Box>
                    {!snapshotToggleOn &&
                        <Box>
                            <ScoopDatePicker
                                value={pickedDate}
                                onChange={(date) => setPickedDate(date)}
                                containerSx={{gap: '4px'}}
                                label={'Start date'}
                            />
                        </Box>
                    }
                </Box>
                {advancedToggleOn &&
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px'}}>
                        <Box
                            sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <Typography
                                sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}
                            >
                                Date to group by
                            </Typography>
                            <Selector
                                onChange={(e) => setGroupBy(e.target.value)}
                                value={groupBy}
                                padding={'0px 12px !important'}
                                size={'small'}
                                style={{height: '38px', paddingTop: '4px'}}
                            >
                                <MenuItem value={0}>Load Date</MenuItem>
                                {chosenTable.dates && chosenTable.dates.map((dateName, index) =>
                                    <MenuItem key={index} value={index + 1}>{dateName}</MenuItem>
                                )}
                            </Selector>
                        </Box>
                        <Box
                            sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                                Aggregate by time period
                            </Typography>
                            <Selector
                                value={aggregateBy}
                                onChange={(e) => setAggregateBy(e.target.value)}
                                style={{height: '38px'}}
                            >
                                {timeLevels.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Selector>
                        </Box>
                        <Box
                            sx={{width: '272px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                                Data retrieval
                            </Typography>
                            <Selector onChange={(e) => setDataRetrieval(e.target.value)}
                                      value={dataRetrieval}
                                      style={{height: '38px'}}
                            >
                                {dataRetrievalOptions.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Selector>
                        </Box>
                        <Box
                            sx={{width: '222px', height: '59px', display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <Typography sx={{color: primaryColor, fontSize: '12px', fontWeight: '600'}}>
                                Aggregate by selected attributes
                            </Typography>
                            <Switch
                                checked={aggregateToggleOn}
                                sx={{mt: '8px'}}
                                onClick={() => setAggregateToggleOn(!aggregateToggleOn)}
                            />
                        </Box>
                    </Box>
                }
                {!isTableLoaded &&
                    <Box sx={{
                        height: '300px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F9F9F9'
                    }}>
                        <Typography sx={{color: '#D0CDD1', fontSize: '14px'}}>
                            Select dataset above to see columns
                        </Typography>
                    </Box>
                }
                <Box
                    sx={{
                        width: 'auto',
                        maxWidth: '100%',
                        overflow: 'auto',
                        gap: '8px',
                        maxHeight: '100px',
                        display: 'flex',
                    }}
                >
                    {selectedFiltersRef?.current?.filter?.map((f, index) => (
                        <Chip
                            sx={{width: 'auto'}}
                            onDelete={() => handleDeleteFilter(index)}
                            key={index}
                            label={getStringForFilter(f)}
                            onClick={(e) => handleFilterEdit(e, f)}
                        />
                    ))}
                </Box>
                {chosenTable && chosenTable.columns && selectedColumnsRef.current ?
                    <DefineInputQuery
                        editingAnchorEl={anchorEl}
                        setEditingAnchorEl={setAnchorEl}
                        selectedColumns={selectedColumnsRef.current}
                        selectedFilters={selectedFiltersRef.current}
                        setSelectedColumns={setSelectedColumns}
                        handleFilterChange={handleFilterChange}
                        index={index}
                        table={chosenTable}
                        inputQuery={getDefineQuery()}
                        server={currentServerContext.server}
                        worksheetID={worksheetID}
                        aggregate={aggregateToggleOn}
                        timeLevel={aggregateBy}
                        useLatest={snapshotToggleOn}
                        groupByDate={groupBy}
                        pickedDate={pickedDate}
                        save={save}
                        setSave={setSave}
                        dataRetrieval={dataRetrieval === 'Change data'}
                        continueToNext={continueToNext}
                        setIsCreating={setIsLoading}
                        resetState={resetState}
                    /> :
                    <Box sx={{height: '314px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{color: '#D0CDD1', fontSize: '14px'}}>
                            Select table above to see columns
                        </Typography>
                    </Box>
                }
            </Box>

        </Dialog>
    )

}


export default BlendingModal;