export const SET_PROMPT_SELECTING = 'SET_PROMPT_SELECTING';
export const SET_PROMPT_SELECTING_OBJECTS = 'SET_PROMPT_SELECTING_OBJECTS';
export const TOGGLE_PROMPT_OBJECT = 'TOGGLE_PROMPT_OBJECT';
export const SET_LAST_EDITED_PROMPT = 'SET_LAST_EDITED_PROMPT';
export const SET_UPDATED_WORKSHEET = 'SET_UPDATED_WORKSHEET';

export const setPromptSelecting = (toggle, id) => {
    return {
        type: SET_PROMPT_SELECTING,
        payload: {toggle, id}
    };
}

export const setPromptSelectingObjects = (objects) => {
    return {
        type: SET_PROMPT_SELECTING_OBJECTS,
        payload: objects
    };
}

export const togglePromptObject = (objectId) => {
    return {
        type: TOGGLE_PROMPT_OBJECT,
        payload: objectId
    };
}

export const setLastEditedPrompt = (prompt) => {
    return {
        type: SET_LAST_EDITED_PROMPT,
        payload: prompt
    };
}

export const setUpdatedWorksheet = (worksheetId) => {
    return {
        type: SET_UPDATED_WORKSHEET,
        payload: worksheetId
    };
}
