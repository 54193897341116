import {useEffect, useState} from "react";
import BlendingModal from "./BlendingModal";
import CalculatedColumnsDialog from "./CalculatedColumnsDialog";
import SettingsModal from "./SettingsModal";
import {ServerContext} from "../../../../Source/SheetState";
import {Server} from "../../../../../api/Server";
import {useSelector} from "react-redux";

const BlendedPath = ({ onClose, goBack, inboxName, inboxID, worksheetID, handleSave, metadata, serverContext }) => {

    const [step, setStep] = useState(0);

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <BlendingModal
                        metadata={metadata}
                        index={0}
                        steps={['Query 1', 'Query 2', 'Blending', 'Settings']}
                        setStep={setStep}
                        onClose={onClose}
                        goBack={goBack}
                        goForward={() => setStep(1)}
                        title={'Query 1'}
                        subtitle={'Create a data query from an existing dataset'}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                        isBlending={true}
                    />
                )
            case 1:
                return (
                    <BlendingModal
                        metadata={metadata}
                        index={1}
                        steps={['Query 1', 'Query 2', 'Blending', 'Settings']}
                        setStep={setStep}
                        onClose={onClose}
                        goForward={() => setStep(2)}
                        goBack={()=> setStep(0)}
                        activeStep={1}
                        title={'Query 2'}
                        subtitle={'Create a data query from an existing dataset, to merge with data query 1'}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                        isBlending={true}
                    />
                )
            case 2:
                return (
                    <CalculatedColumnsDialog
                        serverContext={serverContext}
                        isBlending={true}
                        steps={['Query 1', 'Query 2', 'Blending', 'Settings']}
                        onClose={onClose}
                        goBack={()=> setStep(1)}
                        activeStep={2}
                        goForward={() => {
                            setStep(3)
                            /*handleSave()
                            onClose()*/
                        }}
                        inboxID={inboxID}
                    />
                )
            case 3:
                return (
                    <SettingsModal
                        inboxID={inboxID}
                        serverContext={serverContext}
                        isBlending={true}
                        save={() => handleSave()}
                        steps={['Query 1', 'Query 2', 'Blending', 'Settings']}
                        setStep={setStep}
                        onClose={onClose}
                        activeStep={3}
                        goBack={() => setStep(2)}
                        inboxName={inboxName}
                    />
                )
        }
    }

    return (
        <>{metadata && renderStepContent()}</>
    );
}

export default BlendedPath;