import scoopLoader from '../../../assets/gifs/Scoop_Loader.gif';
export const ScoopLoader = ({size = 36, ...props}) => {

    const sizeStyle = typeof size === 'number' ? `${size}px` : size;

    return (
        <img
            style={{ width: sizeStyle }}
            src={scoopLoader}
            alt="Scoop loader"
            {...props}
        />
    )

}