import React, {useEffect, useState} from "react";
import {SelectType} from "../SelectType";
import Dialog from "../../../../common/Dialog/Dialog";
import Button from "../../../../common/Button/Button";
import {Box, Typography} from "@mui/material";
import {TypeBox} from "../TypeBox";
import BlendingMethodDialog from "./BlendingMethodDialog";
import AggregatePath from "./AggregatePath";
import BlendedPath from "./BlendedPath";
import {ServerContext} from "../../../../Source/SheetState";
import {Server} from "../../../../../api/Server";
import {useSelector} from "react-redux";
import {Toast} from "../../../../common/Toast/Toast";
import {useWorkspaceMetadata} from "../../../../../hooks/useGetWorkspaceMetadata";


const BlendingDatasets = ({onClose, stepBack}) => {

    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);

    const [step, setStep] = useState(1)
    const [type, setType] = useState('')
    const [inboxID, setInboxID] = useState(null)
    const [worksheetID, setWorksheetID] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [inboxName, setInboxName] = useState('')
    const [inboxDescription, setInboxDescription] = useState(null)
    const [alert, setAlert] = useState(null)

    const serverContext = new ServerContext(new Server (workspaceID, userID, token))
    const { metadata } = useWorkspaceMetadata(userID, workspaceID, token);

    const handleCreateCSW = async (results) => {
        if (results.error ||results.message === 'Internal server error') {
            setIsLoading(false)
            setAlert({message: 'An error occurred while creating the dataset.', severity: 'error'})
        } else {
            setInboxID(results.inboxID)
            setWorksheetID(results.worksheetID)
            setIsLoading(false)
            setStep(2)
        }
    }

    const handleCreateInbox = async (inboxName, sourceType) => {
        setIsLoading(true)
        setInboxName(inboxName)
        await serverContext.server.sheetPostData({
            "action": "createCalculatedSheet",
            "inboxName": inboxName,
            "description": inboxDescription ? inboxDescription : undefined,
            "inboxType": "calculated",
            //"sourceType" : { sourceType: sourceType.toLowerCase() } LEAVE THIS FOR THE FUTURE, RIGHT NOW ASSUME IT'S TRANSACTIONAL
            "sourceType" : {sourceType: "transactional"}
        }, handleCreateCSW);
    }

    const handleSave = async () => {
        try {
            await serverContext.server.sheetPostData({
                "action": "flushWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            });
        } catch (e) {
            console.error(e)
        }
    }

    const handleClose = async () => {
        /*if (inboxID) {
            await serverContext.server.postData({
                "action": "clearWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            }, () => {
            });
        }*/
        onClose()
    }

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <SelectType
                        index={1}
                        stepBack={stepBack}
                        setType={setType}
                        onClose={handleClose}
                        isSnapshotDefault={true}
                        createInbox={true}
                        setStep={setStep}
                        handleCreateInbox={handleCreateInbox}
                        isLoading={isLoading}
                        isBlending={true}
                        setDescription={setInboxDescription}
                    />
                )
            case 2:
                return (
                    <BlendingMethodDialog
                        setStep={setStep}
                        onClose={handleClose}
                    />
                )
            case 3:
                return (
                    <BlendedPath
                        serverContext={serverContext}
                        metadata={metadata}
                        handleSave={handleSave}
                        inboxName={inboxName}
                        goBack={() => setStep(2)}
                        setStep={setStep}
                        onClose={handleClose}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                    />
                )
            case 4:
                return (
                    <AggregatePath
                        serverContext={serverContext}
                        metadata={metadata}
                        handleSave={handleSave}
                        inboxName={inboxName}
                        goBack={() => setStep(2)}
                        setStep={setStep}
                        onClose={onClose}
                        inboxID={inboxID}
                        worksheetID={worksheetID}
                    />
                )
            default:
                return <></>
        }
    }

    return (
        <>
            {renderStepContent()}
            <Toast alert={alert} onClose={() => setAlert(null)}></Toast>
        </>
    );

}

export default BlendingDatasets;