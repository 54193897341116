// Gmail.jsx

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useApi } from '../../../../api/api';
import { ROUTES } from "../../../../router/routes";

import Dialog from "../../../common/Dialog/Dialog";
import Button from "../../../common/Button/Button";
import { DialogTitle } from "@mui/material";
import { TextField, Select, MenuItem, InputLabel, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Stack, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Box, Typography, Switch, Checkbox } from '@mui/material';
import Input from "../../../common/Input/Input";
import Selector from "../../../common/Selector/Selector";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SubjectIcon from '@mui/icons-material/Subject';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Toast } from "../../../common/Toast/Toast";

import EmailPreviewModal from './PreviewEmail';

import IconButton from '@mui/material/IconButton';

import { replace } from "lodash";

import datasourceDefs from '../../../../api/datasourceConfig'
const bot = datasourceDefs.find(b => b.name === "Gmail");

// google api, add redirect to this in code based on the current url
const GMAIL_API_URL = bot.GMAIL_API_URL;
const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/gmail`;



export const GMail = () => {
    const navigate = useNavigate();
    const { postData: postGmailAuth } = useApi(bot.API_URL);
    const { postData: postRobotActions } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions');

    const [selectedReport, setSelectedReport] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [oauthCode, setOauthCode] = useState('')

    const [userName, setUserName] = useState(''); //this is the username returned by the first email scan call, since we don't know what gmail user the user selected

    const [inboxID, setInboxID] = useState('');
    const [fromSender, setFromSender] = useState('');
    const [emailSubject, setEmailSubject] = useState('');
    const [subjectOnly, setSubjecOnly] = useState(true);
    const [emailCount, setEmailCount] = useState(0);
    const [selectedEmail, setSelectedEmail] = useState('');
    const [emailsList, setEmailsList] = useState([]);
    const [reportName, setReportName] = useState('');
    const [reportDescription, setReportDescription] = useState('');

    const [emailPreviewModalOpen, setEmailPreviewModalOpen] = useState(false);
    const [rawEmail, setRawEmail] = useState('');
    // this flow means the user came in via the setup page and now has to authorize gmail
    const [isFinishSetupFlow, setIsFinishSetupFlow] = useState(false);
    const [isReadyToSave, setIsReadyToSave] = useState(false);

    const [alert, setAlert] = useState(false)

    const location = useLocation();
    const { inboxInfo } = location.state || {}; // Ensure there's a fallback if state is undefined

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');

        if (code) {
            const decodedCode = decodeURIComponent(code);
            setOauthCode(decodedCode);
            setIsFinishSetupFlow(false);
            const stateObject = JSON.parse(localStorage.getItem('gmailIntegrationState'));
            setInboxID(stateObject.inboxID);
            setEmailSubject(stateObject.emailSubject);
            setFromSender(stateObject.emailFrom);
        } else if (queryParams.toString() !== '') {
            // console.log("Got back from Google with other parameters:", queryParams.toString());
            setIsFinishSetupFlow(false);
        }

        if (inboxInfo != {}) {
            setIsFinishSetupFlow(true);
        }

    }, []); // Empty dependency array ensures this runs once on mount

    // Note: this used to work but we took it out of the current flow
    const scanEmails = async () => {
        const action = {
            "action": "scanEmails",
            "emailSubject": emailSubject,
            "emailFrom": fromSender,
            "max_days_ago": "",
            "userName": userName,
            "subjectOnly": subjectOnly,
            "redirect_uri": REDIRECT_URI,
            "oauthCode": oauthCode,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        const result = await postGmailAuth(action);
        return result;
    };

    const handleScanEmails = async () => {
        setIsLoading(true);
        try {
            const data = await scanEmails();
            if (data.status === 'success') {
                setEmailsList(data?.emails);
                setUserName(data?.userName);
                setIsReadyToSave(true);
            } else {
                console.log("error reading emails!");
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const previewEmail = async (emailID) => {
        const action = {
            "action": "previewEmail",
            "userName": userName,
            "emailID": emailID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        const result = await postGmailAuth(action);
        return result;
    };

    const handleEmailClick = async (emailID) => {
        setIsLoading(true);
        try {
            const data = await previewEmail(emailID);
            setRawEmail(data?.email);
            setEmailPreviewModalOpen(true);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };



    const commenceGMailFlow = () => {
        // save the local state so we can come back to it after the oauth dance
        localStorage.setItem('gmailIntegrationState', JSON.stringify({
            inboxID: inboxInfo.inboxID,
            emailFrom: inboxInfo.emailFrom,
            emailSubject: inboxInfo.emailSubject
        }));

        window.location.href = replace(GMAIL_API_URL, 'REDIRECT_URI', REDIRECT_URI);
    }



    const handleSubjectToggle = (event) => {
        setSubjecOnly(event.target.checked);
    };

    function handleClose(event) {
        setTimeout(() => navigate(ROUTES.SOURCES), 5000)
    }


    const createBot = async () => {
        const action = {
            "action": "flipOnSetupInbox",
            "inboxID": inboxID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        const result = await postRobotActions(action);
        return result;
    };

    const handleSave = () => {
        createBot().then((results) => {
            if (results?.status === 'success') {
                setAlert({ message: 'Gmail connector created successfully', severity: 'success' })
                setTimeout(() => navigate(ROUTES.SOURCES), 5000)
            }
            else {
                setAlert({ message: results?.message ?? 'Failed to create Gmail app connector', severity: 'error' })
                setTimeout(() => navigate(ROUTES.SOURCES), 5000)
            }
        })
    }



    const previewHtml = "<div><strong>Preview:</strong> This is formatted HTML content.</div>";

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'}></Button>
            {oauthCode && !isReadyToSave && <Button onClick={handleScanEmails} className={'button-purple'} text={'Verify'}></Button>}
            {oauthCode && isReadyToSave && <Button onClick={handleSave} className={'button-purple'} text={'Save'}></Button>}

        </Box>
    )


    return (
        <>
            <EmailPreviewModal
                rawEmail={rawEmail}
                open={emailPreviewModalOpen}
                onClose={() => setEmailPreviewModalOpen(false)}
            />
            {isFinishSetupFlow &&
                <Dialog open={true} title={'Gmail Integration'} actions={actions} icon={bot.icon} maxWidth={'800px'}>
                    <Box sx={{ display: 'flex', m: 0, mt: 2, alignItems: 'center', width: '800px' }}>
                        <DialogContent style={{ width: '800px', textAlign: 'center' }}>
                            {!oauthCode &&
                                <Box>
                                    <div style={{ color: '#635566', textAlign: 'start' }}>Since the user chose to integrate via gmail, tell them...
                                    </div>
                                    <IconButton onClick={commenceGMailFlow}>
                                        <img
                                            src={bot.button}
                                            alt="Integrate GMail"
                                            style={{ cursor: "pointer", maxWidth: '100%', alignContent: 'center' }}
                                        />
                                    </IconButton>
                                </Box>
                            }

                            {oauthCode &&
                                // <Stack direction="row" spacing={4} sx={{ width: '100%' }}>

                                //     <TextField
                                //         id="from-sender-textfield"
                                //         label="From"
                                //         InputProps={{
                                //             startAdornment: (
                                //                 <InputAdornment position="start">
                                //                     <AccountCircleIcon />
                                //                 </InputAdornment>
                                //             ),
                                //         }}
                                //         variant="standard"
                                //         fullWidth
                                //         value={fromSender}
                                //         onChange={(e) => setFromSender(e.target.value)}  // Handle changes
                                //     />

                                //     <TextField
                                //         id="email-subject-textfield"
                                //         label="Contains"
                                //         InputProps={{
                                //             startAdornment: (
                                //                 <InputAdornment position="start">
                                //                     <SubjectIcon />
                                //                 </InputAdornment>
                                //             ),
                                //         }}
                                //         variant="standard"
                                //         fullWidth
                                //         value={emailSubject}
                                //         onChange={(e) => setEmailSubject(e.target.value)}  // Handle changes
                                //     />

                                //     <FormControlLabel
                                //         sx={{ m: 1, minWidth: 250, flexGrow: 1, alignSelf: 'self-end', marginTop: 0 }} size="small"
                                //         control={
                                //             <Switch
                                //                 checked={subjectOnly}
                                //                 onChange={handleSubjectToggle}
                                //                 name="subjectOnlySwitch"
                                //             />
                                //         }
                                //         label="Search Subject Only"
                                //     />
                                // </Stack>
                                <>
                                    <Box sx={{ padding: '12px 16px', fontSize: '14px', background: '#F9F9F9', borderRadius: '5px', color: '#635566' }} >
                                        <Typography> Subject: <b>{emailSubject}</b> From: <b>{fromSender}</b></Typography>
                                    </Box>
                                    {/* <Input
                                        sx={{ width: '100%' }}
                                        label={'Report name'}
                                        onChange={(event) => {
                                            setReportName(event.target.value)
                                        }}
                                        value={reportName}
                                    />
                                    <Input
                                        sx={{ width: '100%' }}

                                        multiline
                                        rows={2}
                                        label={'Report description'}
                                        onChange={(event) => {
                                            setReportDescription(event.target.value)
                                        }}
                                        value={reportDescription}
                                    /> */}
                                    <Selector value="daily" label='How often do you want to check this email?' native>
                                        <option>
                                            {"daily"}
                                        </option>
                                    </Selector>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Scoop historical data from previous emails" />
                                    </FormGroup>                     </>
                            }

                            {emailsList && emailsList.length > 0 && (
                                <List sx={{
                                    height: '250px',
                                    overflow: 'auto',
                                    border: 1,
                                    borderColor: 'lightgray',
                                    borderRadius: '5px',
                                    mt: '10px'  // shorthand for marginTop
                                }}>
                                    {emailsList.map((email) => (
                                        <ListItem disablePadding key={email.emailID}>
                                            <ListItemButton onClick={() => handleEmailClick(email.emailID)}>
                                                <ListItemIcon>
                                                    <DraftsIcon />
                                                </ListItemIcon>
                                                <ListItemText primary={email.snippet} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            )}

                            {isLoading &&
                                <LinearProgress variant="indeterminate" style={{ marginTop: '20px', height: '10px' }} />
                            }
                        </DialogContent>
                    </Box>
                    {alert && <Toast alert={alert} onClose={() => setAlert(false)} />}
                </Dialog>
            }

        </>

    );

};


