// useApi.js
import { useSelector } from 'react-redux';
import {useNavigate, useParams} from "react-router-dom";
import { ROUTES } from '../../../router/routes';

export const useApi2 = (otherURL) => {
    const navigate = useNavigate()
    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const apiURL = useSelector(state => state.auth.apiURL);

    const postData2 = async (action) => {
        if (Array.isArray(action)) {
            action.forEach(a => {
                a.workspaceID = workspaceID;
                a.userID = userID;
            })
        } else {
            action.workspaceID = workspaceID;
            action.userID = userID;
        }
        const url = typeof otherURL === 'undefined' ? apiURL : otherURL;
        try {
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(action),
            }); console.log(response)
            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                if (response?.message === 'Unauthorized') {
                    console.log('*****Unauthorized');
                    navigate(ROUTES.LOGOUT)
                }
                // Not OK - throw an error
                throw new Error('Network response was not ok', response);

            }

            // Try parsing the response into JSON
           /* const clone = response.clone();
            await clone.text().then(a => console.log('clone.text:', JSON.parse(a)));
            const a = await response.json();
            console.log('postData response:', a);
            return await response.text().JSON.parse(a);*/
            const responseText = await response.text();
            console.log('Response text:', responseText);
            const parsedData = JSON.parse(responseText);
            console.log('Parsed data:', parsedData);
            return parsedData;
        } catch (error) {
            console.log(error);
            // navigate(ROUTES.LOGOUT)
            // Log any errors that occur during the fetch() or parsing
            // throw error;
        }
    };
    return { postData2 };
};
