import {Box, Drawer as MuiDrawer} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './Drawer.css'
export const Drawer = ({open, onClose, children, ...props})=>{

    return(
        <MuiDrawer open={open} onClose={onClose} {...props} className={'drawer-container'} >
            <Box onClick={onClose} className={'drawer-xButton'}>
                <CloseIcon/>
            </Box>
            {children}
        </MuiDrawer>
    )
}