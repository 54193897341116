import {useState} from "react";
import {initiateForgotPassword, submitNewPassword} from "../../../../api/auth";

export const useRecoverPassword = (setShowSnackbar, setRecoverPasswordStep, backToLogin) => {

    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState({
        code: '',
        password: '',
        confirmPassword: ''
    });

    const handleEmailChange = (value) => {
        setEmail(value);
    }

    const checkEqualPasswords = () => {
        return newPassword.password === newPassword.confirmPassword;
    }

    const handleRecoverPasswordChange = (fieldName, value) => {
        setNewPassword(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const handleStartPWDReset = () => {
        initiateForgotPassword({
            username: email,
        }).then(() => {
            setShowSnackbar({
                msg: `Please check your ${email} email for a reset code. Make sure to check your spam folder if you don't see the email in a few minutes.`,
                severity: "success"
            });
            setRecoverPasswordStep(1);
        })
            .catch((error) => {
                setShowSnackbar({msg: error.message, severity: "error"});
                console.log("password reset error: ", error);
            });
    };

    const handleFinishPWDReset = () => {
        if (!checkEqualPasswords()) {
            setShowSnackbar({
                msg: "Passwords do not match",
                severity: "error"
            });
            return;
        }
        submitNewPassword({
            username: email,
            code: newPassword.code,
            new_password: newPassword.password,
        }).then(() => {
            setShowSnackbar({
                msg: `Your password for ${email} has been resest! Please log in with your new password`,
                severity: "success"
            });
            setEmail('')
            setNewPassword({
                username: '',
                code: '',
                password: '',
            })
            backToLogin();

        })
            .catch((error) => {
                setShowSnackbar({
                    msg: error.message,
                    severity: "error"
                })
                console.log("password reset error: ", error);
            });

    };

    return {
        email,
        newPassword,
        checkEqualPasswords,
        handleEmailChange,
        handleRecoverPasswordChange,
        handleStartPWDReset,
        handleFinishPWDReset,
    }
}