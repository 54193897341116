import {OBJECT_TYPES} from "../types";

export const Image_MIN_SIZE = {"width": 200, "height": 200};

export class ImageClass {
  constructor(id, title, x, y, width, height, content) {
    this.id = id;
    this.title = title;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.isBlank = true;
    this.type = OBJECT_TYPES.IMAGE;
    this.content = content;
  }

  // initialize w sample data from a working chart
  static newImage(title, content, zoom, x, y) {
    const canvasScrollElement = document.getElementById('canvas-scrollable')
    return new ImageClass(
        Math.random(),
        title || 'My Image',
        x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
        y ? y :(200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
        800,
        500,
        content
    );
  }
}
