import {useState, useEffect, useCallback} from 'react';
import {useSelector} from "react-redux";

export const useMultiDnD = (
    delta,
    selectedItems,
    initialPositions,
    updateElementsPosition,
    snap
) => {

    const [positions, setPositions] = useState(initialPositions);
    const [isDragging, setIsDragging] = useState(false);
    const [mouseDown, setMouseDown] = useState(false)
    const [dragOffsets, setDragOffsets] = useState([]);
    const zoom = useSelector(state => state.ui.zoom);

    const handleMouseDown = useCallback((e) => {
        setMouseDown(true)
        setIsDragging(true)
        const offsets = []
        initialPositions.forEach(position => {
            if (position.startPos) {
                const startOffsetX = e.clientX/zoom - position.startPos.x;
                const startOffsetY = e.clientY/zoom - position.startPos.y;
                const endOffsetX = e.clientX/zoom - position.endPos.x;
                const endOffsetY = e.clientY/zoom - position.endPos.y;
                offsets.push({
                    startOffset: {x: startOffsetX, y: startOffsetY},
                    endOffset: {x: endOffsetX, y: endOffsetY},
                })
            } else {
                const offsetX = e.clientX/zoom - position.x;
                const offsetY = e.clientY/zoom - position.y;
                offsets.push({x: offsetX, y: offsetY})
            }
        })
        setDragOffsets(offsets);
    }, [initialPositions])

    const handleMouseUp = useCallback(() => {
        if (updateElementsPosition && isDragging) updateElementsPosition(positions);
        setIsDragging(false)
        setMouseDown(false)
    }, [isDragging, positions])

    const handleMove = useCallback((e) => {
        if (isDragging && mouseDown) {
            const newPositions = dragOffsets.map(offset => {
                if (offset.startOffset) {
                    if (snap) {
                        return {
                            startPos: {
                                x: Math.round((e.clientX/zoom - offset.startOffset.x) / 20) * 20,
                                y: Math.round((e.clientY/zoom - offset.startOffset.y) / 20) * 20
                            },
                            endPos: {
                                x: Math.round((e.clientX/zoom - offset.endOffset.x) / 20) * 20,
                                y: Math.round((e.clientY/zoom - offset.endOffset.y) / 20) * 20
                            }
                        }
                    } else {
                        return {
                            startPos: {x: e.clientX/zoom - offset.startOffset.x, y: e.clientY/zoom - offset.startOffset.y},
                            endPos: {x: e.clientX/zoom - offset.endOffset.x, y: e.clientY/zoom - offset.endOffset.y}
                        }
                    }
                } else {
                    if (snap) {
                        return {
                            x: Math.round((e.clientX/zoom - offset.x) / 20) * 20,
                            y: Math.round((e.clientY/zoom - offset.y) / 20) * 20,
                        }
                    } else {
                        return {x: e.clientX/zoom - offset.x, y: e.clientY/zoom - offset.y}
                    }
                }
            })
            setPositions(newPositions);
        }
    }, [isDragging, mouseDown])

    const clearPositions = useCallback(() => {
        setPositions([])
    }, [])

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMove, handleMouseUp]);

    return {
        isDragging,
        positions,
        handleMouseDown,
        handleMove,
        handleMouseUp,
        clearPositions
    }
};
