import React, {useEffect, useState} from "react";
import './Frame.css';
import DotsSixVerticalGrey from '../../../assets/icons/DotsSixVerticalGrey.svg';
import Eye from '../../../assets/icons/EyeBlack.svg';
import EyeClosed from '../../../assets/icons/EyeClosed.svg';
import RefreshIcon from '../../../assets/icons/SlidesDownloadSync.svg';
import OpenInSlidesIcon from '../../../assets/icons/ArrowSquareOut.svg';
import {Box, Drawer, IconButton, Typography} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
import {setShowFrameDrawer} from "../../../store/actions/uiActions";
import {OBJECT_TYPES} from "../types";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {APPLY_LOADED_OBJECTS_STATE, updateFrameHidden} from "../../../store/actions";
import {Server} from '../../../api/Server';
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import {Switch} from "../../common/Switch/Switch";
import Checkbox from "../../common/Checkbox/Checkbox";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {FrameClass} from "./FrameClass";
import Button from "../../common/Button/Button";
import {isEqual} from "lodash";

export const StrictModeDroppable = ({children, ...props}) => {
    const [enabled, setEnabled] = useState(false)
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true))
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) return null
    return <Droppable {...props}>{children}</Droppable>
};

export const FrameDrawer = ({setReordering}) => {

    const dispatch = useDispatch();
    const objects = useSelector(state => state.objects);
    const presentationID = useSelector(state => state.ui.presentationID);
    const showFrameDrawer = useSelector(state => state.ui.showFrameDrawer);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const zoom = useSelector(state => state.ui.zoom);

    const [loading, setLoading] = React.useState(false);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
    const [showSnackbar, setShowSnackbar] = useState(null)
    const [presentationFrames, setPresentationFrames] = useState([]);
    const [originalPresentationFrames, setOriginalPresentationFrames] = useState([]);
    const allSelected = selectedCheckboxes.length === presentationFrames.length;

    useEffect(() => {
        const frames = objects.filter(obj => obj.type === OBJECT_TYPES.FRAME).sort((a, b) => a.presentationIndex - b.presentationIndex)
        setPresentationFrames([...frames])
        setOriginalPresentationFrames([...frames])
    }, [objects]);

    useEffect(() => {
        setServer(new Server(workspaceID, userID, token));
    }, [userID, token, workspaceID]);

    const handleClose = () => {
        dispatch(setShowFrameDrawer(false))
    }

    const onDragEnd = (result) => {
        if (!result.destination) return
        const draggedFrame = presentationFrames.filter(frame => frame.id === parseFloat(result.draggableId.split('-')[1]))[0]
        const offset = result.destination.index - draggedFrame.presentationIndex
        setPresentationFrames((prevState) =>
            prevState.map(frame => {
                if (frame.id === draggedFrame.id) return { ...frame, presentationIndex: result.destination.index }
                if (offset > 0 && frame.presentationIndex > draggedFrame.presentationIndex && frame.presentationIndex <= result.destination.index)
                    return { ...frame, presentationIndex: frame.presentationIndex - 1 }
                if (offset < 0 && frame.presentationIndex < draggedFrame.presentationIndex && frame.presentationIndex >= result.destination.index)
                    return { ...frame, presentationIndex: frame.presentationIndex + 1 }
                return frame
            }).sort((a, b) => a.presentationIndex - b.presentationIndex)
        )
    }

    const handleOpenSlides = (presentationIndex) => {
        const googleSlidesURL = `https://docs.google.com/presentation/d/${presentationID}/edit#slide=id.p${presentationIndex}`
        window.open(googleSlidesURL, '_blank')
    }

    const getSlideIDFromFrameID = (frameID) => {
        if (!(typeof frameID === 'string'))
            return frameID;
        let index = frameID.indexOf(':');
        if (index > 0) {
            return frameID.substring(0, index);
        } else {
            return frameID;
        }
    }

    const doesFrameExist = (presentationIndex) => {
        if (objects.length === 0) return false;
        return (objects.find(obj => obj.type === OBJECT_TYPES.FRAME && obj.presentationIndex === presentationIndex) !== undefined);
    }

    const extractBackground = (content) => {
        const regex = /background:([^;]*)/;
        const match = content.match(regex);
        if (match) {
            const colorValue = match[1].trim();
            const endIndex = colorValue.indexOf("'");
            return endIndex !== -1 ? colorValue.substring(0, endIndex) : colorValue;
        }
        return null;
    };

    const handleSyncSlides = () => {
        if (selectedCheckboxes.length === 0) {
            setShowSnackbar({severity: 'warning', msg: 'Please select at least one frame to sync'});
            return;
        }
        const slidesIDs = selectedCheckboxes.map(frameID => getSlideIDFromFrameID(frameID));
        setLoading(true);

        server.postData({
            "action": "getUpdatedSlide",
            "presentationID": presentationID,
            "slideIDs": slidesIDs
        }, (result) => {
            if (result.error) {
                setShowSnackbar({severity: 'error', msg: result.error});
                setLoading(false);
                return;
            }
            let newObjects = [...objects];
            let newFrames = [...objects.filter(obj => obj.type === OBJECT_TYPES.FRAME)];
            let objectsBySlideID = {};

            for (let obj of result.canvasObjects) {
                let objSlideID = Number(obj.id.substring(0, obj.id.indexOf(':')));
                if (!objectsBySlideID[objSlideID]) {
                    objectsBySlideID[objSlideID] = [];
                }
                objectsBySlideID[objSlideID].push(obj);
            }

            Object.keys(objectsBySlideID).forEach(slideID => {
                objectsBySlideID[slideID].sort((a, b) => {
                    let aSlideID = Number(a.id.substring(a.id.indexOf(':') + 1));
                    let bSlideID = Number(b.id.substring(b.id.indexOf(':') + 1));
                    return aSlideID - bSlideID;
                });
            });

            Object.keys(objectsBySlideID).forEach(slideID => {
                if (!doesFrameExist(Number(slideID) - 1)) {
                    const object = objectsBySlideID[slideID][0];
                    const background = extractBackground(object.content);
                    newFrames.push(FrameClass.newFrame(
                        zoom,
                        Number(slideID) - 1,
                        '',
                        object.x,
                        object.y,
                        object.width,
                        object.height,
                        `Slide ${slideID}`,
                        background,
                        Number(slideID)
                    ));
                }
            });

            if (allSelected) {
                newObjects = [...result.canvasObjects];
            } else {
                Object.keys(objectsBySlideID).forEach(slideID => {
                    slideID = Number(slideID);
                    let start = -1;
                    let end = -1;
                    for (let index = 0; index < newObjects.length; index++) {
                        let object = newObjects[index];
                        if (typeof object.id === 'string') {
                            let sep = object.id.indexOf(':');
                            let objSlideID = Number(object.id.substring(0, sep));
                            if (objSlideID === slideID) {
                                if (start < 0) start = index;
                            } else if (start >= 0 && start >= 0) {
                                end = index - 1;
                                break;
                            }
                        }
                    }

                    if (start < 0) start = newObjects.length;
                    if (end < 0) end = newObjects.length - 1;

                    newObjects = [
                        ...newObjects.slice(0, start),
                        ...objectsBySlideID[slideID],
                        ...newObjects.slice(end + 1)
                    ];
                });
            }

            newObjects = [...newFrames, ...newObjects.filter(obj => obj.type !== OBJECT_TYPES.FRAME)];

            dispatch({
                type: 'APPLY_LOADED_OBJECTS_STATE',
                payload: newObjects,
            });

            setSelectedCheckboxes([]);
            setLoading(false);
        }, undefined, () => setLoading(false));
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedCheckboxes([]);
        } else {
            setSelectedCheckboxes(presentationFrames.map(frame => frame.id));
        }
    }

    const isIntersecting = (frame, child) => {
        if (child.type === OBJECT_TYPES.ARROW) {
            const minX = Math.min(child.startInitialPosition.x, child.endInitialPosition.x);
            const minY = Math.min(child.startInitialPosition.y, child.endInitialPosition.y);
            const maxX = Math.max(child.startInitialPosition.x, child.endInitialPosition.x);
            const maxY = Math.max(child.startInitialPosition.y, child.endInitialPosition.y);
            const arrowAABB = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
            const horizontalOverlap = Math.max(0, Math.min(arrowAABB.x + arrowAABB.width, frame.x + frame.width) - Math.max(arrowAABB.x, frame.x));
            const verticalOverlap = Math.max(0, Math.min(arrowAABB.y + arrowAABB.height, frame.y + frame.height) - Math.max(arrowAABB.y, frame.y));
            return horizontalOverlap > 0 && verticalOverlap > 0;
        } else {
            const horizontalOverlap = Math.max(0, Math.min(child.x + child.width, frame.x + frame.width) - Math.max(child.x, frame.x));
            const verticalOverlap = Math.max(0, Math.min(child.y + child.height, frame.y + frame.height) - Math.max(child.y, frame.y));
            return horizontalOverlap > 0 && verticalOverlap > 0;
        }
    };

    const handleConfirmReorder = () => {
        dispatch(setShowFrameDrawer(false))
        setReordering(true)
        const orderedFrames = [...presentationFrames]
        const frames = objects.filter(obj => obj.type === OBJECT_TYPES.FRAME)
        const positions = frames.map(frame => ({x: frame.x, y: frame.y})).sort((a, b) => a.y - b.y || a.x - b.x)
        const importedObjects = objects.filter(obj => obj.type !== OBJECT_TYPES.FRAME && typeof obj.id === 'string')
        const scoopObjects = objects.filter(obj => obj.type !== OBJECT_TYPES.FRAME && typeof obj.id !== 'string')
        let resultObjects = []
        // update all objects positions
        orderedFrames.forEach((frame, i) => {
            // update all imported objects positions
            const oldFramePosition = {x: frame.x, y: frame.y}
            const newFramePosition = {x: positions[i].x, y: positions[i].y}
            if (frame.id % 1 === 0) {
                const importedFrameObjects = importedObjects.filter(obj => parseInt(obj.id.split(':')[0]) === frame.id)
                const scoopFrameObjects = scoopObjects.filter(obj => isIntersecting(frame, obj))
                if (!isEqual(oldFramePosition, newFramePosition)) {
                    // update imported frame shapes
                    importedFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                    // update scoop objects
                    scoopFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                } else {
                    resultObjects = [...resultObjects, ...importedFrameObjects, ...scoopFrameObjects]
                }
            } else {
                const importedFrameObjects = importedObjects.filter(obj => isIntersecting(frame, obj))
                const scoopFrameObjects = scoopObjects.filter(obj => isIntersecting(frame, obj))
                if (!isEqual(oldFramePosition, newFramePosition)) {
                    // update imported frame shapes
                    importedFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                    // update scoop objects
                    scoopFrameObjects.forEach(o => {
                        if (!isEqual(oldFramePosition, newFramePosition)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                } else {
                    resultObjects = [...resultObjects, ...importedFrameObjects, ...scoopFrameObjects]
                }
            }
            frame.x = newFramePosition.x
            frame.y = newFramePosition.y
        })
        dispatch({
            type: APPLY_LOADED_OBJECTS_STATE,
            payload: [...presentationFrames, ...resultObjects]
        });
        setTimeout(() => setReordering(false), 1000)
    }

    const handleCancelReorder = () => {
        setPresentationFrames([...originalPresentationFrames])
    }

    return (
        <Drawer
            anchor={"right"}
            open={showFrameDrawer}
            variant={"persistent"}
            PaperProps={{sx: {marginTop: '115px'}}}
        >
            <Box className={'frame-drawer-content'}>
                <Box className={'frame-drawer-header'}>
                    <Typography className={'inter'} sx={{fontSize: '20px', fontWeight: 600}}>Frames</Typography>
                    <IconButton onClick={handleClose}>
                        <img src={CloseIcon} alt={'close'}/>
                    </IconButton>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0}}>
                    <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                        <Typography className={'inter'} sx={{fontSize: '16px', fontWeight: 600}}>
                            Sync changes
                        </Typography>
                        <Tooltip title="Sync changes in Google Slides back to Scoop">
                            <IconButton onClick={() => handleSyncSlides()}>
                                <img src={RefreshIcon} alt={'refresh'}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <Typography className={'inter'} sx={{fontSize: '12px', fontWeight: 600}}>
                            Select All
                        </Typography>
                        <Switch
                            checked={allSelected}
                            onClick={handleSelectAll}
                        />
                    </Box>
                </Box>
                <Box className={'frame-drawer-body'}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <StrictModeDroppable droppableId={"droppable"}>
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {presentationFrames.map((frame, index) => (
                                        <Draggable key={frame.id} draggableId={'draggable-' + frame.id} index={index}>
                                            {(provided, snapshot) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={'frame-draggable'}
                                                    sx={{boxShadow: snapshot.isDragging ? '0px 0px 7px 0px rgba(20, 9, 42, 0.25)' : ''}}
                                                >
                                                    <img src={DotsSixVerticalGrey} alt={'drag'}
                                                         style={{marginRight: '10px'}}/>
                                                    <Box sx={{flex: 1}}>
                                                        <Typography className={'inter'} sx={{
                                                            width: 'auto',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap'
                                                        }}>
                                                            {(frame.presentationIndex !== undefined ? frame.presentationIndex + 1 + '. ' : '') + frame.title}
                                                        </Typography>
                                                    </Box>
                                                    {
                                                        loading && selectedCheckboxes.includes(frame.id) &&
                                                        <ScoopLoader color="#7442D7" size={36} />
                                                    }
                                                    {
                                                        presentationID && loading !== frame.id &&
                                                        <>
                                                            <Tooltip title="Edit original PowerPoint in Google Slides">
                                                                <IconButton onClick={() => handleOpenSlides(frame.presentationIndex + 1)}>
                                                                    <img src={OpenInSlidesIcon} alt={'open in slides'}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Hide / Un-hide this frame in Presentation Mode">
                                                                <IconButton
                                                                    onClick={() => dispatch(updateFrameHidden(frame.id, !frame.hidden))}>
                                                                    <img src={frame.hidden ? EyeClosed : Eye}
                                                                         alt={'toggle visibility'}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Checkbox
                                                                padding={'6px'}
                                                                size={'small'}
                                                                checked={selectedCheckboxes.includes(frame.id)}
                                                                onClick={() => {
                                                                    if (selectedCheckboxes.includes(frame.id)) {
                                                                        setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== frame.id));
                                                                    } else {
                                                                        setSelectedCheckboxes([...selectedCheckboxes, frame.id]);
                                                                    }
                                                                }}
                                                            />
                                                        </>
                                                    }
                                                </Box>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                </Box>
                {
                    !isEqual(presentationFrames, originalPresentationFrames) &&
                    <Box className={'frame-drawer-footer'}>
                        <Button className={'button-purple frame-drawer-button'} onClick={handleConfirmReorder}>
                            <Typography className={'inter'}>Apply</Typography>
                        </Button>
                        <Button onClick={handleCancelReorder} className={'button-grey frame-drawer-button'}>Cancel</Button>
                    </Box>
                }
            </Box>
            <Snackbar
                open={!!showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert onClose={() => setShowSnackbar(null)} severity={showSnackbar?.severity} variant="filled">
                    {showSnackbar?.msg}
                </Alert>
            </Snackbar>
        </Drawer>
    )
}
