import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { deleteKPI } from '../../../store/actions';
import DeleteDialog from "../../common/Dialog/DeleteDialog";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 80,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function KPIEdit({ isMenuOpen, setIsMenuOpen, title, id }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuRef = React.useRef();
    const buttonRef = React.useRef();
    const [isAlertOpen, setIsAlertOpen] = React.useState(false);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setIsMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        const actionType = event.actionType;

        if (actionType === 'delete') {
            setIsAlertOpen(true);
        } else
        {
            setIsMenuOpen(false);
            setAnchorEl(null);
        }
    };

    const handleDelete = () => {
        // console.log("Deleting KPI: " + id);
        dispatch(deleteKPI(id));
        setIsMenuOpen(false);
        setIsAlertOpen(false);
        setAnchorEl(null);
    };



    return (
        <>
            <div>
                <IconButton aria-label="delete" size="large" onClick={handleClick}>
                    <AutoAwesomeIcon fontSize='large' color="info" />
                </IconButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        <EditIcon />
                        Edit
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                        onClick={(event) => {
                            event.actionType = 'delete';
                            handleClose(event);
                        }}
                        disableRipple
                    >
                        <DeleteIcon />
                        Delete
                    </MenuItem>
                </StyledMenu>
            </div>
            {isAlertOpen && (
                <DeleteDialog
                open={isAlertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                title={title}
                type={'KPI'}
                handleDelete={handleDelete}
                handleCancel={() => setIsAlertOpen(false)}
                />
            )}
        </>
    );
}
