import {useDropzone} from "react-dropzone";
import React, {useEffect, useMemo, useState} from "react";
import {Box, LinearProgress} from "@mui/material";
import Button from "../Button/Button";
import Dialog from "./Dialog";
import {updateImageContent} from "../../../store/actions";
import {useApi} from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function safeS3FileName(str) {
    return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
}

const imageS3Bucket = "https://d3lkv74tdz6isd.cloudfront.net/"


export const UploadImageDialog = ({open, handleClose, id, showAsDialog, onUploadCallback}) => {

    const { postData: postDataWorkspaceActions } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');
    const workspaceID = useSelector(state => state.auth.workspaceID);

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'image/gif': [],
            'image/jpg': [],
            'image/svg+xml': [],
        },
    });

    const dispatch = useDispatch();

    const [progress, setProgress] = useState(0);
    const [snackbar, setSnackbar] = useState(null);
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);

    useEffect(() => {
        let timer;
        if (progress > 0 && progress < 100) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + 3, 100));
            }, 1000);
        } else if (progress >= 100) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const showSnackbar = (message, severity) => {
        setSnackbar({ message, severity });
    };

    const handleUpload = async () => {
        setProgress(1);

        const payload = {
            "action": "uploadImage",
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        try {
            const presignedData = await postDataWorkspaceActions({
                ...payload,
                fileName: safeS3FileName(acceptedFiles[0].name),
                fileType: acceptedFiles[0].type
            });

            await fetch(presignedData.signedRequest, {
                method: 'PUT',
                body: acceptedFiles[0],
                headers: {
                    'Content-Type': acceptedFiles[0].type
                }
            });

            const imageS3Path = `${process.env.REACT_APP_SCOOP_ENV === 'dev' ? `${imageS3Bucket}dev/${workspaceID}/` : `${imageS3Bucket}${workspaceID}/`}${safeS3FileName(acceptedFiles[0].name)}`;

            // console.log("Image uploaded to S3:", imageS3Path);
            id && dispatch(updateImageContent(id, { "imageURL": imageS3Path }));
            setProgress(100);
            showSnackbar("File uploaded successfully! Please wait a few minutes before its data is available.", "success");
            onUploadCallback && onUploadCallback(imageS3Path);
        } catch (error) {
            console.error("Error uploading the file:", error);
            setProgress(0);
            showSnackbar("Error uploading file", "error");
        } finally {
            handleClose();
        }
    };

    const dialogActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button
                onClick={handleClose}
                color="primary"
                text={'Cancel'}
            />
            <Button onClick={handleUpload} className={'primary-button button-purple'} disabled={acceptedFiles.length === 0} text={'Upload'} />
        </Box>
    )

    const renderArea = () => {
        return (
            <section className="container">
                <div {...getRootProps({className: 'dropzone', style})}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop your image file here, or <u>click to select an image file</u></p>
                    <em style={{fontSize: '0.8rem'}}>
                        (Only .PNG, .GIF, .SVG and .JPG files are supported)
                    </em>
                </div>
                {acceptedFiles.length > 0 && (
                    <>
                        <ul>{acceptedFileItems}</ul>
                    </>
                )}
                <LinearProgress variant="determinate" value={progress} style={{marginTop: '20px'}}/>
            </section>
        )
    }

    return (
        <>
            {showAsDialog ?
                <Dialog open={open} onClose={() => handleClose()} title={'Upload image file'} actions={dialogActions}>
                    {renderArea()}
                </Dialog>
                : <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', flex: 1}}>
                    {renderArea()}
                    {dialogActions}
                </Box>
            }

            {snackbar && <Snackbar
                open={!!snackbar}
                autoHideDuration={5000}
                onClose={() => setSnackbar(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <Alert onClose={() => setSnackbar(null)} severity={snackbar?.severity} variant="filled">
                    {snackbar?.message}
                </Alert>
            </Snackbar>}
        </>
    )
}
