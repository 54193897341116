// uiReducer.js
import {
  TOGGLE_INSIGHT_MODAL,
  TOGGLE_SOURCE_MODAL,
  SET_RESET_TRIGGER,
  TOGGLE_FILE_UPLOAD,
  TOGGLE_SNAP,
  CREATE_THUMBNAIL,
  ZOOM_IN,
  ZOOM_OUT,
  SET_BACKGROUND_COLOR,
  SET_LAST_SAVED,
  SET_PRESENTATION_ID,
  FORCE_EXPLORER_DATA_REFRESH,
  SET_ZOOM,
  SET_MAX_SCROLL,
  SET_ACTIVE_MODE,
  SET_SHOW_FRAME_DRAWER,
  SET_SELECTED_OBJECT,
  SET_SHOW_EDIT_ELEMENT_WRAPPER
} from '../actions/uiActions'

const initialState = {
  editInsightOpen: false,
  sourceModalOpen: false,
  resetTrigger: false,
  snapThumbnail: false,
  showFileUpload: false,
  multiSelectElements: [],
  snap: true,
  zoom: 1,
  presentatiobID: null,
  maxScroll: { x: 0, y: 0 },
  forceRefesh: null,
  activeMode: 'cursor',
  worksheetID: null,
  backgroundColor: '#FFF',
  showFrameDrawer: false,
  showEditElementWrapper: [false, undefined],
  selectedObject: ''
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_INSIGHT_MODAL':
      return {
        ...state,
        editInsightOpen: !state.editInsightOpen,
      };
    case 'TOGGLE_SOURCE_MODAL':
      return {
        ...state,
        sourceModalOpen: !state.sourceModalOpen,
      };
    case TOGGLE_SNAP:
      return {
        ...state,
        snap: !state.snap,
      };
    case SET_RESET_TRIGGER:
      return {
        ...state,
        resetTrigger: action.payload,
      };
    case CREATE_THUMBNAIL:
      return {
        ...state,
        snapThumbnail: !state.snapThumbnail,
      };

    case TOGGLE_FILE_UPLOAD:
      return {
        ...state,
        showFileUpload: !state.showFileUpload
      };

    // sheetlet cell change triggers chart refreshes
    case FORCE_EXPLORER_DATA_REFRESH:
      return {
        ...state,
        forceRefresh: !state.forceRefresh,
        worksheetID: action.worksheetID
      };
    case 'HIDE_FILE_UPLOAD':
      return {
        ...state,
        showFileUpload: false
      };
    case ZOOM_IN:
      return {
        ...state,
        zoom: Math.min(2, state.zoom + 0.1)
      };
    case ZOOM_OUT:
      return {
        ...state,
        zoom: Math.max(0.1, state.zoom - 0.1)
      };
    case SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom
      };
      // background color for the entire canvas
    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.color
      };
      case SET_PRESENTATION_ID:
        return {
          ...state,
          presentationID: action.presentationID
        };
    case SET_LAST_SAVED:
      return {
        ...state,
        lastSaved: action.lastSaved
      };
    case SET_MAX_SCROLL:
      return {
        ...state,
        maxScroll: action.maxScroll
      };
    case SET_ACTIVE_MODE:
      return {
        ...state,
        activeMode: action.mode
      };
    case SET_SHOW_FRAME_DRAWER:
      return {...state, showFrameDrawer: action.payload.value}
    case SET_SELECTED_OBJECT:
      return {...state, selectedObject: action.payload.id}
    case SET_SHOW_EDIT_ELEMENT_WRAPPER:
        return {...state, showEditElementWrapper: action.payload.value}
    default:
      return state;

  }
};

export default uiReducer;
