import {Box, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {AXIS_DEFAULT_VALUES, FONT_FAMILIES, FONT_WEIGHTS, AXIS_TEXT_DEFAULT_VALUES} from "../styleConsts";
import {Slider} from "../../../../../common/Slider/Slider";
import {Switch} from "../../../../../common/Switch/Switch";
import {debounce, cloneDeep} from "lodash";
import Input from "../../../../../common/Input/Input";

export const AxisConfig = ({config, setConfig, axis, chartPreferences}) => {

    const [tickFontSize, setTickFontSize] = useState(AXIS_TEXT_DEFAULT_VALUES.fontSize);
    const [nameFontSize, setNameFontSize] = useState(AXIS_TEXT_DEFAULT_VALUES.fontSize);
    const [axisLabelRotation, setAxisLabelRotation] = useState(AXIS_TEXT_DEFAULT_VALUES.rotate);
    const [range, setRange] = useState([AXIS_DEFAULT_VALUES.min, AXIS_DEFAULT_VALUES.max])

    useEffect(() => {
        setTickFontSize(getAxisTextStylePropValue('fontSize', axis, 'tick'))
        setNameFontSize(getAxisTextStylePropValue('fontSize', axis, 'name'))
        setAxisLabelRotation(getAxisTextStylePropValue('rotate', axis, 'tick'))
    }, [config])

    const debouncedFunc = useCallback(debounce((prop, value, axisName, destination) => {
        const newConfig = cloneDeep(config)
        if (!destination) newConfig.styleOverrides[axisName][prop] = value;
        if (destination === 'tick') {
            newConfig.styleOverrides[axisName].axisLabel[prop] = value
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName].axisLabel.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                }
            }
        } else {
            newConfig.styleOverrides[axisName].nameTextStyle[prop] = value
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName].nameTextStyle.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                }
            }
        }
        setConfig(newConfig);
    }, 200), [config]);

    const getAxisPropValue = (prop, axisName) => {
        if (propExistsOnObject(config.styleOverrides[axisName], prop)) return config.styleOverrides[axisName][prop]
        if (propExistsOnObject(chartPreferences[axisName], prop)) return chartPreferences[axisName][prop]
        return AXIS_DEFAULT_VALUES[prop]
    }

    const handleAxisPropValueChange = (prop, value, axisName) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides[axisName][prop] = value
        setConfig(newConfig)
    }

    const getAxisTextStylePropValue = (prop, axisName, destination) => {
        if (destination === 'tick') {
            if (propExistsOnObject(config.styleOverrides[axisName].axisLabel, prop)) return config.styleOverrides[axisName].axisLabel[prop]
            if (propExistsOnObject(chartPreferences[axisName]?.axisLabel, prop)) return chartPreferences[axisName].axisLabel[prop]
            return AXIS_TEXT_DEFAULT_VALUES[prop]
        } else {
            if (propExistsOnObject(config.styleOverrides[axisName].nameTextStyle, prop)) return config.styleOverrides[axisName].nameTextStyle[prop]
            if (propExistsOnObject(chartPreferences[axisName]?.nameTextStyle, prop)) return chartPreferences[axisName].nameTextStyle[prop]
            return AXIS_TEXT_DEFAULT_VALUES[prop]
        }
    }

    const handleAxisTextStylePropChange = (prop, value, axisName, destination) => {
        const newConfig = cloneDeep(config)
        if (destination === 'tick') {
            newConfig.styleOverrides[axisName].axisLabel[prop] = value;
        } else {
            newConfig.styleOverrides[axisName].nameTextStyle[prop] = value;
        }
        setConfig(newConfig);
    };

    const handleDebouncedAxisTextStylePropChange = (prop, value, axisName, destination, setter) => {
        debouncedFunc(prop, value, axisName, destination)
        setter(value)
    }

    const handleDebouncedAxisStylePropChange = (prop, value, axisName) => {
        debouncedFunc(prop, value, axisName)
        if (prop === 'min') setRange([value, range[1]])
        else setRange([range[0], value])
    }

    const showAxis = getAxisPropValue('show', axis)

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            <Box className={'column'} sx={{gap: '8px'}}>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showAxis}
                        onClick={() => handleAxisPropValueChange('show', !showAxis, axis)}
                    />
                    <Typography className={'inter style-config-label'}>Show {axis}</Typography>
                </Box>
            </Box>
            <Box className={'row'} sx={{gap: '8px', alignItems: 'center'}}>
                <Input
                    disableMargin
                    type={'number'}
                    label={'Min'}
                    value={range[0]}
                    onChange={(e) => handleDebouncedAxisStylePropChange('min', e.target.value || undefined, axis)}

                />
                <Input
                    disableMargin
                    type={'number'}
                    label={'Max'}
                    value={range[1]}
                    onChange={(e) => handleDebouncedAxisStylePropChange('max', e.target.value || undefined, axis)}
                />
            </Box>
            {axis !== 'xAxis' &&
                <Box className={'column'} sx={{borderTop: '1px solid #E6E4E6', gap: '16px', padding: '16px 0 0 0'}}>
                    <Typography className={'inter style-config-label'}>Axis name</Typography>
                    {
                        renderSelector(
                            'Font family',
                            getAxisTextStylePropValue('fontFamily', axis, 'name'),
                            (v) => handleAxisTextStylePropChange('fontFamily', v, axis, 'name'),
                            FONT_FAMILIES
                        )
                    }
                    {
                        renderSelector(
                            'Font weight',
                            getAxisTextStylePropValue('fontWeight', axis, 'name'),
                            (v) => handleAxisTextStylePropChange('fontWeight', v, axis, 'name'),
                            FONT_WEIGHTS
                        )
                    }
                    <Slider
                        label={`Font size: ${nameFontSize}px`}
                        value={nameFontSize}
                        min={8}
                        max={64}
                        step={1}
                        onChange={(v) => handleDebouncedAxisTextStylePropChange('fontSize', v, axis, 'name', setNameFontSize)}
                    />
                    {
                        renderColorPicker(
                            'Font color',
                            getAxisTextStylePropValue('color', axis, 'name'),
                            (v) => handleAxisTextStylePropChange('color', v, axis, 'name'),
                        )
                    }
                    {
                        renderColorPicker(
                            'Background color',
                            getAxisTextStylePropValue('backgroundColor', axis, 'name'),
                            (v) => handleAxisTextStylePropChange('backgroundColor', v, axis, 'name'),
                        )
                    }
                </Box>
            }
            <Box className={'column'} sx={{borderTop: '1px solid #E6E4E6', gap: '16px', padding: '16px 0 0 0'}}>
                <Typography className={'inter style-config-label'}>Axis label</Typography>
                {
                    renderSelector(
                        'Font family',
                        getAxisTextStylePropValue('fontFamily', axis, 'tick'),
                        (v) => handleAxisTextStylePropChange('fontFamily', v, axis,'tick'),
                        FONT_FAMILIES
                    )
                }
                {
                    renderSelector(
                        'Font weight',
                        getAxisTextStylePropValue('fontWeight', axis, 'tick'),
                        (v) => handleAxisTextStylePropChange('fontWeight', v, axis,'tick'),
                        FONT_WEIGHTS
                    )
                }
                <Slider
                    label={`Font size: ${tickFontSize}px`}
                    value={tickFontSize}
                    min={8}
                    max={64}
                    step={1}
                    onChange={(v) => handleDebouncedAxisTextStylePropChange('fontSize', v, axis,'tick', setTickFontSize)}
                />
                {
                    renderColorPicker(
                        'Font color',
                        getAxisTextStylePropValue('color', axis,'tick'),
                        (v) => handleAxisTextStylePropChange('color', v, axis, 'tick'),
                    )
                }
                <Slider
                    label={`Rotation: ${axisLabelRotation}°`}
                    value={axisLabelRotation}
                    min={-90}
                    max={90}
                    step={1}
                    onChange={(v) => handleDebouncedAxisTextStylePropChange('rotate', v, axis, 'tick', setAxisLabelRotation)}
                />
            </Box>
        </Box>
    )
}
