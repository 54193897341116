import React, {useEffect, useMemo, useState} from "react";
import './Frame.css';
import {useDispatch, useSelector} from "react-redux";
import {Box, Checkbox, checkboxClasses, IconButton, LinearProgress, Tooltip, Typography} from "@mui/material";
import TrashRed from "../../../assets/icons/TrashRed.svg";
import Image from "../../../assets/icons/Image.svg";
import Camera from "../../../assets/icons/Camera.svg";
import SidebarRight from "../../../assets/icons/SidebarRight.svg";
import XCircle from "../../../assets/icons/XCircle.svg";
import {
    deleteFrame, updateAllFramesBackgroundImage,
    updateFrameBackgroundColor,
    updateFrameBackgroundImage,
    updateFrameSize
} from "../../../store/actions";
import {ScoopColorPicker} from "../../common/ScoopColorPicker/ScoopColorPicker";
import {SizePicker} from "../../common/SizePicker/SizePicker";
import {setShowFrameDrawer, setZoom} from "../../../store/actions/uiActions";
import DeleteDialog from "../../common/Dialog/DeleteDialog";
import html2canvas from "html2canvas";
import sha256 from "crypto-js/sha256";
import {useParams} from "react-router-dom";
import {useApi} from "../../../api/api";
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import Dialog from "../../common/Dialog/Dialog";
import {useDropzone} from "react-dropzone";
import Button from "../../common/Button/Button";
import CheckboxIcon from "../../../assets/icons/Checkbox.svg";

const imageS3Bucket = "https://d3lkv74tdz6isd.cloudfront.net/"

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
// when user uploads an image to S3, make sure the filename is safe
function safeS3FileName(str) {
    return str.replace(/[^a-zA-Z0-9._-]/g, '_').replace(/ /g, '_');
}

export const EditFrameMenu = ({frame, handleDelete}) => {

    const objects = useSelector(state => state.objects);
    const userID = useSelector(state => state.auth.userID);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const canvasName = useSelector((state) => state.auth.canvasName);
    const zoom = useSelector(state => state.ui.zoom);
    const lastSaved = useSelector(state => state.ui.lastSaved);
    const backgroundColor = useSelector(state => state.ui.backgroundColor || '#ffffff');
    const presentationID = useSelector(state => state.ui.presentationID);
    const { canvasID } = useParams();
    const dispatch = useDispatch();
    const { postData } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/canvasV2');
    const { postData: postDataWorkspaceactions } = useApi('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/workspaceactions');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [thumbLoading, setThumbLoading] = useState(false);
    const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [imageUrl, setImageUrl] = useState('');
    const [applyToAllFrames, setApplyToAllFrames] = useState(false);

    useEffect(() => {
        let timer;
        if (progress > 0 && progress < 100) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + 3, 100));
            }, 1000);
        } else if (progress >= 100) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [progress]);

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            'image/png': [],
            'image/jpeg': [],
            'image/gif': [],
            'image/jpg': [],
            'image/svg+xml': [],
        },
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const handleDeleteFrame = () => {
        handleDelete()
        dispatch(deleteFrame(frame.id))
        setDeleteOpen(false)
    }

    const captureThumbnail = () => {
        const frameElement = document.getElementById(`FrameElement-${frame.id}`)
        const scoopCanvas = document.getElementById('scoop-canvas')
        const canvasScrollable = document.getElementById('canvas-scrollable')
        if (!frameElement || !scoopCanvas) return
        const frameX = frame.x + canvasScrollable.scrollLeft
        const frameY = frame.y - 115 + canvasScrollable.scrollTop
        const frameWidth = frame.width
        const frameHeight = frame.height
        setThumbLoading(true)
        html2canvas(scoopCanvas, {
            useCORS: true,
            allowTaint: true,
            x: frameX,
            y: frameY,
            width: frameWidth,
            height: frameHeight,
        })
            .then((capturedCanvas) => {
                const thumbnail = capturedCanvas.toDataURL("image/png")
                const checksum = sha256(JSON.stringify(objects)).toString()
                const action = {
                    "action": "saveCanvas",
                    "userID": userID,
                    "workspaceID": workspaceID,
                    "canvasID": canvasID,
                    "canvasName": canvasName,
                    "canvasObjects": objects,
                    "canvasImage": thumbnail,
                    "zoom": zoom,
                    "lastSaved" : lastSaved,
                    "background": backgroundColor,
                    "presentationID": presentationID,
                    "checksum": checksum,
                    "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
                };
                postData(action).then(() => setThumbLoading(false))
            })
            .catch((error) => console.error('Error capturing frame:', error))
    }

    const setAsThumbnail = () => {
        const currentZoom = zoom
        if (zoom !== 1) {
            dispatch(setZoom(1))
            setTimeout(() => {
                captureThumbnail()
                setTimeout(() => dispatch(setZoom(currentZoom)), 500)
            }, 500)
        } else {
            captureThumbnail()
        }
    }

    const handleUpload = async () => {
        setProgress(1)
        let payload = {
            "action": "uploadImage",
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        try {
            const presignedData = await postDataWorkspaceactions({
                ...payload,
                fileName: safeS3FileName(acceptedFiles[0].name),
                fileType: acceptedFiles[0].type
            });
            await fetch(presignedData.signedRequest, {
                method: 'PUT',
                body: acceptedFiles[0],
                headers: {'Content-Type': acceptedFiles[0].type}
            });
            setProgress(100);

            const imageS3Path = `${process.env.REACT_APP_SCOOP_ENV === 'dev' ? `${imageS3Bucket}dev/${workspaceID}/` : `${imageS3Bucket}${workspaceID}/`}${safeS3FileName(acceptedFiles[0].name)}`;


            setImageUrl(imageS3Path)
        } catch (error) {
            console.error("Error uploading the file:", error);
            setProgress(0);  // Reset the progress
        }
    };

    const handleSave = () => {
        if (applyToAllFrames) {
            dispatch(updateAllFramesBackgroundImage(imageUrl))
        } else {
            dispatch(updateFrameBackgroundImage(frame.id, imageUrl))
        }
        setBackgroundModalOpen(false)
        setImageUrl('')
    }

    const backgroundActions = (
        <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} gap={'8px'}>
            <Button onClick={()=> {
                setBackgroundModalOpen(false)
                setImageUrl('')
            }} text={'Cancel'}/>
            <Button
                onClick={imageUrl ? handleSave : handleUpload}
                className={'button-purple'}
                disabled={acceptedFiles.length === 0}
                text={imageUrl ? 'Save' : 'Upload'}
            />
        </Box>
    )

    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <Box className={'frame-menu'} id={'frame-menu'}>
            <ScoopColorPicker
                height={20}
                width={20}
                value={frame.backgroundColor}
                origin={'top'}
                onChange={(color) => dispatch(updateFrameBackgroundColor(frame.id, color))}
                tooltip
            />
            <Tooltip title={'Background image'}>
                <IconButton onClick={() => setBackgroundModalOpen(true)}>
                    <img src={Image} alt={'image'} />
                </IconButton>
            </Tooltip>
            {
                frame.backgroundImage &&
                <Tooltip title={'Remove background image'}>
                    <IconButton onClick={() => dispatch(updateFrameBackgroundImage(frame.id, ''))}>
                        <img src={XCircle} alt={'remove'} style={{height: 22, width: 22}} />
                    </IconButton>
                </Tooltip>
            }
            <SizePicker
                origin={'top'}
                value={{width: frame.width, height: frame.height}}
                onChange={(size) => dispatch(updateFrameSize(frame.id, size))}
                tooltip
            />
            <Box sx={{width: '2px', height: '20px', backgroundColor: '#F2F2F2', borderRadius: '2px'}} />
            <Tooltip title={'Manage frames'}>
                <IconButton onClick={() => dispatch(setShowFrameDrawer(true))}>
                    <img src={SidebarRight} alt={'manage frames'}/>
                </IconButton>
            </Tooltip>
            <Tooltip title={'Set as thumbnail'}>
                {
                    thumbLoading ?
                        <ScoopLoader /> :
                        <IconButton onClick={setAsThumbnail}>
                            <img src={Camera} alt={'camera'} />
                        </IconButton>
                }
            </Tooltip>
            <Box sx={{width: '2px', height: '20px', backgroundColor: '#F2F2F2', borderRadius: '2px'}} />
            <Tooltip title={'Delete'}>
                <IconButton onClick={() => setDeleteOpen(true)}>
                    <img src={TrashRed} alt={'delete'}/>
                </IconButton>
            </Tooltip>
            <DeleteDialog
                handleCancel={() => setDeleteOpen(false)}
                title={'Frame'}
                type={'frame'}
                open={deleteOpen}
                handleDelete={handleDeleteFrame}
            />
            <Dialog
                open={backgroundModalOpen}
                onClose={() => {
                    setBackgroundModalOpen(false)
                    setImageUrl('')
                }}
                title={imageUrl ? 'Preview' : 'Frame background'}
                actions={backgroundActions}
            >
                {
                    imageUrl ?
                        <Box>
                            <Box
                                className={'preview-image-container'}
                                sx={{
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundSize: '100% 100%',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={'10px'}>
                                <Checkbox
                                    sx={{
                                        height: 10,
                                        width: 10,
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: '#E50B54',
                                        },
                                    }}
                                    checked={applyToAllFrames}
                                    onClick={() => setApplyToAllFrames(!applyToAllFrames)}
                                    size={"medium"}
                                    icon={<img src={CheckboxIcon} alt={'CheckBox'}/>}
                                />
                                <Typography className={'inter'} sx={{marginLeft: '8px', fontSize: 14}}>
                                    Apply to all frames
                                </Typography>
                            </Box>
                        </Box> :
                        <Box>
                            <div {...getRootProps({ className: 'dropzone', style })}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop your image file here, or <u>click to select an image file</u></p>
                                <em style={{ fontSize: '0.8rem' }}>
                                    (Only .PNG, .GIF, .SVG and .JPG files are supported)
                                </em>
                            </div>
                            {acceptedFiles.length > 0 && (
                                <>
                                    <ul>{acceptedFileItems}</ul>
                                </>
                            )}
                            <LinearProgress variant={'determinate'} value={progress} style={{marginTop: '20px'}} />
                        </Box>
                }
            </Dialog>
        </Box>
    )
}
