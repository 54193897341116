import {OBJECT_TYPES} from "../types";

export const Insight_MIN_SIZE = {"width": 600, "height": 400};

export class TextEditorClass {
    constructor(id, title, x, y, width, height, content, border) {
        this.id = id;
        this.title = title;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.type = OBJECT_TYPES.TEXT;
        this.content = content;
        this.border = border;
    }

    // initialize w sample data from a working chart
    static newTextEditor(title, zoom, x, y) {
        const canvasScrollElement = document.getElementById('canvas-scrollable')
        return new TextEditorClass(
            Math.random(),
            title || 'My Text Editor',
            x ? x : (200 + (canvasScrollElement ? canvasScrollElement.scrollLeft : 0)) / zoom,
            y ? y : (200 + (canvasScrollElement ? canvasScrollElement.scrollTop : 0)) / zoom,
            '300px',
            '50px',
            '',
            '1px solid #000000'
        );
    }
}
