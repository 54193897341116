import {Box, Grid, Typography} from "@mui/material";
import {TableBox} from "./TableBox";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Server} from "../../../../api/Server";
import Dialog from "../../../common/Dialog/Dialog";
import Button from "../../../common/Button/Button";
import {DataGrid} from "../../../common/DataGrid/DataGrid";


export const TablesInsight = ({inboxID, tables, datasetName, setAlert, getMetadata, readOnly}) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const [openCompareTable, setOpenCompareTable] = useState(false)
    const [compareResult, setCompareResult] = useState(null)
    const [server, setServer] = useState(new Server(workspaceID, userID, token));
    useEffect(() => {
        setServer(new Server(workspaceID, userID, token))
    }, [workspaceID, userID, token])

    const processInboxTableCompare = (result) => {
        setOpenCompareTable(true)
        setCompareResult(result);
    }
    const compareInboxTables = (inboxID) => {
        server.postData({
            "action": "compareReportSeriesTables", "inboxID": inboxID
        }, processInboxTableCompare);

    }


    return (<>
            <Box sx={{paddingLeft: '16px'}}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                        sx={{
                            overflow: 'hidden', color: '#635566', textOverflow: 'ellipsis', fontSize: ' 16px',
                        }}
                    >{`${tables?.length} tables found from this datasource`}</Typography>

                    {tables?.length >= 2 &&
                        <Button className={'button-grey small'} onClick={() => compareInboxTables(inboxID)}>Compare
                            Tables</Button>}            </Box>
                <Grid container spacing={2} sx={{paddingTop: '32px', gap: '16px'}}>
                    {tables.map((table) => <TableBox
                        getMetadata={getMetadata}
                        table={table}
                        server={server}
                        setAlert={setAlert}
                        readOnly={readOnly}
                    >
                    </TableBox>)}
                </Grid>

            </Box>
            <Dialog
                title={'Compare Tables'}
                open={openCompareTable}
                onClose={() => setOpenCompareTable(false)} style={{minWidth: '1000px'}}
                actions={<Button
                    className={'button-purple small'}
                    onClick={() => setOpenCompareTable(false)}
                >
                    Close
                </Button>}
                PaperProps={{
                    sx: {
                        maxWidth: 'none'
                    }
                }}
            >
                <Typography
                    sx={{
                        overflow: 'hidden', color: '#635566', textOverflow: 'ellipsis', fontSize: ' 16px',
                    }}
                > {`Compare the formatting of the ${tables?.length} tables found in ${datasetName}`}</Typography>
                <DataGrid
                    rows={compareResult?.rows}
                    columns={compareResult?.columns}
                    disableColumnMenu={true}
                    pagination={false}
                    hideFooter
                />

            </Dialog>
        </>)
}