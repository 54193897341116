import React, {useCallback, useEffect, useRef, useState} from "react";
import './Prompt.css';
import {ElementWrapper} from "../../common/ElementWrapper/ElementWrapper";
import {useSelector} from "react-redux";

import IconButton from "@mui/material/IconButton";
import DotsThree from '../../../assets/icons/DotsThree.svg';
import {Box, Typography, Button, MenuItem, Chip, Menu} from "@mui/material";
import {setLastEditedPrompt, setPromptSelecting, setPromptSelectingObjects} from "../../../store/actions/promptActions";
import {ScoopDatePicker} from "../../common/DatePicker/ScoopDatePicker";
import Check from "../../../assets/icons/Check.svg";
import Selector from "../../common/Selector/Selector";
import {useApi} from "../../../api/api";
import PencilSimple from "../../../assets/icons/PencilSimple.svg";
import TrashRed from "../../../assets/icons/TrashRed.svg";
import {debounce} from "lodash";
import {NumericRange} from "../../common/NumericRange/NumericRange";
import {Toast} from "../../common/Toast/Toast";

export const PromptElementSharing = ({
                                         id,
                                         promptProps,
                                         onPromptPropsChange,
                                         promptShareIndex
                                     }) => {
    const promptId = `PromptElement-${id}`
    const {postData} = useApi();
    const menuButton = useRef();
    const activeMode = useSelector(state => state.ui.activeMode);

    const [openMenu, setOpenMenu] = useState(false);
    const [categoryValues, setCategoryValues] = useState([]);
    const [categoryValuesLoading, setCategoryValuesLoading] = useState(false);
    const [singleSelectValue, setSingleSelectValue] = useState('');
    const [multipleSelectValue, setMultipleSelectValue] = useState([]);
    const [dateValue, setDateValue] = useState(null);
    const [numericRange, setNumericRange] = useState(null);
    const [alert, setAlert] = useState(false);
    const selectDebounce = useCallback(debounce((value) => {
        const newPrompt = {...promptProps}
        newPrompt.value = value
        if (promptProps.type === 'multi-select') {
            newPrompt.prompt.filterValue.values = value.length === categoryValues.length ? ['All'] : value
        } else {
            newPrompt.prompt.filterValue.values[0] = value
        }
        onPromptPropsChange(newPrompt, id, promptShareIndex);
        // dispatch(updatePromptProps(id, newPrompt))
        // dispatch(setLastEditedPrompt(newPrompt))
    }, 200), [promptProps, onPromptPropsChange]);

    const getCategoryValues = (tableID, columnName, likeValue) => {
        setCategoryValuesLoading(true)
        const action = {
            "action": "getCategoryValues",
            "reportSeriesTableID": tableID,
            "columnName": columnName,
            "like": likeValue
        };
        postData(action)
            .then(r => {
                setCategoryValuesLoading(false)
                setCategoryValues(r.values)
            })
            .catch(() => {
                setCategoryValuesLoading(false)
                setCategoryValues([])
                setAlert(true)
            })
    }

    useEffect(() => {
        if (promptProps.dataSourceId && promptProps.fieldName && !['data-range', 'single-date'].includes(promptProps.type)) {
            getCategoryValues(promptProps.dataSourceId, promptProps.fieldName)
        }
    }, [promptProps.fieldName, promptProps.dataSourceId])

    useEffect(() => {
        switch (promptProps.type) {
            case 'single-select':
                setSingleSelectValue(promptProps.value || '')
                break
            case 'multi-select':
                setMultipleSelectValue(promptProps.value || [])
                break
            case 'single-date':
                setDateValue(promptProps.value ? new Date(promptProps.value) : null)
                break
            case 'date-range':
                setDateValue(promptProps.value ? [new Date(promptProps.value[0]), new Date(promptProps.value[1])] : null)
                break
            case 'numeric-range':
                setNumericRange(promptProps.value || null)
                break
        }
    }, [promptProps])

    const renderMultipleValues = (values) => {
        return values.map(value =>
            <Chip
                key={value}
                label={value}
                onMouseDown={(e) => {
                    if (['svg', 'path'].includes(e.target.tagName)) e.stopPropagation()
                }}
                onDelete={() => {
                    let newValues = [...multipleSelectValue]
                    newValues.splice(newValues[newValues.indexOf(value)], 1)
                    setMultipleSelectValue(newValues)
                    selectDebounce(newValues)
                }}
                sx={{maxWidth: 150}}
            />
        )
    }

    const handleDateChange = (value) => {
        setDateValue(value)
        const newPrompt = {...promptProps}
        if (newPrompt.type === 'date-range') {
            newPrompt.value = [value[0].toString(), value[1].toString()]
            newPrompt.prompt[0].filterValue.values[0] = value[0].toISOString().split('T')[0]
            newPrompt.prompt[1].filterValue.values[0] = value[1].toISOString().split('T')[0]
        } else {
            newPrompt.value = value.toString()
            newPrompt.prompt.filterValue.values[0] = value.toISOString().split('T')[0]
        }
        // dispatch(updatePromptProps(id, newPrompt))
        // dispatch(setLastEditedPrompt(newPrompt))
    }

    const handleNumericChange = (value) => {
        setNumericRange(value)
        const newPrompt = {...promptProps}
        newPrompt.value = value
        newPrompt.prompt[0].filterValue.values[0] = value[0]
        newPrompt.prompt[1].filterValue.values[0] = value[1]
        // dispatch(updatePromptProps(id, newPrompt))
        // dispatch(setLastEditedPrompt(newPrompt))
    }

    const handleSelectChange = (value) => {
        setSingleSelectValue(value)
        selectDebounce(value)
    }

    const handleMultipleSelect = (e) => {
        if (e.target.value.includes('Select all')) {
            setMultipleSelectValue([...categoryValues])
            selectDebounce([...categoryValues])
        } else if (e.target.value.includes('Clear all')) {
            setMultipleSelectValue([])
            selectDebounce([])
        } else {
            setMultipleSelectValue(e.target.value)
            selectDebounce(e.target.value)
        }
    }

    const getRange = (prompt) => {
        const startDate = prompt[0].filterValue.values[0];
        const endDate = prompt[1].filterValue.values[0];
        return [startDate, endDate]
    }

    const getNames = () => {
        return multipleSelectValue
    }

    const getPromptContent = () => {
        if (promptProps.dataSourceId && promptProps.fieldName && promptProps.type) {
            switch (promptProps.type) {
                case 'single-select':
                    return (
                        <Box sx={{width: '100%', marginTop: '-20px'}}>
                            <Selector
                                value={singleSelectValue || ''}
                                renderValue={(v) => v}
                                sx={{height: 38, fontFamily: 'Inter, sans-serif', fontSize: '14px'}}
                                MenuProps={{
                                    sx: {maxHeight: 350},
                                    container: document.getElementById('slide-container')
                                }}
                            >
                                {
                                    ['All', ...categoryValues].map(value =>
                                        <MenuItem key={value} onClick={() => handleSelectChange(value)}
                                                  sx={{justifyContent: 'space-between'}}>
                                            <Typography className={'inter'} sx={{
                                                fontSize: '14px',
                                                width: 300,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                {value}
                                            </Typography>
                                            {singleSelectValue === value && <img src={Check} alt={'check'}/>}
                                        </MenuItem>
                                    )
                                }
                            </Selector>
                        </Box>
                    )
                case 'multi-select':
                    return (
                        <Box sx={{width: '100%', marginTop: '-20px'}}>
                            <Selector
                                value={getNames() || []}
                                onChange={handleMultipleSelect}
                                renderValue={renderMultipleValues}
                                labelClassName={'selector-label-bold'}
                                sx={{
                                    height: 38,
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    '& .MuiSelect-select': {
                                        padding: '2px !important',
                                        gap: '2px',
                                        maxWidth: '428px',
                                        overflow: 'scroll'
                                    },
                                    '& .MuiFormControl-root': {marginTop: '0px !important'},
                                }}
                                MenuProps={{
                                    sx: {maxHeight: 350},
                                    container: document.getElementById('slide-container')
                                }}
                                multiple
                            >
                                {
                                    [multipleSelectValue.length === categoryValues.length ? 'Clear all' : 'Select all', ...categoryValues].map(value => {
                                        const selected = multipleSelectValue.includes(value)
                                        return (
                                            <MenuItem key={value} value={value} sx={{justifyContent: 'space-between'}}>
                                                <Typography className={'inter'} sx={{
                                                    fontSize: '14px',
                                                    width: 300,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>
                                                    {value}
                                                </Typography>
                                                {selected && <img src={Check} alt={'check'}/>}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Selector>
                        </Box>
                    )
                case 'single-date':
                case 'date-range':
                    return (
                        <ScoopDatePicker
                            value={[new Date(promptProps.value[0]), new Date(promptProps.value[1])]}
                            onChange={handleDateChange}
                            range={promptProps.type === 'date-range'}
                        />
                    )
                case 'numeric-range':
                    return (
                        <NumericRange
                            value={getRange(promptProps.prompt)}
                            onChange={handleNumericChange}
                            max={parseInt(categoryValues[categoryValues.length - 1])}
                            min={categoryValues[0] !== null ? parseInt(categoryValues[0]) : 0}
                        />
                    )
            }
        }
    }

    return (
        <Box sx={{height: 80}}>
            <Box sx={{padding: '12px 0px !important'}} className={'prompt-container'}>
                <Box className={'prompt-header'}>
                    <Typography sx={{fontSize: '14px', fontWeight: 600}}
                                className={'inter'}>{promptProps.label || 'Untitled prompt'}</Typography>
                </Box>
                {getPromptContent()}
            </Box>
            {
                alert &&
                <Toast
                    alert={{message: "Error fetching values, try again later.", severity: "error"}}
                    onClose={() => setAlert(false)}
                />
            }
        </Box>
    )
}
