import { KPIClass } from '../../components/Objects/KPI/KPIClass';
import { WorksheetClass } from '../../components/Objects/Worksheet/WorksheetClass';
import { InsightClass } from '../../components/Objects/Insight/InsightClass';
import { ImageClass } from '../../components/Objects/Image/ImageClass';
import { VideoClass } from '../../components/Objects/Video/VideoClass';
import { SheetletClass } from '../../components/Objects/Sheetlet/SheetletClass';
import {TextEditorClass} from "../../components/Objects/TextEditor/TextEditorClass";
import {ArrowClass} from "../../components/Objects/Arrow/ArrowClass";
import { ProcessDiagramClass } from '../../components/Objects/ProcessDiagram/ProcessDiagramClass';
import {PromptClass} from "../../components/Objects/Prompt/PromptClass";
import {FrameClass} from "../../components/Objects/Frame/FrameClass";

export const SEND_TO_BACK = 'SEND_TO_BACK';
export const BRING_TO_FRONT = 'BRING_TO_FRONT';
export const ADD_KPI = 'ADD_KPI';
export const UPDATE_KPI_POSITION = 'UPDATE_KPI_POSITION';
export const UPDATE_KPI_SIZE = 'UPDATE_KPI_SIZE';
export const UPDATE_KPI_TITLE = 'UPDATE_KPI_TITLE';
export const DELETE_KPI = 'DELETE_KPI';
export const ADD_Worksheet = 'ADD_Worksheet';
export const UPDATE_Worksheet_POSITION = 'UPDATE_Worksheet_POSITION';
export const UPDATE_Worksheet_SIZE = 'UPDATE_Worksheet_SIZE';
export const UPDATE_Worksheet_TITLE = 'UPDATE_Worksheet_TITLE';
export const DELETE_Worksheet = 'DELETE_Worksheet';

export const ADD_INSIGHT = 'ADD_INSIGHT';
export const UPDATE_INSIGHT_POSITION = 'UPDATE_INSIGHT_POSITION';
export const UPDATE_INSIGHT_SIZE = 'UPDATE_INSIGHT_SIZE';
export const UPDATE_INSIGHT_TITLE = 'UPDATE_INSIGHT_TITLE';
export const DELETE_INSIGHT = 'DELETE_INSIGHT';
export const EDIT_INSIGHT = 'EDIT_INSIGHT';
export const UPDATE_INSIGHT_CONTENT = 'UPDATE_INSIGHT_CONTENT';

export const ADD_PROCESSDIAGRAM = 'ADD_PROCESSDIAGRAM';
export const UPDATE_PROCESSDIAGRAM_POSITION = 'UPDATE_PROCESSDIAGRAM_POSITION';
export const UPDATE_PROCESSDIAGRAM_SIZE = 'UPDATE_PROCESSDIAGRAM_SIZE';
export const UPDATE_PROCESSDIAGRAM_TITLE = 'UPDATE_PROCESSDIAGRAM_TITLE';
export const DELETE_PROCESSDIAGRAM = 'DELETE_PROCESSDIAGRAM';
export const EDIT_PROCESSDIAGRAM = 'EDIT_PROCESSDIAGRAM';
export const UPDATE_PROCESSDIAGRAM_CONTENT = 'UPDATE_PROCESSDIAGRAM_CONTENT';

export const ADD_SHEETLET = 'ADD_SHEETLET';
export const UPDATE_SHEETLET_POSITION = 'UPDATE_SHEETLET_POSITION';
export const UPDATE_SHEETLET_SIZE = 'UPDATE_SHEETLET_SIZE';
export const UPDATE_SHEETLET_TITLE = 'UPDATE_SHEETLET_TITLE';
export const DELETE_SHEETLET = 'DELETE_SHEETLET';
export const EDIT_SHEETLET = 'EDIT_SHEETLET';
export const UPDATE_SHEETLET_CONTENT = 'UPDATE_SHEETLET_CONTENT';
export const UPDATE_SHEETLET_GRID = 'UPDATE_SHEETLET_GRID';
export const UPDATE_SHEETLET_HEADERS = 'UPDATE_SHEETLET_HEADERS';
export const UPDATE_OBJECT_STYLE = 'UPDATE_OBJECT_STYLE';
export const ADD_TEXT_EDITOR = 'ADD_TEXT_EDITOR';
export const DELETE_TEXT_EDITOR = 'DELETE_TEXT_EDITOR';
export const SET_TEXT_EDITOR_TABLE_RANGE = 'SET_TEXT_EDITOR_TABLE_RANGE';
export const ADD_ARROW = 'ADD_ARROW';
export const DELETE_ARROW = 'DELETE_ARROW';
export const UPDATE_ARROW = 'UPDATE_ARROW';
export const UPDATE_TEXT_EDITOR = 'UPDATE_TEXT_EDITOR';
export const UPDATE_CANVASES = 'UPDATE_CANVASES';

export const ADD_IMAGE = 'ADD_IMAGE';
export const UPDATE_IMAGE_POSITION = 'UPDATE_IMAGE_POSITION';
export const UPDATE_IMAGE_SIZE = 'UPDATE_IMAGE_SIZE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const EDIT_IMAGE = 'EDIT_IMAGE';
export const UPDATE_IMAGE_CONTENT = 'UPDATE_IMAGE_CONTENT';

export const ADD_VIDEO = 'ADD_VIDEO';
export const UPDATE_VIDEO_POSITION = 'UPDATE_VIDEO_POSITION';
export const UPDATE_VIDEO_SIZE = 'UPDATE_VIDEO_SIZE';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const EDIT_VIDEO = 'EDIT_VIDEO';
export const UPDATE_VIDEO_CONTENT = 'UPDATE_VIDEO_CONTENT';

export const ADD_PROMPT = 'ADD_PROMPT';
export const DELETE_PROMPT = 'DELETE_PROMPT';
export const UPDATE_PROMPT_POSITION = 'UPDATE_PROMPT_POSITION';
export const UPDATE_PROMPT_SIZE = 'UPDATE_PROMPT_SIZE';
export const UPDATE_PROMPT_PROPS = 'UPDATE_PROMPT_PROPS';

// when we read a saved state via API and want to apply it
export const APPLY_LOADED_OBJECTS_STATE = 'APPLY_LOADED_OBJECTS_STATE';
export const ADD_OBJECTS = 'ADD_OBJECTS';
export const REMOVE_OBJECTS = 'REMOVE_OBJECTS';
export const ADD_FRAME = 'ADD_FRAME';
export const DELETE_FRAME = 'DELETE_FRAME';
export const UPDATE_FRAME_POSITION = 'UPDATE_FRAME_POSITION';
export const UPDATE_FRAME_SIZE = 'UPDATE_FRAME_SIZE';
export const UPDATE_FRAME_TITLE = 'UPDATE_FRAME_TITLE';
export const UPDATE_FRAME_BACKGROUND_COLOR = 'UPDATE_FRAME_BACKGROUND_COLOR';
export const UPDATE_FRAME_PRESENTATION_INDEX = 'UPDATE_FRAME_PRESENTATION_INDEX';
export const UPDATE_FRAME_BACKGROUND_IMAGE = 'UPDATE_FRAME_BACKGROUND_IMAGE';
export const UPDATE_ALL_FRAMES_BACKGROUND_IMAGE = 'UPDATE_ALL_FRAMES_BACKGROUND_IMAGE';
export const UPDATE_FRAME_HIDDEN = 'UPDATE_FRAME_HIDDEN';
export const UPDATE_GENERIC_SHAPE_POSITION = 'UPDATE_GENERIC_SHAPE_POSITION';
export const UPDATE_GENERIC_SHAPE_SIZE = 'UPDATE_GENERIC_SHAPE_SIZE';

export const DELETE_SHAPE = 'DELETE_SHAPE';

export const UPDATE_ELEMENT_TITLE = 'UPDATE_ELEMENT_TITLE';

export const applyLoadedObjectsState = (payload) => ({
  type: APPLY_LOADED_OBJECTS_STATE,
  payload,
});

export const updateElementTitle = (id, title) => ({
    type: UPDATE_ELEMENT_TITLE,
    payload: { id, title },
});

export const addKPI = (payload) => {
  const newKPI = KPIClass.newKPI(payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_KPI,
    payload: newKPI,
  };
};

export const deleteKPI = (id) => ({
  type: DELETE_KPI,
  payload: { id},
});


export const updateKPIPosition = (id, position) => ({
  type: UPDATE_KPI_POSITION,
  payload: { id, position },
});

export const updateKPISize = (id, size) => ({
  type: UPDATE_KPI_SIZE,
  payload: { id, size },
});

export const updateKPITitle = (id, title) => ({
  type: UPDATE_KPI_TITLE,
  payload: { id, title },
});

export const addWorksheet = () => {
  const newWorksheet = WorksheetClass.newWorksheet();
  return {
    type: ADD_Worksheet,
    payload: newWorksheet,
  };
};

export const deleteWorksheet = (id) => ({
  type: DELETE_Worksheet,
  payload: { id},
});


export const updateWorksheetPosition = (id, position) => ({
  type: UPDATE_Worksheet_POSITION,
  payload: { id, position },
});

export const updateWorksheetSize = (id, size) => ({
  type: UPDATE_Worksheet_SIZE,
  payload: { id, size },
});

export const updateWorksheetTitle = (id, title) => ({
  type: UPDATE_Worksheet_TITLE,
  payload: { id, title },
});

export const addInsight = (payload) => {
  const newInsight = InsightClass.newInsight(
      payload?.title,
      payload?.chartProps,
      payload?.zoom || 1,
      payload?.wrapperStyles,
      payload?.x,
      payload?.y
  );
  return {
    type: ADD_INSIGHT,
    payload: newInsight,
  };
};

export const deleteInsight = (id) => ({
  type: DELETE_INSIGHT,
  payload: { id},
});

export const editInsight = (id) => ({
  type: EDIT_INSIGHT,
  payload: { id},
});


export const updateInsightPosition = (id, position) => ({
  type: UPDATE_INSIGHT_POSITION,
  payload: { id, position },
});

export const updateInsightSize = (id, size) => ({
  type: UPDATE_INSIGHT_SIZE,
  payload: { id, size },
});

export const updateInsightTitle = (id, title) => ({
  type: UPDATE_INSIGHT_TITLE,
  payload: { id, title },
});

export const updateInsightContent = (id, content) => ({
  type: UPDATE_INSIGHT_CONTENT,
  payload: { id, content },
});

export const addSheetlet = (payload) => {
  const newSheetlet = SheetletClass.newSheetlet(
      payload?.title,
      payload?.sheetletProps,
      payload.zoom,
      payload?.wrapperStyles,
      payload?.x,
      payload?.y
  );
  return {
    type: ADD_SHEETLET,
    payload: newSheetlet,
  };
};

export const deleteSheetlet = (id) => ({
  type: DELETE_SHEETLET,
  payload: { id},
});

export const editSheetlet = (id) => ({
  type: EDIT_SHEETLET,
  payload: { id},
});


export const updateSheetletPosition = (id, position) => ({
  type: UPDATE_SHEETLET_POSITION,
  payload: { id, position },
});

export const updateSheetletSize = (id, size) => ({
  type: UPDATE_SHEETLET_SIZE,
  payload: { id, size },
});

export const updateSheetletTitle = (id, title) => ({
  type: UPDATE_SHEETLET_TITLE,
  payload: { id, title },
});

export const updateSheetletContent = (id, content) => ({
  type: UPDATE_SHEETLET_CONTENT,
  payload: { id, content },
});

export const updateSheetletGrid = (id, shouldHideGrid) => ({
  type: UPDATE_SHEETLET_GRID,
  payload: { id, shouldHideGrid },
});

export const updateSheetletHeaders = (id, shouldHideHeaders) => ({
  type: UPDATE_SHEETLET_HEADERS,
  payload: { id, shouldHideHeaders },
});

export const updateObjectStyle = (id, wrapperStyles) => ({
    type: UPDATE_OBJECT_STYLE,
    payload: { id, wrapperStyles },
});

export const  addTextEditor = (payload) => {
  const newTextEditor = TextEditorClass.newTextEditor(payload?.title, payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_TEXT_EDITOR,
    payload: newTextEditor,
  };
};

export const deleteTextEditor = (id) => {
  return {
    type: DELETE_TEXT_EDITOR,
    payload:{id},
  }
};

export const setTextEditorTableRange = (id, worksheetId, selectedWorksheetRange, fitData) => {
  return {
    type: SET_TEXT_EDITOR_TABLE_RANGE,
    payload: {id, worksheetId, selectedWorksheetRange, fitData},
  }
}

export const updateTextEditor = (id, content, border) => ({

  type: UPDATE_TEXT_EDITOR,
  payload: {id, content,border},
});

export const addArrow = (payload) => {
  const newArrow = ArrowClass.newArrow(payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_ARROW,
    payload: newArrow,
  };
};

export const deleteArrow = (id) => {
  return {
    type: DELETE_ARROW,
    payload: {id},
  };
};

// update receives an object "update" {key, value}
export const updateArrow = (id, update) => ({
  type: UPDATE_ARROW,
  payload: {id, update},
});

export const updateCanvases = (canvases) => ({
  type: UPDATE_CANVASES,
  payload: canvases,
});

export const addProcessDiagram = (payload) => {
  const newProcessDiagram = ProcessDiagramClass.newProcessDiagram(
      payload?.title,
      payload?.processDiagramProps,
      payload.zoom,
      payload?.wrapperStyles,
      payload?.x,
      payload?.y
  );
  return {
    type: ADD_PROCESSDIAGRAM,
    payload: newProcessDiagram,
  };
};

export const deleteProcessDiagram = (id) => ({
  type: DELETE_PROCESSDIAGRAM,
  payload: { id},
});

export const editProcessDiagram = (id) => ({
  type: EDIT_PROCESSDIAGRAM,
  payload: { id},
});


export const updateProcessDiagramPosition = (id, position) => ({
  type: UPDATE_PROCESSDIAGRAM_POSITION,
  payload: { id, position },
});

export const updateProcessDiagramSize = (id, size) => ({
  type: UPDATE_PROCESSDIAGRAM_SIZE,
  payload: { id, size },
});

export const updateProcessDiagramTitle = (id, title) => ({
  type: UPDATE_PROCESSDIAGRAM_TITLE,
  payload: { id, title },
});

export const updateProcessDiagramContent = (id, content) => ({
  type: UPDATE_PROCESSDIAGRAM_CONTENT,
  payload: { id, content },
});



export const addImage = (payload) => {
  const newImage = ImageClass.newImage(payload?.title, payload?.content, payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_IMAGE,
    payload: newImage,
  };
};

export const deleteImage = (id) => ({
  type: DELETE_IMAGE,
  payload: { id},
});

export const editImage = (id) => ({
  type: EDIT_IMAGE,
  payload: { id},
});


export const updateImagePosition = (id, position) => ({
  type: UPDATE_IMAGE_POSITION,
  payload: { id, position },
});

export const updateImageSize = (id, size) => ({
  type: UPDATE_IMAGE_SIZE,
  payload: { id, size },
});


export const updateImageContent = (id, content) => ({
  type: UPDATE_IMAGE_CONTENT,
  payload: { id, content },
});

export const addVideo = (payload) => {
  const newVideo = VideoClass.newVideo(payload?.title, payload?.content, payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_VIDEO,
    payload: newVideo,
  };
};

export const deleteVideo = (id) => ({
  type: DELETE_VIDEO,
  payload: { id},
});

export const editVideo = (id) => ({
  type: EDIT_VIDEO,
  payload: { id},
});


export const updateVideoPosition = (id, position) => ({
  type: UPDATE_VIDEO_POSITION,
  payload: { id, position },
});

export const updateVideoSize = (id, size) => ({
  type: UPDATE_VIDEO_SIZE,
  payload: { id, size },
});


export const updateVideoContent = (id, content) => ({
  type: UPDATE_VIDEO_CONTENT,
  payload: { id, content },
});





export const addObjects = (objects) => ({
    type: ADD_OBJECTS,
    payload: objects,
})

export const removeObjects = (objectIDs) => ({
    type: REMOVE_OBJECTS,
    payload: objectIDs,
})

export const addPrompt = (payload) => {
  const newPrompt = PromptClass.newPrompt(payload.zoom, payload?.x, payload?.y);
  return {
    type: ADD_PROMPT,
    payload: newPrompt,
  };
};

export const deletePrompt = (id) => ({
  type: DELETE_PROMPT,
  payload: {id},
});

export const updatePromptPosition = (id, position) => ({
  type: UPDATE_PROMPT_POSITION,
  payload: { id, position },
});

export const updatePromptSize = (id, size) => ({
  type: UPDATE_PROMPT_SIZE,
  payload: { id, size },
});

export const updatePromptProps = (id, props) => ({
  type: UPDATE_PROMPT_PROPS,
  payload: { id, props },
});

export const addFrame = (payload) => {
  const newFrame = FrameClass.newFrame(payload.zoom, payload.presentationIndex, '', payload?.x, payload?.y);
  return {
    type: ADD_FRAME,
    payload: newFrame,
  };
};

export const deleteFrame = (id) => ({
  type: DELETE_FRAME,
  payload: {id},
});

export const updateFramePosition = (id, position) => ({
  type: UPDATE_FRAME_POSITION,
  payload: { id, position },
});

export const updateFrameSize = (id, size) => ({
  type: UPDATE_FRAME_SIZE,
  payload: { id, size },
});

export const updateFrameTitle = (id, title) => ({
  type: UPDATE_FRAME_TITLE,
  payload: { id, title },
});

export const updateFrameHidden = (id, hidden) => ({
  type: UPDATE_FRAME_HIDDEN,
  payload: { id, hidden },
});

export const updateFrameBackgroundColor = (id, backgroundColor) => ({
  type: UPDATE_FRAME_BACKGROUND_COLOR,
  payload: { id, backgroundColor },
});

export const updateFrameBackgroundImage = (id, imageUrl) => ({
  type: UPDATE_FRAME_BACKGROUND_IMAGE,
  payload: { id, imageUrl },
});

export const updateAllFramesBackgroundImage = (imageUrl) => ({
  type: UPDATE_ALL_FRAMES_BACKGROUND_IMAGE,
  payload: { imageUrl },
});

export const sendToBack = (id) => ({
  type: SEND_TO_BACK,
  payload: { id },
});

export const bringToFront = (id) => ({
  type: BRING_TO_FRONT,
  payload: { id },
});

export const updateFramePresentationIndex = (result) => ({
  type: UPDATE_FRAME_PRESENTATION_INDEX,
  payload: { result },
});

export const deleteShape = (id) => ({
  type: DELETE_SHAPE,
  payload: {id},
});

export const updateGenericShapePosition = (id, position) => ({
  type: UPDATE_GENERIC_SHAPE_POSITION,
  payload: { id, position },
});

export const updateGenericShapeSize = (id, size) => ({
  type: UPDATE_GENERIC_SHAPE_SIZE,
  payload: { id, size },
});

