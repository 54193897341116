import React, { useState, useCallback, useEffect } from "react";
import './CanvasDashboard.css';
import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import ProjectorScreenPink from '../../../assets/icons/ProjectorScreenPink.svg'
import PlusWhite from "../../../assets/icons/PlusWhite.svg";
import MagnifyingGlass from "../../../assets/icons/MagnifyingGlass.svg";
import Button from "../../common/Button/Button";
import { debounce } from "lodash";
import { NewCanvasDialog } from "./NewCanvasDialog/NewCanvasDialog";
import DeleteDialog from "../../common/Dialog/DeleteDialog";
import { useApi } from "../../../api/api";
import { updateCanvases } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { CanvasBox } from "./CanvasBox/CanvasBox";
import { useNavigate } from 'react-router-dom';
import { setAutoShowTourWorkspace } from '../../../store/actions/authActions';

// these are hardcoded defaults for when there's a new user and we need to kick off the tour
const DEFAULT_CANVAS_NAME = 'Scoop Tour'
const DEFAULT_WORKSPACE_NAME = 'Tour Scoop Workspace'

const tabs = [
    { label: 'All', key: 'all' },
    { label: 'Starred', key: 'starred' },
    { label: 'Recent', key: 'recent' },
    { label: 'Draft', key: 'draft' }
]

export const CanvasDashboard = () => {

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const workspaceName = useSelector((state) => state.auth.workspaceName);
    const userID = useSelector((state) => state.auth.userID);
    const autoShowTourWorkspace = useSelector((state) => state.auth.autoShowTourWorkspace);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [selectedTab, setSelectedTab] = useState(tabs[0].key);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';
    const { postData: postCanvasData } = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);
    const [canvases, setCanvases] = useState([]);
    const [filteredCanvases, setFilteredCanvases] = useState([]);
    const [canvasID, setCanvasID] = useState(null);
    const [openDeleteCanvas, setOpenDeleteCanvas] = useState(false);
    const [openNewCanvasDialog, setOpenNewCanvasDialog] = useState(false);
    const navigate = useNavigate();
    const debounceFn = useCallback(debounce((value) => {
        setFilteredCanvases(canvases.filter(canvas => canvas.canvasName.toLowerCase().includes(value.toLowerCase())))
    }, 1000), [canvases]);
    const [anchorEl, setAnchorEl] = useState(null);
    const listCanvas = useCallback(async () => {
        if (!userID || !workspaceID) return;
        const action = {
            "action": "listCanvases",
            "workspaceID": workspaceID,
            "userID": userID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }
        const result = await postCanvasData(action);
        setCanvases(result)
        setFilteredCanvases(result)
        dispatch(updateCanvases(result))
    }, [postCanvasData, workspaceID, userID]);


    // if user is in Tour workspace and there is only one canvas, navigate to that canvas
    useEffect(() => {
        if (canvases.length === 1 && canvases[0].canvasName === DEFAULT_CANVAS_NAME && workspaceName === DEFAULT_WORKSPACE_NAME && autoShowTourWorkspace) {
            dispatch(setAutoShowTourWorkspace(false))
            navigate(`${canvases[0].canvasID}`);
        }
    }, [canvases])

    useEffect(() => {
        listCanvas()
        const fontLinkElems = document.querySelectorAll('[id^="dynamic-loaded-fonts-"]')
        if (fontLinkElems.length > 0) fontLinkElems.forEach(e => document.head.removeChild(e))
    }, [workspaceID, userID])

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
        debounceFn(event.target.value);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleDeleteDialog = (canvasID) => {
        setCanvasID(canvasID);
        setOpenDeleteCanvas(true);
    };

    const deleteCanvas = async (canvasID) => {
        if (!canvasID || !workspaceID) return;
        const action = {
            "action": "deleteCanvas",
            "workspaceID": workspaceID,
            "canvasID": canvasID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }
        const result = await postCanvasData(action);
        if (result) {
            await listCanvas();
            setOpenDeleteCanvas(false);
            setCanvasID('')
        }
    }

    return (
        <Box className={'canvases-screen-container'}>
            <Box className={'canvases-header'}>
                <Box className={'canvases-pink'}>
                    <img src={ProjectorScreenPink} alt={'Canvases'} />
                </Box>
                <Box className={'header-text'}>
                    <Typography className={'inter'} sx={{ fontSize: '28px', fontWeight: 600 }}>Canvases</Typography>
                </Box>
                <TextField
                    value={search}
                    placeholder={'Search Canvases'}
                    onChange={handleSearchChange}
                    variant={'outlined'}
                    sx={{
                        '& .MuiInputBase-root': {
                            height: '38px !important',
                            width: '400px !important',
                            borderRadius: '5px !important',
                            fontFamily: 'Inter, sans-serif'
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <img src={MagnifyingGlass} alt={'search'} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button className={'button-purple new-canvas'}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                    }}>
                    <Box sx={{ display: 'flex', flex: '1', alignItems: 'center' }}>
                        <img src={PlusWhite} alt={'plus'} />
                        <Typography className={'inter'} sx={{ fontSize: '14px', ml: '10px' }}>New Canvas</Typography>
                    </Box>
                </Button>
            </Box>
            {/*<Tabs*/}
            {/*    value={selectedTab}*/}
            {/*    onChange={handleTabChange}*/}
            {/*    sx={{*/}
            {/*        marginTop: '48px',*/}
            {/*        marginBottom: '24px',*/}
            {/*        '& .MuiTabs-flexContainer': { height: '100%' },*/}
            {/*        '& .MuiTabs-indicator': { backgroundColor: '#E50B54', borderRadius: '15px' },*/}
            {/*        '& .Mui-selected': { opacity: 1, color: '#E50B54 !important' }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {tabs.map((tab) => (*/}
            {/*        <Tab className={'drawer-tab'} key={tab.key} label={tab.label} value={tab.key} disabled={tab.key !== 'all'} />*/}
            {/*    ))}*/}
            {/*</Tabs>*/}
            <Box className={'canvases-container'}>
                {
                    filteredCanvases?.length > 0 && filteredCanvases.map((canvas, index) =>
                        <CanvasBox
                            canvasID={canvas.canvasID}
                            canvasName={canvas.canvasName}
                            thumbnailURL={canvas.thumbnailURL}
                            index={index}
                            handleDeleteDialog={handleDeleteDialog}
                            key={index}
                        />
                    )
                }
            </Box>
            <NewCanvasDialog anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
            <DeleteDialog
                open={openDeleteCanvas}
                title={'Canvas'}
                handleDelete={() => deleteCanvas(canvasID)}
                handleCancel={() => setOpenDeleteCanvas(false)}
                type={'Canvas'}
                description={'Are you sure you want to permanently delete this canvas'}
            />
        </Box>
    )
}
