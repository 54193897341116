import {
    Box,
    DialogContentText,
} from "@mui/material";
import * as React from "react";
import dayjs from 'dayjs';
import Dialog from "../common/Dialog/Dialog";
import Button from "../common/Button/Button";
import {ScoopDatePicker} from "../common/DatePicker/ScoopDatePicker";
import Selector from "../common/Selector/Selector";
import MenuItem from "@mui/material/MenuItem";

const options = [
    {value: 'GreaterThan', label: 'Greater Than'},
    {value: 'LessThan', label: 'Less Than'},
    {value: 'Equals', label: 'Equals'},
    {value: 'Between', label: 'Between'},
    {value: 'GreaterThanOrEquals', label: 'Greater Than or Equals'},
    {value: 'LessThanOrEquals', label: 'Less Than or Equals'}
];


export function TimeRangeDialog({
                                    open, setOpen,
                                    config, setConfig,
                                    chartProperties
                                }) {

    const [selectedDateOption, setSelectedDateOption] = React.useState(options[4].value);
    const [range, setRange] = React.useState(undefined);
    const [singleDate, setSingleDate] = React.useState(undefined);

    const handleDateChange = (e) => {
      setSelectedDateOption(e.target.value);
    }

    function handleClose(event) {
        setOpen(false);
    }

    function handleSave() {
        let from;
        let to;
        if (range === undefined && singleDate === undefined) return;
        if (selectedDateOption !== 'Between') {
            switch (selectedDateOption) {
                case 'GreaterThan':
                    from = dayjs(singleDate).add(1, 'day').format('YYYY-MM-DD');
                    to = undefined;
                    break;
                case 'LessThan':
                    from = undefined;
                    to = dayjs(singleDate).subtract(1, 'day').format('YYYY-MM-DD');
                    break;
                case 'Equals':
                    from = dayjs(singleDate).format('YYYY-MM-DD');
                    to = dayjs(singleDate).format('YYYY-MM-DD');
                    break;
                case 'GreaterThanOrEquals':
                    from = dayjs(singleDate).format('YYYY-MM-DD');
                    to = undefined;
                    break;
                case 'LessThanOrEquals':
                    from = undefined;
                    to = dayjs(singleDate).format('YYYY-MM-DD');
                    break;
            }
        } else {
            from = dayjs(range[0]).format('YYYY-MM-DD');
            to = dayjs(range[1]).format('YYYY-MM-DD');
        }
        config.timeRange = "Custom";
        config.from = from;
        config.to = to;
        chartProperties.getResults(config);
        setOpen(false);
        setConfig({...config});
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleClose} text={'Cancel'}></Button>
            <Button onClick={handleSave} className={'button-purple'} text={'Apply'}></Button>
        </Box>
    )

    return (
        <Dialog style={{width: '500px'}} open={open} onClose={handleClose} title={'Filter Time Range'} actions={actions}>
            <DialogContentText>
                Specify a custom time range
            </DialogContentText>
            <Box sx={{display: 'flex', m: 0, mt: 2, mb: 2, alignItems: 'center', gap: '16px'}}>
                <Selector
                    value={selectedDateOption}
                    label={'Select option'}
                    sx={{height: 36}}
                    labelClassName={'selector-label-bold'}
                    onChange={(e) => handleDateChange(e)}
                >
                    {
                        options.map((option, index) => {
                            return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        })
                    }
                </Selector>
                <ScoopDatePicker
                    label={`Select ${selectedDateOption === 'Between' ? 'range' : 'date'}`}
                    value={selectedDateOption === 'Between' ? range : singleDate}
                    onChange={(newValue) => selectedDateOption === 'Between' ? setRange(newValue) : setSingleDate(newValue)}
                    range={selectedDateOption === 'Between'}
                />
            </Box>
        </Dialog>
    )
}
