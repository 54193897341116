import React, { useState } from 'react';
import kpi from '../../../kpi.svg';
import Input from '@mui/material/Input';
import { useDispatch } from 'react-redux';
import { updateKPIPosition, updateKPISize, updateKPITitle } from '../../../store/actions';
import KPIEdit from './KPIEdit';
import {ElementWrapper} from "../../common/ElementWrapper/ElementWrapper";

const KPI = ({id, title, initialPosition, initialSize, multiProps}) => {

    const ariaLabel = { 'aria-label': 'description' };
    const dispatch = useDispatch();
    const [inputTitle, setInputTitle] = useState(title); // Local state for the input title
    const [isEditMode, setIsEditMode] = useState(false); // Local state for the edit mode
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleTitleChange = (e) => {
        setInputTitle(e.target.value);
        dispatch(updateKPITitle(id, e.target.value)); // Dispatch the UPDATE_KPI_TITLE event
    };

    const updateKPIPos = (position) => {
        dispatch(updateKPIPosition(id, position))
    }

    const updateKPIsz = (size) => {
        dispatch(updateKPISize(id, size))
    }

    return (
        <ElementWrapper
            id={`KPI-${id}`}
            key={`KPI-${id}`}
            editMode={isEditMode}
            toggleEditMode={setIsEditMode}
            initialSize={initialSize}
            initialPosition={initialPosition}
            updateElementPosition={updateKPIPos}
            updateElementSize={updateKPIsz}
            {...multiProps}
        >
            {isEditMode && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <KPIEdit isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} title={title} id={id}/>
                </div>
            )}
            <div style={{ height: '40px', width: '50%' }}>
                <Input
                    style={{ width: '100%' }} // Add this line
                    placeholder={title}
                    inputProps={ariaLabel}
                    margin="dense"
                    value={inputTitle}
                    onChange={handleTitleChange}
                />
            </div>
            <div
                style={{
                    height: `calc(100% - 20px)`,
                    width: '100%',
                    backgroundImage: `url(${kpi})`,
                    backgroundSize: '100% 100%',
                }}
            />
        </ElementWrapper>
    );
};

export default KPI;
