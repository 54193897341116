import { combineReducers } from 'redux';
import objectReducer from './objectReducer';
import uiReducer from './uiReducer';
import authReducer from './authReducer';
import promptReducer from "./promptReducer";

const rootReducer = combineReducers({
  objects: objectReducer,
  ui: uiReducer,
  auth: authReducer,
  prompt: promptReducer
});

export default rootReducer;
