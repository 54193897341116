import {Box, Typography} from "@mui/material";
import {propExistsOnObject, renderSelector} from "../utils";
import React, {useCallback, useEffect, useState} from "react";
import {Slider} from "../../../../../common/Slider/Slider";
import {Switch} from "../../../../../common/Switch/Switch";
import {LINE_DEFAULT_VALUES, SYMBOLS} from "../styleConsts";
import {debounce, cloneDeep} from "lodash";

export const LineConfig = ({config, setConfig, chartPreferences}) => {

    const [width, setWidth] = useState(LINE_DEFAULT_VALUES.lineStyle.width);
    const [symbolSize, setSymbolSize] = useState(LINE_DEFAULT_VALUES.symbolSize);

    useEffect(() => {
        setWidth(getLineStylePropValue('width'))
        setSymbolSize(getLinePropValue('symbolSize'))
    }, [config])

    const lineDebouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.line[prop] = value
        setConfig(newConfig)
    }, 200), [config])

    const lineLineStyleDebouncedFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.line.lineStyle[prop] = value
        setConfig(newConfig)
    }, 200), [config])

    const getLinePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.line, prop)) return config.styleOverrides.line[prop]
        if (propExistsOnObject(chartPreferences.line, prop)) return chartPreferences.line[prop]
        return LINE_DEFAULT_VALUES[prop]
    }

    const getLineStylePropValue = (prop) => {
        if (propExistsOnObject(config.styleOverrides.line.lineStyle, prop)) return config.styleOverrides.line.lineStyle[prop]
        if (propExistsOnObject(chartPreferences.line?.lineStyle, prop)) return chartPreferences.line.lineStyle[prop]
        return LINE_DEFAULT_VALUES.lineStyle[prop]
    }

    const handleDebouncedLinePropChange = (prop, value, setter) => {
        lineDebouncedFunc(prop, value)
        setter(value)
    }

    const handleDebouncedLineStylePropChange = (prop, value, setter) => {
        lineLineStyleDebouncedFunc(prop, value)
        setter(value)
    }

    const handleLinePropChange = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.line[prop] = value
        setConfig(newConfig)
    }

    const smooth = getLinePropValue('smooth')

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            <Slider
                label={`Width: ${width}px`}
                value={width}
                onChange={(v) => handleDebouncedLineStylePropChange('width', v, setWidth)}
                min={1}
                max={10}
                step={1}
            />
            <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                <Switch
                    checked={smooth}
                    onClick={() => handleLinePropChange('smooth', !smooth)}
                />
                <Typography className={'inter style-config-label'}>Smooth</Typography>
            </Box>
            {
                renderSelector(
                    'Symbol',
                    getLinePropValue('symbol'),
                    (v) => handleLinePropChange('symbol', v),
                    SYMBOLS
                )
            }
            <Slider
                label={`Symbol size: ${symbolSize}px`}
                value={symbolSize}
                onChange={(v) => handleDebouncedLinePropChange('symbolSize', v, setSymbolSize)}
                min={1}
                max={30}
                step={1}
            />
        </Box>
    )
}
